
const openNewTabWithHtml: any = (htmlString: any) => {
    let newTab = window.open();
    if (newTab !== null) {
      newTab.document.write(htmlString);
      newTab.document.close();
    } else {
      console.error('Failed to open new tab.');
    }
}
export default openNewTabWithHtml;
