import { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import { useIntl } from 'react-intl';
import Account from './Account';
import AccountInfo from './components/user/AccountInfo';

const AccountWrapper: FC = () => {
  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'MENU.ACCOUNT' }),
      path: '/accounts',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
      <Switch>
        <Route path='/account/add'>
          <PageTitle breadcrumbs={breadcrumbs}>
            {intl.formatMessage({ id: 'COMMON.CREATE' })}
          </PageTitle>
          <AccountInfo />
        </Route>
        <Route path='/account/edit/:id'>
          <PageTitle breadcrumbs={breadcrumbs}>
            {intl.formatMessage({ id: 'COMMON.UPDATE' })}
          </PageTitle>
          <AccountInfo />
        </Route>
        <Route path='/accounts/list'>
          <PageTitle breadcrumbs={breadcrumbs}>
            {intl.formatMessage({ id: 'MENU.ACCOUNT.LIST' })}
          </PageTitle>
          <Account />
        </Route>
        <Redirect from='/accounts/list' exact={true} to='/accounts/list' />
        <Redirect to='/accounts/list' />
      </Switch>
  );
};

export default AccountWrapper;
