import { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Products from './Products'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'

const ProductBreadCrumbs: Array<PageLink> = [
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  }
]

const ProductsWrapper: FC = () => {
  return (
    <Switch>
      <Route path='/my-products'>
        <PageTitle breadcrumbs={ProductBreadCrumbs}>Danh sách sản phẩm</PageTitle>
        <Products />
      </Route>
      <Redirect from='/my-products' exact={true} to='/my-products' />
      <Redirect to='/my-products' />
    </Switch>
  )
}

export default ProductsWrapper
