import React, { createContext, useContext, ReactNode } from 'react';

interface ModalContextProps {
  openStoreModal: () => void;
  closeStoreModal: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const openStoreModal = () => {
  };

  const closeStoreModal = () => {
  };

  return (
    <ModalContext.Provider value={{ openStoreModal, closeStoreModal }}>
      {children}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export { ModalProvider, useModal };
