import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import queryString from 'query-string'
import { shallowEqual, useSelector } from "react-redux";
import { PaginateModel } from "../../../models/PaginateModel";
import { AbilityModel, ModalModel, SearchModel } from "../../../models/AbilityModel";
import { RootState } from "../../../../setup";
import { UserModel } from "../../../modules/auth/models/UserModel";
import { formatPrice, openNewTabWithHtml } from "../../../selectors";
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../../_metronic/partials/pagination/PaginationLength";
import * as DebtCRUD from '../DebtCRUD';
import { PaginationHeader } from "../../../../_metronic/partials/pagination/PaginationHeader";
import { Paginate } from "../../../../_metronic/partials/pagination/Paginate";
import { ABILITIES_TYPES, RECIEPT_VOUCHER } from "../../../constants/common";
import { Toast, download } from "../../../modules/common/helper";
import moment from "moment";
import { SupplierModel } from "../../../models/SupplierModel";
import SupplierDebtTable from "./SupplierDebtTable";
import ReceiptVoucherModal from "../../abilities/receipt-voucher/ReceiptVoucherModal";
import PaymentVoucher from "../../abilities/agency/PaymentVoucherModal";
import SupplierDebtModal from "./SupplierDebtModal";
import {Sento, UserLoggedIn} from "../../../hooks/PermissionChecker";
import { HeaderModel } from "../../../models/CommonModel";
import ConfigModal from "./ConfigModal";
import useMasterData from "../../../hooks/useMaster";

const SupplierDebt: FC = () => {
    const intl = useIntl();
    const history = useHistory();
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<SupplierModel>>();
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const sento = Sento();
    const {master} = useMasterData();
    const fp = useRef<any>(null);
    const type: any = ABILITIES_TYPES.find((e) => {
        return e.label == RECIEPT_VOUCHER
    });
    const [hideCols, setHideCols] = useState<string[]>([]);
    const [configModal, setConfigModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const [paymentVoucherModal, setPaymentVoucherModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });

    const [debtDetailModal, setDebtDetailModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const [totalDebt, setTotalDebt] = useState<number>(0);
    const initSearch = {
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0],
        store_id: UserLoggedIn().store_id
    }
    const [search, setSearch] = useState<SearchModel>(initSearch)

    const parseParams = (params: SearchModel) => {
        if (params.page) {
            params.page = +params.page
        }
        if (params.per_page) {
            params.per_page = +params.per_page
        }
        return params
    }

    useEffect(() => {
		document.title = 'Sento - Công nợ nhà cung cấp';
	})

    useEffect(() => {
        const searchHistory = history.location.search;
        const initParams: SearchModel = parseParams(queryString.parse(searchHistory))
        getData({ ...search, ...initParams });
    }, []);

    const getData = async (params?: SearchModel) => {
        const { data: resData } = await DebtCRUD.getSupplierDebt({...params});
        const objQuery = { ...params }
        if (objQuery.page === 1) {
            delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
            delete objQuery.per_page
        }
        history.push({ search: `?${queryString.stringify(objQuery)}` })
        setDataPaginate(resData?.paginate_data);
        setTotalDebt(resData?.total_debt ?? 0)
        setSearch(params ?? {});
    }

    const handlePageChange = (data: any) => {
        getData({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getData({ ...search, page: 1, per_page: +e.target.value })
    }

    const handleSearch = (e: any) => {
        e.preventDefault();
        getData({ ...search, page: 1 })
    }

    const handleClearSearch = (e: any) => {
        e.preventDefault();
        setSearch(initSearch);
        if (fp.current?.flatpickr) {
            fp.current.flatpickr.clear();
        }
        getData(initSearch)
    }

    const handleSubmit = async (values: any, actions: any) => {
        let result;
        result = DebtCRUD.store(values);
        let message = 'Tạo phiếu chi thành công!'
        result.then(res => {
            Toast.fire({
                icon: 'success',
                title: message
            })
            setPaymentVoucherModal({ ...paymentVoucherModal, show: false })
            getData({ ...search, page: 1 })
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Đã có lỗi xảy ra vui lòng liên hệ Admin'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };

    const handleViewDetail = (supplier: SupplierModel) => {
        setDebtDetailModal({ ...debtDetailModal, show: true, data: {...debtDetailModal?.data, supplier: supplier} })
    }

    const handlePaymentVoucher = async (supplier: SupplierModel) => {
        setPaymentVoucherModal({ ...paymentVoucherModal, show: true, data: {...paymentVoucherModal?.data, supplier: supplier} })
    }
    
    const header: HeaderModel[] = [
        {
            label: intl.formatMessage({ id: 'SUPPLIER.NAME' }),
            style: { minWidth: "150px" },
            field: 'name',
            sortable: true
        },
        {
            label: 'Ngày thu cuối cùng',
            style: { minWidth: "150px" },
            field: 'ability_max_date',
            sortable: true
        },
        {
            label: 'Công nợ',
            style: { minWidth: "250px" },
            field: 'debt',
            sortable: true
        }
    ]

    const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
        const currentSearch = { ...search };
        const sortParams: SearchModel = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
        if (direction === 'none') {
            delete sortParams.sort_by;
            delete sortParams.sort_direction;
        }
        getData({ ...sortParams });
    }

    const handleChangeVisCols = (e: any) => {
        let hiddenCols = [...hideCols];
        if (!e.target?.checked) {
            setHideCols([...hiddenCols, e.target.name])
        } else {
            setHideCols(hiddenCols.filter((item) => item !== e.target.name))
        }
    }

    const onChangeHasDebt = (e: any) => {
        const checked = e.target.checked
        let newSearch = {
            ...initSearch,
            has_debt: checked ? 1 : 0
          }
          getData(newSearch);
    }
    const handleExportDebt = async () => {
        const { data } = await DebtCRUD.exportSupplierDebt({ ...search });
        download(data, 'supplier-debt');
    }
    return <>
        <div className="card">
            <div className="card-header py-4">
                <div className="col-md-12">
                    <form onSubmit={handleSearch}>
                        <div className="row">
                            <div className="col-md-2">
                                <label className="form-label">{intl.formatMessage({ id: 'COMMON.KEYWORD' })}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="keyword"
                                    value={search.keyword || ''}
                                    onChange={(e) => setSearch({ ...search, keyword: e.target.value })}
                                    autoComplete='nope'
                                />
                            </div>
                            {sento &&
                                <div className="col-md-3 mb-2">
                                    <label className="form-label">Store</label>
                                    <select
                                        className="form-select"
                                        value={search.store_id}
                                        onChange={(e) => setSearch({ ...search, store_id: e.target.value })}
                                        name="store_id"
                                    >
                                        <option value=''>Tất cả</option>
                                        {master?.stores?.map((store, index) => {
                                            return (
                                                <option value={store.id}>{store.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            }
                            <div className="col-md-4">
                                <div className="row">
                                    <button
                                        className="col-auto btn btn-primary mt-8 me-2"
                                        onClick={handleSearch}
                                    >
                                        <i className="bi bi bi-search fs-4"></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
                                    </button>
                                    <button
                                        className="col-auto btn btn-danger mt-8 me-2"
                                        type="button"
                                        onClick={handleClearSearch}
                                    >
                                        <i className="bi bi-eraser-fill fs-1"></i> Re-search
                                    </button>
                                    <button className="btn btn-icon mt-8 btn-sm me-2 ms-auto btn-light-info text-hover-white" type="button"
                                    onClick={handleExportDebt}>
                                        <i className="bi bi-cloud-arrow-down-fill fs-1 text-info" />
                                    </button>
                                    
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="form-check mt-10">
                                    <input className="form-check-input" type="checkbox" value="" id="has_debt"
                                        name='has_debt'
                                        onChange={onChangeHasDebt}/>
                                    <label htmlFor="has_debt" className="form-check-label mb-2">Hiển thị công nợ</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card-body">
                <div className="row justify-content-between align-items-center mb-3">
                    <div className='col-7 col-md-6'>
                        <PaginationHeader from={dataPaginate?.meta?.from || 0} to={dataPaginate?.meta?.to || 0}
                            total={dataPaginate?.meta?.total || 0} />
                    </div>
                    <div className='col-12 col-md-6 text-end'>
                        <h3>Tổng cộng: <span className="text-bold">{ formatPrice(totalDebt) } VNĐ</span></h3> 
                    </div>
                </div>
                <SupplierDebtTable
                    data={dataPaginate?.data || []}
                    handleViewDetail={handleViewDetail}
                    handlePaymentVoucher={handlePaymentVoucher}
                    openColConfig={() => setConfigModal({ ...configModal, show: true })}
                    header={header}
                    hideCols={hideCols}
                    handleSort={handleSort}
                />
                <PaymentVoucher
                    {...paymentVoucherModal}
                    onClose={() => { setPaymentVoucherModal({ ...paymentVoucherModal, show: false }) }}
                    onSubmit={handleSubmit}
                />
                <SupplierDebtModal
                    {...debtDetailModal}
                    onClose={() => { setDebtDetailModal({ ...debtDetailModal, show: false }) }}
                />
            </div>
            <div className="card-footer d-flex justify-content-between">
                <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
                <Paginate
                    pageCount={dataPaginate?.meta?.last_page || 0}
                    onPageChange={handlePageChange}
                    forcePage={(search.page || 1) - 1}
                />
            </div>
            <ConfigModal
                {...configModal}
                header={header}
                hideCols={hideCols}
                handleChangeVisCols={handleChangeVisCols}
                onClose={() => { setConfigModal({ ...configModal, show: false }) }}
            />
        </div>
    </>
}
export default SupplierDebt;