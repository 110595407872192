import React, { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import * as ReceiptCRUD from "../../receipt/ReceiptCRUD";
import { IReceipt, IReceiptDetail} from "../../../../models/WareHouseModel";
import { Toast, download } from "../../../../modules/common/helper";
import ReceiptProductsTable from "./ReceiptProductsTable";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { openNewTabWithHtml } from "../../../../selectors";
import * as StoreCRUD from "../../../store/StoreCRUD";
import { ReceiptDetailModel, ReceiptDetailSearchModel } from "../../../../models/ReceiptDetailModel";
import { DATA_PAGINATION_LENGTH } from "../../../../../_metronic/partials/pagination/PaginationLength";
import moment from "moment";
import { PaginateModel } from "../../../../models/PaginateModel";
import { HeaderModel, ModalModel } from "../../../../models/CommonModel";
import { AsyncSelect2Supplier } from "../../../../../_metronic/partials/select/AsyncSelect2Supplier";
import Flatpickr from "react-flatpickr";
import {Vietnamese} from "flatpickr/dist/l10n/vn.js"
import { Sento, UserLoggedIn } from "../../../../hooks/PermissionChecker";
import { IStore } from "../../../../models/StoreModel";
import { GET_SUPPLIER_OPTIONS } from "../../../../constants/endpoints";
import useMasterData from "../../../../hooks/useMaster";

const ReceiptProducts: FC = () => {
  const intl = useIntl()
  const initRange: any = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const initSearch = {
    page: 1,
    per_page: DATA_PAGINATION_LENGTH[0],
    date: {startDate: initRange[0], endDate: initRange[1]},
    receipt_type: 1,
    store_id: UserLoggedIn().store_id
  }
  const [configModal, setConfigModal] = useState<ModalModel>({
    show: false,
    mode: 'add',
  });
  const fp = useRef<any>(null);
  const supplierRef = useRef<any>(null);
  const [hideCols, setHideCols] = useState<string[]>([]);
  const [dataPaginate, setDataPaginate] = useState<PaginateModel<ReceiptDetailModel>>()
  const [search, setSearch] = useState<ReceiptDetailSearchModel>(initSearch)
  const sento = Sento();
  const { master } = useMasterData();

  useEffect(() => {
    document.title = 'Sento - Tìm kiếm sản phẩm nhập';
  })

  useEffect(() => {
    getData(initSearch)
  }, []);

  const header: HeaderModel[] = [
    {
      label: 'Tên sản phẩm',
      style: {},
      field: 'inventory_name',
      sortable: true
    },
    {
      label: 'Nhà cung cấp',
      style: { minWidth: "150px" },
      field: 'supplier_name',
      sortable: true
    },
    {
      label: 'Số lượng',
      style: { minWidth: "50px" },
      field: 'quantity',
      sortable: true
    },
    {
      label: 'Đơn vị',
      style: { minWidth: "80px" },
      field: 'unit',
      sortable: false
    },
    {
      label: 'Đơn giá',
      style: { minWidth: "80px" },
      field: 'price',
      sortable: true
    },
    {
      label: 'Chiết khấu',
      style: { minWidth: "50px" },
      field: 'discount',
      sortable: true
    },
    {
      label: 'Tổng giá trị',
      style: { minWidth: "80px" },
      field: 'sub_total',
      sortable: true
    },
    {
      label: 'Mã đơn',
      style: { minWidth: "100px" },
      field: 'receipt_code',
      sortable: true
    },
    {
      label: 'Ngày duyệt',
      style: { minWidth: "120px" },
      field: 'approved_at',
      sortable: true
    }
  ];

  const getData = async (params: ReceiptDetailSearchModel) => {

    const { data: dataPaginate } = await ReceiptCRUD.searchProduct(params);
    const objQuery = { ...params }
    if (objQuery.page === 1) {
      delete objQuery.page
    }
    if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
      delete objQuery.per_page
    }
    setDataPaginate(dataPaginate);
    setSearch(params ?? {});
  }
  
  const handleChangeVisCols = (e: any) => {
    let hiddenCols = [...hideCols];
    if (!e.target?.checked) {
      setHideCols([...hiddenCols, e.target.name])
    } else {
      setHideCols(hiddenCols.filter((item) => item !== e.target.name))
    }
  }

  const handleSearch = (e: any) => {
      e.preventDefault();
      getData({ ...search, page: 1 })
  }

  const handlePageLengthChange = (e: any) => {
    getData({ ...search, page: 1, per_page: +e.target.value })
  }

  const handlePageChange = (data: any) => {
    getData({ ...search, page: data.selected + 1 })
  }

  const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
    const currentSearch = { ...search };
    const sortParams: ReceiptDetailSearchModel = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
    if (direction === 'none') {
      delete sortParams.sort_by;
      delete sortParams.sort_direction;
    }
    getData({ ...sortParams });
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
        handleSearch(e);
    }
  }

  const handleClearSearch = (e: any) => {
    e.preventDefault();
    supplierRef?.current?.clearValue()
    setSearch({
      ...initSearch,
      date: {startDate: null, endDate: null}
    });
    if (fp.current?.flatpickr) {
      fp.current.flatpickr.clear();
    }
    getData({...initSearch})
  }

  const handleExport = async () => {
    const {data} = await ReceiptCRUD.exportSearchProduct({...search});
    download(data, 'search_product');
  }

  return (
      <div className="card">
        <div className="card-header py-4">
          <div className="col-md-12">
            <form onSubmit={handleSearch}>
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label">Nhà cung cấp</label>
                  <AsyncSelect2Supplier
                    name="supplier_id"
                    placeholder=""
                    sourceUrl={GET_SUPPLIER_OPTIONS}
                    defaultOptions
                    default
                    onChange={(e:any) => {
                      setSearch({ ...search, supplier_id: e.value })
                    }} 
                    forwardRef={supplierRef}
                  />
                </div>
                <div className="col-md-2">
                  <label className="form-label">Tên sản phẩm</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="name"
                    value={search.name || ''}
                    onChange={(e) => setSearch({ ...search, name: e.target.value })}
                    autoComplete='nope'
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </div>
                <div className='col-md-3 mb-2'>
                  <label className="form-label">Ngày</label>
                  <Flatpickr
                    ref={fp}
                    onChange={(date) => {
                      let startDate = moment(date[0]).format('YYYY-MM-DD');
                      let endDate = moment(date[1]).format('YYYY-MM-DD');
                      setSearch({...search, date: {startDate, endDate}});
                    }}
                    options={{
                      mode: "range",
                      dateFormat: "d-m-Y",
                      locale: Vietnamese,
                      shorthandCurrentMonth: true,
                      defaultDate: [moment().startOf('month').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
                    }}
                    className='form-control'
                    placeholder=''
                  />
                </div>
                {sento &&
                  <div className="col-md-3 mb-2">
                      <label className="form-label">Store</label>
                      <select
                      className="form-select"
                      value={search.store_id}
                      onChange={(e) => setSearch({ ...search, store_id: e.target.value })}
                      name="store_id"
                      >
                      <option value=''>Tất cả</option>
                      {master?.stores?.map((store, index) => {
                          return (
                          <option value={store.id}>{store.name}</option>
                          )
                      })}
                      </select>
                  </div>
              }
                <div className="col-md-4">
                  <div className="row">
                    <button
                      className="col-auto btn btn-primary mt-8 me-2"
                      onClick={handleSearch}
                    >
                      <i className="bi bi bi-search fs-4"></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
                    </button>
                    <button
                      className="col-auto btn btn-danger mt-8 me-2"
                      type="button"
                      onClick={handleClearSearch}
                    >
                      <i className="bi bi-eraser-fill fs-1"></i> Re-search
                    </button>
                  </div>

                </div>
                <div className="col-md-2">
                  <button className="btn btn-icon mt-8 me-2 btn-light-info me-5 text-hover-white" type="button"
                    onClick={handleExport}>
                    <i className="bi bi bi-cloud-arrow-down-fill fs-1 text-info"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="card-body">
          <ReceiptProductsTable
            data={dataPaginate?.data || []}
            openColConfig={() => setConfigModal({ ...configModal, show: true })}
            header={header}
            hideCols={hideCols}
            handleSort={handleSort}
          />
        </div>
      </div>
  )
}
export default ReceiptProducts