import { FC, useEffect, useState } from 'react'
import { ProductsTable } from './components/ProductsTable'
import { FilterList } from './components/FilterList'
import Pagination from './components/Pagination'
import { getProducts } from './ProductsCRUD'
import ProductsTableHeader from './components/ProductsTableHeader'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { PaginateModel } from '../../../models/PaginateModel'
import { IFilter, IProductsWithBestSupplier, ISearch } from '../../../modules/auth/models/ProductsModel'
import { DATA_PAGINATION_LENGTH } from '../../../../_metronic/partials/pagination/PaginationLength'
import { HeaderModel, ModalModel } from '../../../models/CommonModel'
import { Toast, getDiscount } from '../../../modules/common/helper'
import ConfigModal from '../../../components/Modal/ConfigModal'

const initialDataQuickEdit = {
  name: '',
  discount: 0,
  inventory_of_supplier: {
    price: 0,
    supplier: { id: 0 }
  },
}

const Products: FC = () => {
  const [dataPaginate, setDataPaginate] = useState<PaginateModel<IProductsWithBestSupplier>>()
  const [dataProduct, setDataProduct] = useState<IProductsWithBestSupplier>({ ...initialDataQuickEdit });
  const intl = useIntl()
  const [hideCols, setHideCols] = useState<string[]>([]);
  const intItSearch = {
    page: 1,
    per_page: DATA_PAGINATION_LENGTH[0],
  }
  const [search, setSearch] = useState<IFilter>(intItSearch)
  const [configModal, setConfigModal] = useState<ModalModel>({
      show: false,
      mode: 'add',
  });
  useEffect(() => {
		document.title = 'Sento - Danh sách sản phẩm';
	})

  useEffect(() => {
    getData()
  }, [])

  const getData = async (params?: IFilter) => {
    let newParams: IFilter = { ...params }
    if (params?.from_price || params?.to_price) {
      const priceRange = `${params?.from_price ?? ''} - ${params?.to_price ?? ''}`;
      newParams = {
        ...params,
        'price': priceRange,
      };
    }
    const { data: dataPaginate } = await getProducts(newParams)
    setDataPaginate(dataPaginate)
    setSearch(newParams ?? { ...intItSearch })
  }

  const handlePageChange = (data: any) => {
    getData({ ...search, page: data.selected + 1 })
  }

  const handlePageLengthChange = (e: any) => {
    getData({ ...search, page: 1, per_page: e.target.value })
  }

  const handleSearch = () => {
    getData({ ...search, page: 1 })
  }

  const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
    const currentSearch = { ...search };
    const sortParams: ISearch = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
    if (direction === 'none') {
      delete sortParams.sort_by;
      delete sortParams.sort_direction;
    }
    getData({ ...sortParams });
  }

  const handleClearSearch = () => {
    setSearch(intItSearch)
    getData()
  }

  useEffect(() => {
    const discount = getDiscount(dataProduct?.price ?? 0, dataProduct?.inventory_of_supplier?.price ?? 0)
    setDataProduct({
      ...dataProduct,
      ...{ inventory_of_supplier: { ...dataProduct?.inventory_of_supplier ?? {}, discount } }
    })
  }, [dataProduct.price, dataProduct.inventory_of_supplier?.price]);

  const header: HeaderModel[] = [
    
    {
      label: 'Image',
      style: { minWidth: "100px", maxWidth: "100px" },
      field: 'image_path',
      sortable: false
    },
    {
        label: intl.formatMessage({ id: 'PRODUCTS.NAME' }),
        style: { minWidth: "150px" },
        field: 'name',
        sortable: true
    },
    {
        label: 'Danh mục',
        style: { minWidth: "120px" },
        field: 'category_id',
        sortable: false
    },
    {
        label: intl.formatMessage({ id: 'PRODUCTS.PRICE_SELL' }),
        style:  { minWidth: "100px" },
        field: 'supplier_price_sell',
        sortable: true
    },
    {
        label: 'ĐVT',
        style:  { minWidth: "120px" },
        field: 'unit',
        sortable: false
    },
    {
        label: intl.formatMessage({ id: 'PRODUCTS.IS_COMPANY' }),
        style:  { minWidth: "100px", textAlign: "center" },
        field: 'is_company',
        sortable: false
    }
  ]

  const handleChangeVisCols = (e: any) => {
    let hiddenCols = [...hideCols];
    if (!e.target?.checked) {
        setHideCols([...hiddenCols, e.target.name])
    } else {
        setHideCols(hiddenCols.filter((item) => item !== e.target.name ))
    }
  }

  return (
    <>
      <div className='card'>
        <div className='card-header py-4'>
          <div className='col-md-12'>
            <FilterList
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
              handleClearSearch={handleClearSearch}
            />
          </div>
        </div>
        <div className='card-body'>
          <ProductsTableHeader
            meta={dataPaginate?.meta}
          />
          <ProductsTable
            search={search}
            openColConfig={() => setConfigModal({...configModal, show: true})}
            data={dataPaginate?.data ?? []}
            handleSort={handleSort}
            header={header}
            hideCols={hideCols}
          />
        </div>
        <div className='card-footer d-flex justify-content-between'>
          <Pagination
            search={search}
            handlePageLengthChange={handlePageLengthChange}
            handlePageChange={handlePageChange}
            dataPaginate={dataPaginate}
          />
        </div>
      </div>
      <ConfigModal
            {...configModal}
            header={header}
            hideCols={hideCols}
            handleChangeVisCols={handleChangeVisCols}
            onClose={() => { setConfigModal({ ...configModal, show: false }) }}
        />
    </>
  )
}

export default Products
