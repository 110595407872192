/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {map} from "lodash"
import {IOrderReport} from "../../models/DashboardModel";
import * as DashboardCURD from "./DashboardCRUD";
import {getCSS, getCSSVariableValue} from "../../../_metronic/assets/ts/_utils";
import {Sento} from "../../hooks/PermissionChecker";

type Props = {
  className: string
  colorBase: string
  store?: string
}

const CharsOrder: React.FC<Props> = ({className, colorBase, store}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [orders, setOrders] = useState<IOrderReport[]>([])
  const [ordersNotApproved, setOrdersNotApproved] = useState<IOrderReport[]>([])
  const [status, setStatus] = useState<number>(0);
  const [time, setTime] = useState<string>('month');

  const isPercent = status == 3;
  useEffect(() => {
    const getOrderReport = async () => {
      const {data} = await DashboardCURD.getReport({status, time, store});
      setOrders(data.data)
      if (isPercent) {
        setOrdersNotApproved(data.data1)
        return;
      }
      setOrdersNotApproved([])
    }
    getOrderReport();
  }, [status, time, store]);

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, colorBase, orders, ordersNotApproved, isPercent))
    if (chart && orders.length > 0) {
      chart.render()
    }

    return () => {
      if (chart && orders.length > 0) {
        chart.destroy()
      }
    }
  }, [chartRef, orders, ordersNotApproved])

  const times: any = {
    'week': 'Tuần',
    'month': 'Tháng',
    'year': 'Năm'
  }
  const isActiveAll = status == 0;
  const isActiveApproved = status == 1;
  const isActiveNotApproved = status == 2;
  const isSento = Sento();

  const emptyData = orders.length == 0 && ordersNotApproved.length == 0;

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div className="card-title align-items-center d-flex justify-content-center">
          <h3>
            <span className='card-label fw-bolder fs-3 mb-1'>Đơn hàng</span>
          </h3>
          <button
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary mx-2 ${isActiveAll ? 'active' : ''}`}
            onClick={() => setStatus(0)}>Tất cả
          </button>
          <button
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary mx-2 ${isActiveApproved ? 'active' : ''}`}
            onClick={() => setStatus(1)}>Duyệt
          </button>
          <button
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary mx-2 ${isActiveNotApproved ? 'active' : ''}`}
            onClick={() => setStatus(2)}>Chưa duyệt
          </button>
          <div
            className={`btn btn-sm btn-color-muted btn-active btn-group mx-2 p-0 ${isPercent ? 'active' : ''}`}
            onClick={() => setStatus(3)}>
            {isPercent ? <>
              <a type="button"
                 className='btn btn-sm btn-primary text-white'>Duyệt</a>
              <a type="button" className='btn btn-sm btn-warning text-white'>Chưa duyệt</a>
            </> : 'Duyệt/Chưa duyệt'
            }

          </div>

        </div>


        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          {Object.keys(times).map(function (key) {
            return <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${key == time ? 'active' : ''}`}
              id='kt_charts_widget_2_year_btn' key={key}
              onClick={() => setTime(key)}
            >
              {times[key]}
            </a>
          })}
        </div>
      </div>
      <div className='card-body'>
        {emptyData ?
          <div key={`item_key_${emptyData}`}
               className="d-flex justify-content-center align-items-center text-muted fst-italic"
               style={{height: '350px'}}>
            Không tìm thấy dữ liệu
          </div>
          :
          <div ref={chartRef} id='kt_charts_widget_2_chart' style={{height: '350px'}}></div>
        }
      </div>
    </div>
  )
}

export {CharsOrder}

function getChartOptions(height: number, colorBase: string, orders: IOrderReport[], ordersNotApproved: IOrderReport[], isPercent: boolean): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue(colorBase)
  const secondaryColor = getCSSVariableValue('--bs-warning')
  const series = [{
    name: 'Duyệt',
    data: map(orders, 'value'),
  }];
  if (isPercent) {
    series.push({
      name: 'Chưa duyệt',
      data: map(ordersNotApproved, 'value'),
    })
  }

  return {
    series,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#FFF"]
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: map(orders, 'label'),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      // y: {
      //   formatter: function (val) {
      //     return val
      //   },
      // },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
