import React, {FC} from "react";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import {useParams} from "react-router-dom";
import SalesProducts from "./SalesProducts";


const SalesProductWrapper: FC = () => {
  const intl = useIntl();
  const {id} = useParams<{ id?: string }>();
  const breadcrumbs = [
    {
      title: 'Sản phẩm - Sales',
      path: '/agents',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]
  return <>
    <PageTitle breadcrumbs={breadcrumbs}>Sản phẩm - Nhân viên kinh doanh</PageTitle>
    <SalesProducts/>
  </>
}
export default SalesProductWrapper;