const formatPrice = (price: number, isRound = true) => {
  if (!price) {
    return 0;
  }
  if(isRound) {
    price = Math.round(price)
  }
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export default formatPrice;
