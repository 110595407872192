import {HTTP_INTERNAL_SERVER_ERROR, HTTP_NOT_FOUND, HTTP_UNAUTHORIZED} from "../../app/constants/status";
import {Toast} from "../../app/modules/common/helper";

export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      document.body.classList.add('page-loading')
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => {
      document.body.classList.remove('page-loading')
      return Promise.resolve(response)
    },
    (error: any) => {
      if (error.response?.status === HTTP_NOT_FOUND) {
        //window.location.href = "/error/404";
      }
      if (error.response?.status === HTTP_UNAUTHORIZED) {
        window.location.href = "/login";
      }
      document.body.classList.remove('page-loading')
      if (error.response?.status === HTTP_INTERNAL_SERVER_ERROR) {
        Toast.fire({
          icon: 'error',
          title: 'Thất bại. Vui lòng thử lại sau'
        })
      }
      return Promise.reject(error)
    }
  );
}
