import { FC } from "react";
import { PaginationHeader } from "../../../../../_metronic/partials/pagination/PaginationHeader";
import { MetaPaginateModel } from "../../../../models/PaginateModel";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

type TProps = {
    meta?: MetaPaginateModel
}

const AccountsTableHeader: FC<TProps> = ({ meta }) => {
    const intl = useIntl()
    return (
        <div className="row justify-content-between align-items-center mb-3">
            <div className='col-md-3'>
                <PaginationHeader from={meta?.from ?? 0} to={meta?.to ?? 0} total={meta?.total ?? 0} />
            </div>
            <div className='col-md-3 text-end'>
               <Link to="/account/add" className='btn btn-success' >
                    <i className='bi bi bi-plus-circle fs-4'></i> {intl.formatMessage({ id: 'COMMON.CREATE' })}
                </Link>
            </div>
        </div>
    )
}

export default AccountsTableHeader