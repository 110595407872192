import { FC } from "react";
import { PaginationHeader } from "../../../../_metronic/partials/pagination/PaginationHeader";
import { MetaPaginateModel } from "../../../models/PaginateModel";
import { useIntl } from "react-intl";
import { LIST_PERMISSIONS } from '../../../constants/common'
type TProps = {
    meta?: MetaPaginateModel
    toggleModal: (id?: number) => void;
    hasPermission: (permission: string) => boolean;
}

const StoreTableHeader: FC<TProps> = ({ meta, toggleModal, hasPermission}) => {
    const intl = useIntl()
    return (
        <div className="row justify-content-between align-items-center mb-3">
            <div className='col-md-3'>
                <PaginationHeader from={meta?.from ?? 0} to={meta?.to ?? 0} total={meta?.total ?? 0} />
            </div>
                <div className='col-md-3 text-end'>
                <button type="button" className='btn btn-success' onClick={() => toggleModal()} >
                     <i className='bi bi bi-plus-circle fs-4'></i> {intl.formatMessage({ id: 'COMMON.CREATE' })}
                 </button>
             </div>
        </div>
    )
}

export default StoreTableHeader