/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { AbilityModel } from '../../../models/AbilityModel'
import { AsyncSelect2 } from '../../../../_metronic/partials/select/AsyncSelect2'
import { GET_AGENCY_OPTIONS } from '../../../constants/endpoints'
import { AsyncSelect2Agency } from '../../../../_metronic/partials/select/AsyncSelect2Agency'
import { formatPrice, revertPriceNumner } from '../../../selectors'
import { useIntl } from 'react-intl'
import { ABILITIES_PAYMENT_METHOD } from '../../../constants/common'
import { NumericFormat } from 'react-number-format'

type Props = {
    show: boolean
    mode: string
    data?: AbilityModel
    onClose: () => void
    onSubmit: (values: any, actions: any) => void
}
const addSchema = Yup.object().shape({
    name: Yup.string().required('Vui lòng nhập tên'),
    agency_id: Yup.string().nullable().required('Vui lòng chọn đại lý'),
    value: Yup.string().required('Vui lòng nhập giá trị')
})

const ReceiptVoucherModal: React.FC<Props> = ({ show, mode, data, onClose, onSubmit }) => {
    const intl = useIntl();
    const agency = data?.agency;
    let initValues: any = {
        agency: {label: agency?.name, value: agency?.id},
        agency_id: agency?.id ?? null,
        name: agency?.name ?? '',
        address: agency?.address ?? '',
        dept: (agency?.order_details_sum_sub_total ?? 0) - (agency?.ability_sum_value ?? 0)
    };
    return (
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header className='d-flex'>
                <Modal.Title>Tạo phiếu thu</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-10">
                <Formik validationSchema={addSchema} initialValues={initValues} onSubmit={onSubmit}>
                    {({ values, handleChange }) => (
                        <Form>
                            <div className="d-flex flex-column  gap-5 pt-3">
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Tên người nộp</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='name'
                                        required
                                    />
                                    <ErrorMessage name='name' className='text-danger' component='span' />
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Đại lý</label>
                                    <AsyncSelect2Agency
                                        name="agency"
                                        placeholder=""
                                        isGray
                                        sourceUrl={GET_AGENCY_OPTIONS}
                                        defaultOptions
                                        default
                                        required
                                        defaultValue={initValues?.agency ?? null}
                                        onChange={(selectedOption: any) => {
                                            handleChange({target: {name: 'agency', value: selectedOption}});
                                            handleChange({target: {name: 'agency_id', value: selectedOption.value}});
                                            handleChange({target: {name: 'dept', value: selectedOption.dept}});
                                            handleChange({target: {name: 'address', value: selectedOption.address}});
                                        }}
                                    />
                                    <ErrorMessage name='agency_id' className='text-danger' component='span' />
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Địa chỉ</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='address'
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='address' /></div>
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Công nợ cũ</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='dept'
                                        value={formatPrice(values.dept)}
                                        readOnly
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='dept' /></div>
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Số tiền thu</label>
                                    <NumericFormat
                                        thousandSeparator=","
                                        name='value'
                                        className='form-control form-control-sm'
                                        required
                                        onBlur={(e) => {
                                            handleChange({target: {name: 'value', value: revertPriceNumner(e.target.value)}});
                                            handleChange({target: {name: 'new_dept', value: values.dept - revertPriceNumner(e.target.value)}});
                                        }}
                                    />
                                    <ErrorMessage name='value' className='text-danger' component='span' />
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Công nợ còn lại</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        value={formatPrice(values.new_dept)}
                                        name='new_dept'
                                    />
                                    <ErrorMessage name='new_dept' className='text-danger' component='span' />
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Phương thức thanh toán</label>
                                    <Field
                                            as='select'
                                            name='payment_method'
                                            className='form-select'
                                            required
                                    >
                                        <option value=""></option>
                                        {ABILITIES_PAYMENT_METHOD.map((method: any) => (
                                            <option key={method.id} value={method.id}>{method.label}</option>
                                        ))}
                                    </Field>
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Ghi chú</label>
                                    <Field
                                        as="textarea"
                                        className="form-control"
                                        value={values.note ?? ""}
                                        id="note"
                                        name="note"
                                        rows={3}
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='note' /></div>
                                </div>
                            </div>
                            <div className='d-flex mb-4 justify-content-between pt-8'>
                                <button className="btn btn-bg-secondary" type="button" onClick={() => onClose()}>Hủy</button>
                                <button className="btn btn-bg-success" type="submit">Thêm</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default ReceiptVoucherModal
