import axios from "axios";
import {PaginateModel,} from "../../models/PaginateModel";
import {ResponseModel} from "../../models/ResponseModel";
import { ADMIN_ABILITIES_ENDPOINT } from "../../constants/endpoints";
import { AbilityModel, SearchModel } from "../../models/AbilityModel";
import { ReportModel } from "./Abilities";

export interface AbilityListModel {
  paginate_data: PaginateModel<AbilityModel>,
  sub_total: number
}


export function getList(params?: SearchModel) {
  return axios.get<AbilityListModel>(`${ADMIN_ABILITIES_ENDPOINT}`, {params})
}

export function getAbilityReport(params?: SearchModel) {
  return axios.get<ReportModel>(`${ADMIN_ABILITIES_ENDPOINT}/ability-report`, {params})
}

export function store(data: AbilityModel) {
    return axios.post<ResponseModel>(`${ADMIN_ABILITIES_ENDPOINT}`, data)
}

export function exportPdf(params: { id?: number, type: string }) {
  return axios.post(`${ADMIN_ABILITIES_ENDPOINT}/export-pdf`, params)
}

export function exportCsv(params: SearchModel) {
  return axios.get(`${ADMIN_ABILITIES_ENDPOINT}/export`, {params, responseType: 'blob'})
}

