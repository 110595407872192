import React, {FC, useEffect, useState} from "react";
import {ErrorMessage, Field, useField, useFormikContext} from "formik";
import {useIntl} from "react-intl";
import * as AgentDetailCRUD from "./AgentDetailCRUD";
import {toUpper} from "lodash";
import { IStore } from "../../../models/StoreModel";
import { IAgentGroup } from "../../../models/AgentGroupModel";
import { IAgent } from "../../../models/AgentModel";
import { generateCode } from "../../../selectors";

type Props = {};

const AgentBasic: FC<Props> = () => {
  const [agentGr, setAgentGr] = useState<IAgentGroup[]>([])

  const {values, setFieldValue, isSubmitting, setTouched} = useFormikContext<IAgent>();

  const [field, meta] = useField('name');

  const intl = useIntl();

  const [agtGrModal, setAgtModal] = useState(false)

  useEffect(() => {
  }, [])

  useEffect(() => {
    if (isSubmitting) {
      setTouched({
        name: true,
        email: true,
        phone: true, address: true
      })
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (meta.value !== meta.initialValue) {
      const cleanedValue = generateCode(meta.value);
      setFieldValue('agency_code', toUpper(cleanedValue))
    }
  }, [meta.value, meta.initialValue])

  const handleCheckAgtCode = async () => {
    if (meta.value && meta.value !== meta.initialValue) {
      const {data} = await AgentDetailCRUD.uniqueAgtCode({id: values.id, agency_code: values.agency_code});
      setFieldValue('agency_code', data?.data?.agency_code);
    }
  }

  const handleSetAgent = (group: IAgentGroup) => {
    const nextKey = Object.keys(agentGr).length;
    setAgentGr({...agentGr, [nextKey]: group})
    setFieldValue('group_agency_id', group.id)
  }

  const handleAddAgtGr = () => setAgtModal(true)

  return <>
    <div className="card">
      <div className="card-header py-4 height-unset">
        <h5 className="mb-0">{intl.formatMessage({id: 'AGENT.BASIC_INFO'})}</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-6 mb-2">
            <div className="row">
              <label htmlFor="name"
                     className="col-md-4 col-form-label required">{intl.formatMessage({id: 'AGENT.CUSTOMER_NAME'})}</label>
              <div className="col-md-8">
                <Field
                  type="text"
                  className="form-control"
                  value={values.name ?? ''}
                  id="name"
                  name="name"
                  onBlur={handleCheckAgtCode}
                />
                <ErrorMessage name='name' className='text-danger' component='span'/>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-2">
            <div className="row">
              <label htmlFor="code"
                     className="col-md-4 col-form-label">{intl.formatMessage({id: 'AGENT.CUSTOMER_CODE'})}</label>
              <div className="col-md-8">
                <Field
                  type="text"
                  className="form-control"
                  disabled
                  value={values.agency_code ?? ''}
                  id="agency_code"
                  name="agency_code"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-2">
            <div className="row">
              <label htmlFor="phone"
                     className="col-md-4 col-form-label required">{intl.formatMessage({id: 'AGENT.CUSTOMER_PHONE'})}</label>
              <div className="col-md-8">
                <Field
                  type="text"
                  className="form-control"
                  value={values.phone ?? ""}
                  id="phone"
                  name="phone"
                />
                <ErrorMessage name='phone' className='text-danger' component='span'/>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-2">
            <div className="row">
              <label htmlFor="email"
                     className="col-md-4 col-form-label">{intl.formatMessage({id: 'COMMON.EMAIL'})}</label>
              <div className="col-md-8">
                <Field
                  type="text"
                  className="form-control"
                  value={values.email ?? ""}
                  id="email"
                  name="email"
                />
                <ErrorMessage name='email' className='text-danger' component='span'/>
              </div>

            </div>
          </div>
          <div className="col-12 col-md-6 mb-2">
            <div className="row">
              <label htmlFor="website"
                     className="col-md-4 col-form-label">{intl.formatMessage({id: 'AGENT.WEBSITE'})}</label>
              <div className="col-md-8">
                <Field
                  type="text"
                  className="form-control"
                  value={values.website ?? ""}
                  id="website"
                  name="website"
                />
              </div>

            </div>
          </div>

          <div className="col-12 col-md-6 mb-2">
            <div className="row">
              <label htmlFor="fax" className="col-md-4 col-form-label">{intl.formatMessage({id: 'AGENT.FAX'})}</label>
              <div className="col-md-8">
                <Field
                  type="text"
                  className="form-control"
                  value={values.fax ?? ""}
                  id="fax"
                  name="fax"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-2">
            <div className="row">
              <label htmlFor="tax"
                     className="col-md-4 col-form-label">{intl.formatMessage({id: 'AGENT.TAX'})}</label>
              <div className="col-md-8">
                <Field
                  type="text"
                  className="form-control"
                  value={values.tax ?? ""}
                  id="tax"
                  name="tax"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}
export default AgentBasic