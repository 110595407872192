import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'
import {Link} from 'react-router-dom'
import {RolePermissionModel} from '../../../../modules/auth/models/RoleModels'
import * as PermissionCRUD from './PermissionCRUD'
import * as RoleCRUD from './RoleCRUD'
import PermissionComponent from './Permission'
import { IPermission } from '../../../../modules/auth/models/PermissionsModel'

type Props = {
  className?: string
  data: Array<RolePermissionModel>
  handleDelete: (id: number) => void
  modalVisible: boolean
  toggleModal: (id?: number) => void
  selectedId: number | undefined
  reloadData: boolean
  handleReloadData: () => void
}

const RolesTable: FC<Props> = ({
  className,
  data,
  handleDelete,
  modalVisible,
  toggleModal,
  selectedId,
  reloadData,
  handleReloadData,
}) => {

  const intl = useIntl()
  const [screens, setScreens] = useState<any[]>([])
  const [role, setRole] = useState<RolePermissionModel>()
  const [selectedRolePermissions, setSelectedRolePermissions] = useState<IPermission[]>([])
  const MODE = {
    VIEW: 'Xem',
    CREATE: 'Tạo mới',
    UPDATE: 'Sửa',
    DELETE: 'Xóa',
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const screenData = await PermissionCRUD.getList()
        setScreens(screenData?.data?.data || [])
        if (selectedId) {
          const data = await RoleCRUD.getDetail(selectedId)
          setSelectedRolePermissions(data?.data?.data?.permissions)
          setRole(data?.data?.data)
        }
      } catch (error) {
        console.error('Error fetching screen data:', error)
      }
    }

    fetchData()
  }, [selectedId])

  useEffect(() => {
    if (reloadData) {
      handleReloadData();
    }
  }, [reloadData, handleReloadData]);
  
  const renderListUser = (users: string[] | undefined) => {
    if (!Array.isArray(users)) {
      return null;
    }
    if (users.length === 0) {
      return (
        <span className='badge badge-light-warning me-1'>
          Hiện chưa có
        </span>
      )
    }
    return users.map((user, index) => (
      <span key={`user_${index}`} className='badge badge-light-primary me-1'>
        {user}
      </span>
    ))
  }

  const renderPermissions = (rolePermissions: string[]) => {
    const permissionName = (permissionName: string): string => {
      const translations: Record<string, string> = {
        list: MODE.VIEW,
        create: MODE.CREATE,
        update: MODE.UPDATE,
        delete: MODE.DELETE,
      }
      const parts = permissionName.split('-')
      const lastPart = parts[parts.length - 1]
      return translations[lastPart] || lastPart
    }
    const colorForEachPermission = (permission: string) => {
      switch (permission) {
        case MODE.VIEW:
          return 'primary'
        case MODE.CREATE:
          return 'success'
        case MODE.UPDATE:
          return 'warning'
        case MODE.DELETE:
          return 'danger'
      }
    }

    return screens.map((currentScreen: any) => {
      const modelPermissions = currentScreen.permissions
        .filter((p: any) => rolePermissions.includes(p.name))
        .map((p: any) => permissionName(p.name))

      if (modelPermissions.length > 0) {
        return (
          <div key={currentScreen.id}>
            <div className='d-flex'>
              <span>{currentScreen.name}:</span>
              <div>
                {modelPermissions.map((permission: string, index: number) => (
                  <span
                    key={index}
                    className={`badge badge-light-${colorForEachPermission(permission)} me-1`}
                  >
                    {permission}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )
      }

      return null
    })
  }

  return (
    <div className='table-responsive'>
      {data?.length ? (
        <table className='table table-hover table-bordered table-striped border gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
              <th style={{minWidth: '100px'}}>{intl.formatMessage({id: 'ACCOUNT.ROLE'})}</th>
              <th style={{minWidth: '300px'}}>
                <div>{intl.formatMessage({id: 'ACCOUNT.PERMISSIONS'})}</div>
              </th>
              <th style={{minWidth: '100px'}}>
                <div>Account có quyền này</div>
              </th>
              <th style={{minWidth: '150px'}}>
                <div className='text-center'>{intl.formatMessage({id: 'COMMON.ACTION_TEXT'})}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={`account_${index}`} className='cursor-pointer text-wrap'>
                <td>{item.name}</td>
                <td>
                  <div className='text-center' style={{maxWidth: '400px'}}>
                    {screens.length > 0 && renderPermissions(item.permissions)}
                  </div>
                </td>
                <td>
                  <div>{renderListUser(item.users)}</div>
                </td>
                <td className='text-center'>
                  <div className='text-center'>
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip id='tooltip-create' className='tooltip'>
                          {intl.formatMessage({id: 'COMMON.UPDATE'})}
                        </Tooltip>
                      }
                    >
                      <button
                       className='btn btn-icon btn-light-warning w-30px h-30px ps-2 me-2'
                       onClick={() => toggleModal(item?.id)}
                     >
                       <i className='bi bi-pencil-square fs-4 me-2'></i>
                     </button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip id='tooltip-delete' className='tooltip'>
                          {intl.formatMessage({id: 'COMMON.DELETE'})}
                        </Tooltip>
                      }
                    >
                      <button
                        className='btn btn-icon btn-light-primary w-30px h-30px'
                        type='button'
                        onClick={() => handleDelete(item?.id)}
                      >
                        <i className='bi bi-trash'></i>
                      </button>
                    </OverlayTrigger>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className='text-center'>{intl.formatMessage({id: 'COMMON.NO_DATA'})}</div>
      )}
        <PermissionComponent
          listScreen={screens}
          role={role}
          selectedRole={selectedId}
          selectedRolePermissions={selectedRolePermissions}
          modalVisible={modalVisible}
          toggleModal={toggleModal}
          handleReloadData={handleReloadData}
          setSelectedRolePermissions={setSelectedRolePermissions}
        />
    </div>
  )
}

export default RolesTable
