/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { SupplierModel } from '../../models/SupplierModel'
import { useIntl } from 'react-intl'
import { Paginate } from '../../../_metronic/partials/pagination/Paginate'
import { DATA_PAGINATION_LENGTH, PaginationLength } from '../../../_metronic/partials/pagination/PaginationLength'
import { exportReceipts, getHistory } from './SupplierCRUD'
import { IReceiptSearchModel, ReceiptModel } from '../../models/ReceiptlModel'
import { MetaPaginateModel } from '../../models/PaginateModel'
import { PaginationHeader } from '../../../_metronic/partials/pagination/PaginationHeader'
import { ReceiptDetailModel } from '../../models/ReceiptDetailModel'
import { formatPrice } from '../../selectors'
import useMasterData from '../../hooks/useMaster'
import { GroupSupplier } from '../../modules/auth/models/SupplierModel'
import { download } from '../../modules/common/helper'

type Props = {
  show: boolean
  mode: string
  data?: SupplierModel
  validDate?: string
  onClose: () => void
  onSubmit: (values: any, actions: any) => void
}

const editSchema = Yup.object().shape({
  email: Yup.string().email().nullable().label('Email'),
  paypal_email: Yup.string().email().nullable().label('Paypal account email'),
  shipping_delay: Yup.number().nullable().label('Shipping delay'),
  name: Yup.string().required('Tên nhà cung cấp không được bỏ trống.').max(255).label('Tên nhà cung cấp'),
  phone: Yup.string().required('Số điện thoại nhà cung cấp không được bỏ trống.').label('Số điện thoại nhà cung cấp'),
  address: Yup.string().required('Địa chỉ nhà cung cấp không được bỏ trống.').label('Địa chỉ nhà cung cấp')
});

const initSearch = {
  page: 1,
  per_page: DATA_PAGINATION_LENGTH[0],
}


const SupplierEditModal: React.FC<Props> = ({ show, mode, data, onClose, onSubmit }) => {
  const intl = useIntl();
  const [historyData, setHistoryData] = useState<ReceiptModel[]>();
  const [meta, setMeta] = useState<MetaPaginateModel>();
  const [search, setSearch] = useState<IReceiptSearchModel>(initSearch)
  const {master} = useMasterData();

  useEffect(() => {
    if (show) {
      getData()
    }
  }, [show]);
  let initValues: any = {
    name: '',
    email: '',
    payment_default: '',
    phone: '',
    fax: '',
    address: '',
    contact: '',
    shipping_delay: '',
    comments: ''
  };
  if (data) {
    initValues = {
      ...initValues,
      id: data.id,
      name: data.name,
      email: data.email,
      lable: data.lable,
      supplier_code: data.supplier_code,
      group_supplier_id: data.group_supplier_id,
      website: data.website,
      address: data.address,
      fax: data.fax,
      location: data.location,
      phone: data.phone,
      tax: data.tax,
      name_employee: data.name_employee,
      desc: data.desc,
      receipt_detail_sum_sub_total: formatPrice(data.receipt_detail_sum_sub_total)
    }
  }

  const getData = async (params?: IReceiptSearchModel) => {
    const res = await getHistory({ ...params, supplier_id: data?.id })
    setHistoryData(res.data.data);
    setMeta(res.data.meta);
    setSearch(params ?? {});
  }

  const handleExport = async (supplierId?: number, params?: IReceiptSearchModel ) => {
    if (supplierId) {
      const { data } = await exportReceipts({ ...params, supplier_id: supplierId });
      download(data, 'agency');
    }
  }


  const handlePageChange = (data: any) => {
    getData({ ...search, page: data.selected + 1 })
  }

  const handlePageLengthChange = (e: any) => {
    getData({ ...search, page: 1, per_page: +e.target.value })
  }

  const handleSearch = (e: any) => {
    e.preventDefault();
    getData({ ...search, page: 1 })
  }

  const handleClearSearch = (e: any) => {
    e.preventDefault();
    setSearch({ ...initSearch });
    getData({ ...initSearch })
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      size="xl"
      className='modal-overflow-y'
    >
      <Modal.Header className='d-flex'>
        <Modal.Title>Nhà cung cấp {data?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-10">
        <Formik validationSchema={editSchema} initialValues={initValues} onSubmit={onSubmit} validateOnMount initialTouched={initValues}>
          {({ values, handleChange }) => (
            <Form>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label className="required form-label">Tên NCC</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='name'
                  />
                  <ErrorMessage name='name' className='text-danger' component='span' />
                </div>

                <div className="col-md-4">
                  <label className="form-label">Email</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='email'
                  />
                  <ErrorMessage name='email' className='text-danger' component='span' />
                </div>
                <div className="col-md-4">
                  <label className="form-label">Nhãn</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='lable'
                  />
                  <ErrorMessage name='lable' className='text-danger' component='span' />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4 mb-3">
                  <label className="form-label">Mã NCC</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='supplier_code'
                  />
                  <ErrorMessage name='supplier_code' className='text-danger' component='span' />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Website</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='website'
                  />
                  <ErrorMessage name='website' className='text-danger' component='span' />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Địa chỉ</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='address'
                  />
                  <ErrorMessage name='address' className='text-danger' component='span' />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-4 mb-3">
                  <label className="form-label">Nhóm NCC</label>
                  <Field
                    as='select'
                    name='group_supplier_id'
                    className='form-select'
                  >
                      <option value=""></option>
                      {master?.group_supplier && master?.group_supplier?.map((group: GroupSupplier) => (
                          <option key={group.id} value={group.id}>{group.name}</option>
                      ))}
                  </Field>
                  <ErrorMessage name='group_supplier_id' className='text-danger' component='span' />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Fax</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='fax'
                  />
                  <ErrorMessage name='fax' className='text-danger' component='span' />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Khu vực</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='location'
                  />
                  <ErrorMessage name='location' className='text-danger' component='span' />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4 mb-3">
                  <label className="form-label">Số điện thoại</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='phone'
                  />
                  <ErrorMessage name='phone' className='text-danger' component='span' />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Mã số thuế</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='tax'
                  />
                  <ErrorMessage name='tax' className='text-danger' component='span' />
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Tổng giá trị giao dịch</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='receipt_detail_sum_sub_total'
                  />
                  <ErrorMessage name='receipt_detail_sum_sub_total' className='text-danger' component='span' />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4 mb-3">
                  <label className="form-label">Nhân viên phụ trách</label>
                  <Field
                    type='text'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='name_employee'
                  />
                  <ErrorMessage name='name_employee' className='text-danger' component='span' />
                </div>
                <div className="col-md-8 mb-3">
                  <label className="form-label">Mô tả</label>
                  <Field
                    type='text'
                    as='textarea'
                    className='form-control'
                    placeholder=''
                    autoComplete='nope'
                    name='desc'
                    rows="4"
                  />
                  <ErrorMessage name='desc' className='text-danger' component='span' />
                </div>
              </div>
              {/* <div className='d-flex flex-column mb-7 fv-row'>
                                <label className="required form-label">Name</label>
                                <Field
                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    autoComplete='nope'
                                    name='name'
                                />
                                <ErrorMessage name='name' className='text-danger' component='span' />
                            </div>
                            <div className='d-flex flex-column mb-7 fv-row'>
                                <label className="required form-label">Email</label>
                                <Field
                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    autoComplete='nope'
                                    name='email'
                                />
                                <ErrorMessage name='email' className='text-danger' component='span' />
                            </div>
                            <div className='d-flex flex-column mb-7 fv-row'>
                                <label className=" form-label">Address</label>
                                <Field
                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    autoComplete='nope'
                                    name='address'
                                />
                                <ErrorMessage name='address' className='text-danger' component='span' />
                            </div>
                            <div className='d-flex flex-column mb-7 fv-row'>
                                <label className=" form-label">Contact ID</label>
                                <Field
                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    autoComplete='nope'
                                    name='contact_id'
                                />
                                <ErrorMessage name='contact_id' className='text-danger' component='span' />
                            </div> */}
              <div className='d-flex mb-4 justify-content-end pt-8'>
                <button className="btn btn-bg-secondary mx-2" type="button" onClick={() => onClose()}>
                  {intl.formatMessage({ id: 'COMMON.CLOSE' })}
                </button>
                <button className="btn btn-bg-success text-light ml-2" type="submit">
                  {mode === 'edit' ? intl.formatMessage({ id: 'COMMON.UPDATE' }) :
                    intl.formatMessage({ id: 'COMMON.CREATE' })}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="history">
          <div className="row justify-content-between align-items-center mb-3">
            <div className='col-md-4'>
              <PaginationHeader from={meta?.from ?? 0} to={meta?.to ?? 0} total={meta?.total ?? 0} />
            </div>
            <form className='row mb-3 col-md-8 justify-content-end align-items-center' onSubmit={handleSearch}>
              <div className="form-check col-md-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name='delivering'
                  id='deliveringChk'
                  defaultChecked={search.delivering ?? false}
                  onChange={(e) => setSearch(e.target.checked ? { ...search, delivering: true } : { ...search, delivering: false })}
                />
                <label className="form-check-label" htmlFor="deliveringChk">
                  {intl.formatMessage({ id: 'SUPPLIER.DELIVERING' })}
                </label>
              </div>
              <div className="col-md-4">
                <input
                  id='productName'
                  type="text"
                  className="form-control"
                  placeholder={intl.formatMessage({ id: 'SUPPLIER.PRODUCT_NAME' })}
                  name="product_name"
                  value={search.product_name ?? ''}
                  onChange={(e) => setSearch({ ...search, [e.target.name]: e.target.value })}
                  autoComplete='off'
                />
              </div>
              <div className="row col-md-4 modal-body__footer">
                <button
                  className="col-auto btn btn-primary mx-1"
                  onClick={handleSearch}
                >
                  <i className="bi bi bi-search fs-4"></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
                </button>
                <button
                  className="col-auto btn btn-danger ml-1"
                  type="button"
                  onClick={handleClearSearch}
                >
                  <i className="bi bi-eraser-fill fs-1"></i>
                </button>
                <button className="btn btn-icon btn-sm me-2 ms-1 btn-light-info text-hover-white"
                 type="button"
                  onClick={() => handleExport(data?.id)}>
                <i className="bi bi-cloud-arrow-down-fill fs-1 text-info" />
              </button>
              </div>
            </form>
          </div>

          <div className='table-responsive'>
            <table className="table table-hover table-bordered table-striped border gy-7 gs-7">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th style={{ minWidth: "100px" }}>{intl.formatMessage({ id: 'SUPPLIER.RECEIPT_CODE' })}</th>
                  <th style={{ minWidth: "100px" }}>{intl.formatMessage({ id: 'SUPPLIER.RECEIPT_DATE' })}</th>
                  <th style={{ minWidth: "150px" }}>{intl.formatMessage({ id: 'SUPPLIER.ORDER_CODE' })}</th>
                  <th style={{ minWidth: "250px" }}>{intl.formatMessage({ id: 'SUPPLIER.PRODUCT_NAME' })}</th>
                  <th style={{ minWidth: "100px" }}>{intl.formatMessage({ id: 'SUPPLIER.NUMBER_OF_ORDER' })}</th>
                  <th style={{ minWidth: "100px" }}>{intl.formatMessage({ id: 'SUPPLIER.NUMBER_OF_RECEIPT' })}</th>
                  <th style={{ minWidth: "100px" }}>{intl.formatMessage({ id: 'SUPPLIER.UNIT' })}</th>
                  <th style={{ minWidth: "100px" }}>{intl.formatMessage({ id: 'SUPPLIER.PRICE_SUPPLIER' })}</th>
                </tr>
              </thead>
              <tbody>
                {historyData && historyData?.length > 0 ? historyData?.map((item: ReceiptModel) => {
                  if (item?.receipt_detail && item?.receipt_detail?.length > 0) {
                    return item.receipt_detail?.map((detail: ReceiptDetailModel, index: number) => {
                      return (
                        <tr key={detail.id}>
                          <td>{item.receipt_code}</td>
                          <td>{item.created_at}</td>
                          <td>{detail?.order?.order_code ?? ''}</td>
                          <td>{detail?.inventory_name ?? ''}</td>
                          <td>{detail?.order?.detail.quantity ?? ''}</td>
                          <td>{detail?.quantity ?? ''}</td>
                          <td>{detail.unit}</td>
                          <td>{ formatPrice(detail?.price ?? 0)}</td>
                        </tr>
                      )
                    })
                  }

                  return (
                    <tr key={`history_${item?.id}`} className="cursor-pointer">
                      <td>{item?.receipt_code ?? ''}</td>
                      <td>{item?.created_at ?? ''}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )
                }) : (
                  <tr><td colSpan={8} className='text-center'>{intl.formatMessage({ id: 'COMMON.NO_DATA' })}</td></tr>
                )}
              </tbody>
            </table>
            {historyData && historyData?.length > 0 && (
              <div className="card-footer d-flex justify-content-between">
                <PaginationLength
                  selected={search.per_page ?? DATA_PAGINATION_LENGTH[0]}
                  onChange={handlePageLengthChange}
                />
                <Paginate
                  pageCount={meta?.last_page ?? 0}
                  onPageChange={handlePageChange}
                  forcePage={(search.page ?? 1) - 1}
                />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SupplierEditModal
