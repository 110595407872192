import {FC} from 'react'
import {
  DATA_PAGINATION_LENGTH,
  PaginationLength,
} from '../../../../_metronic/partials/pagination/PaginationLength'
import { IStoreModel, TSearch } from "../../../models/StoreModel";
import {PaginateModel} from '../../../models/PaginateModel'
import {Paginate} from '../../../../_metronic/partials/pagination/Paginate'

interface IProps {
  search: TSearch
  handlePageLengthChange: (e: any) => void
  handlePageChange: (data: any) => void
  dataPaginate?: PaginateModel<IStoreModel>
}

const Pagination: FC<IProps> = ({
  search,
  handlePageLengthChange,
  handlePageChange,
  dataPaginate,
}) => {
  return (
    <>
      <PaginationLength
        selected={search.per_page ?? DATA_PAGINATION_LENGTH[0]}
        onChange={handlePageLengthChange}
      />
      <Paginate
        pageCount={dataPaginate?.meta?.last_page ?? 0}
        onPageChange={handlePageChange}
        forcePage={(search.page ?? 1) - 1}
      />
    </>
  )
}

export default Pagination
