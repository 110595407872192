import {IOrderProduct} from "../models/OrderModel";

// Item is inventory of sento
const getMarketPrice = (item: IOrderProduct) => {
  if (!item) {
    return 0
  }
  const priceOfAgency = item.price_of_agency ?? {};
  let marketPrice = priceOfAgency?.new_market_price ?? priceOfAgency?.market_price ?? 0;
  const result = !!marketPrice ? marketPrice : item.price;
  return isNaN(result) ? 0 : result;
}
export default getMarketPrice;