import {ADMIN_EXPORT_SUPPLIER_HISTORY_ENDPOINT, ADMIN_SUPPLIER_ENDPOINT, ADMIN_SUPPLIER_HISTORY_ENDPOINT} from '../../constants/endpoints'
import {PaginateModel} from '../../models/PaginateModel'
import axios from 'axios'
import {ResponseModel} from '../../models/ResponseModel'
import {SearchModel, SupplierModel} from '../../models/SupplierModel'
import {ReceiptModel} from '../../models/ReceiptlModel'

export function getList(params?: SearchModel) {
  return axios.get<PaginateModel<SupplierModel>>(`${ADMIN_SUPPLIER_ENDPOINT}`, {params})
}

export function getHistory(params?: any) {
  return axios.get<PaginateModel<ReceiptModel>>(`${ADMIN_SUPPLIER_HISTORY_ENDPOINT}`, {params})
}

export function store(supplier: SupplierModel) {
  return axios.post<ResponseModel>(ADMIN_SUPPLIER_ENDPOINT, supplier)
}

export function update(supplier: SupplierModel) {
  return axios.post<ResponseModel>(ADMIN_SUPPLIER_ENDPOINT, supplier)
}

export function remove(ids?: number[]) {
  return axios.delete<ResponseModel>(`${ADMIN_SUPPLIER_ENDPOINT}/destroy`, {params: {ids}})
}

export function exportCsv(params: SearchModel) {
  return axios.get(`${ADMIN_SUPPLIER_ENDPOINT}/export`, {params, responseType: 'blob'})
}

export function exportReceipts(params?: any) {
  return axios.get(`${ADMIN_EXPORT_SUPPLIER_HISTORY_ENDPOINT}`, {params, responseType: 'blob'})
}