import axios from 'axios'
import queryString from 'query-string'
import { IProducts, IProductsWithBestSupplier, ISearch } from '../../../modules/auth/models/ProductsModel'
import { PaginateModel } from '../../../models/PaginateModel'
import { ADMIN_CHECKING_PRODUCT_ENDPOINT, ADMIN_PRODUCTS_ENDPOINT } from '../../../constants/endpoints'

export function getProducts(params?: ISearch) {
  const queryParams = params || {}
  return axios.get<PaginateModel<IProductsWithBestSupplier>>(
    `${ADMIN_PRODUCTS_ENDPOINT}?${queryString.stringify(queryParams, {arrayFormat: 'bracket'})}`
  )
}


export function getProductBySentoCode(name: string) {
  return axios.get<PaginateModel<IProducts>>(`${ADMIN_CHECKING_PRODUCT_ENDPOINT}/${name}`)
}

