import { FC, useState } from 'react'
import { IAccount } from '../../../../modules/auth/models/AccountModel'
import { useIntl } from 'react-intl'
import { OverlayTrigger, Tooltip } from 'react-bootstrap-v5'
import { Link } from 'react-router-dom'
import { HeaderModel } from '../../../../models/CommonModel'
import SortableColumn from '../../../../components/Table/SortableColumn'

type Props = {
    className?: string
    data: Array<IAccount>
    handleDelete: (id: number) => void
    header: HeaderModel[],
    hideCols: string[],
    openColConfig: () => void,
    handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void
}

const AccountsTable: FC<Props> = ({ className, data, handleDelete, header, hideCols, openColConfig, handleSort }) => {
    const [editing, setEditing] = useState<any>({})

    const intl = useIntl()
    const getRole = (roles: any) => {
        if (Array.isArray(roles) && roles.length > 0) {
            return roles[0]?.name
        } else {
            return 'Hiện chưa có'
        }
    }
    const getStatus = (status: any) => {
        return status == 1
            ? intl.formatMessage({ id: 'ACCOUNT.STATUS.ACTIVE' })
            : intl.formatMessage({ id: 'ACCOUNT.STATUS.INACTIVE' })
    }
    const getStoreName = (store: any) => {
        return store?.name || 'Hiện chưa có'
    }
    return (
        <div className='table-responsive'>
            <table className='table table-hover table-bordered table-striped border gy-7 gs-7'>
                <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                        <th>
                            <i
                                className="fa fa-cogs"
                                style={{ cursor: 'pointer' }}
                                onClick={openColConfig}
                            ></i>
                        </th>
                        {header.map((th, index) => {
                            if (!hideCols.includes(th.field)) {
                                if (th.sortable) {
                                    return (
                                        <SortableColumn
                                            key={'th-' + index}
                                            sortBy={th?.field}
                                            onSortChange={handleSort}
                                            style={th?.style ?? {}}
                                        >
                                            {th.label}
                                        </SortableColumn>
                                    )
                                }
                                return (
                                    <th style={th?.style ?? {}}>{th.label}</th>
                                )
                            }
                        })}
                        <th style={{ minWidth: "150px" }}><div className='text-center'>{intl.formatMessage({ id: 'COMMON.ACTION_TEXT' })}</div></th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item: IAccount, index: number) => {
                        return (
                            <tr key={`account_${index}`} className='cursor-pointer text-wrap'>
                                <td></td>
                                {!hideCols.includes('name') && (
                                <td>{item.name}</td>
                                )}
                                {!hideCols.includes('store_name') && (
                                <td><div className='text-left' style={{ maxWidth: '400px' }} >{getStoreName(item.store)}</div></td>
                                )}
                                {!hideCols.includes('phone') && (
                                <td><div className='text-left'>{item?.phone}</div></td>
                                )}
                                {!hideCols.includes('status') && (
                                <td><div className='text-left'>{getStatus(item?.status)}</div></td>
                                )}
                                {!hideCols.includes('roles') && (
                                <td><div className='text-left'>{getRole(item?.roles)}</div></td>
                                )}
                                <td className='text-center'>
                                    <div className='text-center'>
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip id='tooltip-create' className='tooltip'>
                                                    {intl.formatMessage({ id: 'COMMON.UPDATE' })}
                                                </Tooltip>
                                            }
                                        >
                                            <Link to={`/account/edit/${item.id}`}>
                                                <button className='btn btn-icon btn-light-warning w-30px h-30px ps-2 me-2'>
                                                    <i className='bi bi-pencil-square fs-4 me-2'></i>
                                                </button>
                                            </Link>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip id='tooltip-delete' className='tooltip'>
                                                    {intl.formatMessage({ id: 'COMMON.DELETE' })}
                                                </Tooltip>
                                            }
                                        >
                                            <button
                                                className='btn btn-icon btn-light-primary w-30px h-30px'
                                                type='button'
                                                onClick={() => handleDelete(item?.id)}
                                            >
                                                <i className='bi bi-trash'></i>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default AccountsTable
