import axios from 'axios'
import { IAgent, ISearch } from '../../../models/AgentModel'
import { PaginateModel } from '../../../models/PaginateModel'
import { ADMIN_SALES_ENDPOINT } from '../../../constants/endpoints'

export function getAgents(params?: ISearch) {
  return axios.get<PaginateModel<IAgent>>(`${ADMIN_SALES_ENDPOINT}/agency`, {params})
}

export function getAllAgents(params?: ISearch) {
  return axios.get<PaginateModel<IAgent>>(`${ADMIN_SALES_ENDPOINT}/agency/get-all`, {params})
}

export function getListProducts(params: ISearch) {
  return axios.get(`${ADMIN_SALES_ENDPOINT}/agency/products`, {params})
}
