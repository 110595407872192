import React, {FC, useRef} from "react";
import {useIntl} from "react-intl";
import _ from "lodash";
import { NumericFormat } from "react-number-format";
import { formatPrice, revertPriceNumner } from "../../../../selectors";
import { IReceiptDetail } from "../../../../models/WareHouseModel";
import { ReceiptDetailModel } from "../../../../models/ReceiptDetailModel";
import { HeaderModel } from "../../../../models/CommonModel";
import SortableColumn from "../../../../components/Table/SortableColumn";
import moment from "moment";

type TProps = {
    data: Array<ReceiptDetailModel>,
    header: HeaderModel[]
    hideCols: string[],
    openColConfig: () => void,
    handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void
}

const ReceiptProductsTable: FC<TProps> = ({
  data,
  header,
  hideCols,
  handleSort
  }) => {
  const intl = useIntl();
  return <>
    <div className='table-responsive mt-5'>
      <table className={`table table-bordered border gy-7 gs-7 `}>
        <thead>
        <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
            <th>
            </th>
            {header.map((th, index) => {
              if (!hideCols.includes(th.field)) {
                  if (th.sortable) {
                      return (
                          <SortableColumn
                              key={'th-' + index}
                              sortBy={th?.field}
                              onSortChange={handleSort}
                              style={th?.style ?? {}}
                          >
                              {th.label}
                          </SortableColumn>
                      )
                  }
                  return (
                      <th style={th?.style ?? {}}>{th.label}</th>
                  )
              }
          })}
        </tr>
        </thead>
        <tbody>
        {data?.map((item: ReceiptDetailModel, index: number) => {
            return (
              <tr key={`order_product${index}`} className={`cursor-pointer`}>
                <td>
                </td>
                {!hideCols.includes('inventory_name') && (
                  <td>
                      {item.inventory_name}
                  </td>
                )}
                {!hideCols.includes('supplier_name') && (
                  <td>
                      {item.supplier?.name ?? ''}
                  </td>
                )}
                {!hideCols.includes('quantity') && (
                  <td>
                      {item.quantity}
                  </td>
                )}
                {!hideCols.includes('unit') && (
                  <td>
                      {item.unit}
                  </td>
                )}
                {!hideCols.includes('price') && (
                  <td>{formatPrice(item.price ?? 0)}</td>
                )}
                {!hideCols.includes('discount') && (
                  <td>{item.discount?.toFixed(2)}%</td>
                )}
                {!hideCols.includes('sub_total') && (
                  <td>{formatPrice(item.sub_total ?? 0)}</td>
                )}
                {!hideCols.includes('receipt_code') && (
                  <td>{item.receipt?.receipt_code}</td>
                )}
                {!hideCols.includes('approved_at') && (
                  <td >{item?.receipt?.approved_at ?? ''}</td>
                )}
              </tr>
            )
        })}
        </tbody>
      </table>
    </div>
  </>
}
export default ReceiptProductsTable