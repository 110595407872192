import React, {FC} from 'react'
import {useIntl} from "react-intl";
import OrderDetail from "./OrderDetail";
import {useLocation, useParams} from "react-router-dom";
import OrderCreate from "./OrderCreate";
import { PageTitle } from '../../../../../_metronic/layout/core';

interface TLocation {
  order_code?: string;
}


const OrderDetailWrapper: FC = () => {

  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({id: 'MENU.ORDER'}),
      path: '/orders',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]

  const {id} = useParams<{ id?: string }>();
  const location = useLocation<TLocation>();

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}>{id ? (location.state?.order_code ?? id) : intl.formatMessage({id: "COMMON.CREATE"})}</PageTitle>
      {!id ? <OrderCreate/> : <OrderDetail />}
    </>
  )
}

export default OrderDetailWrapper
