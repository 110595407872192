import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PaginateModel } from "../../models/PaginateModel";
import * as SupplierCRUD from './SupplierCRUD';
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../_metronic/partials/pagination/PaginationLength";
import { Paginate } from "../../../_metronic/partials/pagination/Paginate";
import { PaginationHeader } from "../../../_metronic/partials/pagination/PaginationHeader";
import { Link, useHistory } from "react-router-dom";
import { download, Toast } from "../../modules/common/helper";
import queryString from 'query-string'
import { ModalModel, SearchModel, SupplierModel } from "../../models/SupplierModel";
import SupplierTable from "./SupplierTable";
import SupplierCreateModal from "./SupplierCreateModal";
import SupplierEditModal from "./SupplierEditModal";
import { includes, without, map } from 'lodash';
import { Confirm } from "../../modules/common/helper";
import { Sento, UserLoggedIn } from "../../hooks/PermissionChecker";
import { HeaderModel } from "../../models/CommonModel";
import ConfigModal from "./ConfigModal";
import { generateCode } from "../../selectors";
import useMasterData from "../../hooks/useMaster";

const Supplier: FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const [dataPaginate, setDataPaginate] = useState<PaginateModel<SupplierModel>>();
  const [arrDelete, setArrDelete] = useState<number[]>([])
  const [hideCols, setHideCols] = useState<string[]>([]);
  const sento = Sento();
  const master = useMasterData();
  const [historyModal, setHistoryModal] = useState({
    show: false,
    data: {} as SupplierModel
  });
  const [configModal, setConfigModal] = useState<ModalModel>({
    show: false,
    mode: 'add',
  });
  const [createModal, setCreateModal] = useState<ModalModel>({
    show: false,
    mode: 'add',
  });

  const [editModal, setEditModal] = useState<ModalModel>({
    show: false,
    mode: 'edit',
  });

  const initSearch = {
    page: 1,
    per_page: DATA_PAGINATION_LENGTH[0],
    store_id: UserLoggedIn().store_id
  }
  const [search, setSearch] = useState<SearchModel>(initSearch)
  const [paramsExport, setParamsExport] = useState<SearchModel>({})

  const parseParams = (params: SearchModel) => {
    if (params.page) {
      params.page = +params.page
    }
    if (params.per_page) {
      params.per_page = +params.per_page
    }
    return params
  }

  useEffect(() => {
		document.title = 'Sento - Danh sách nhà cung cấp';
	})

  useEffect(() => {
    const searchHistory = history.location.search;
    const initParams: SearchModel = parseParams(queryString.parse(searchHistory))
    getData({ ...search, ...initParams });
  }, []);

  const getData = async (params?: SearchModel) => {
    const { data: dataPaginate } = await SupplierCRUD.getList(params);
    const objQuery = { ...params }
    if (objQuery.page === 1) {
      delete objQuery.page
    }
    if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
      delete objQuery.per_page
    }
    history.push({ search: `?${queryString.stringify(objQuery)}` })
    setDataPaginate(dataPaginate);
    setSearch(params ?? {});
    setParamsExport({ ...params })
  }

  const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
    const currentSearch = { ...search };
    const sortParams: SearchModel = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
    if (direction === 'none') {
      delete sortParams.sort_by;
      delete sortParams.sort_direction;
    }
    getData({ ...sortParams });
  }

  const handlePageChange = (data: any) => {
    getData({ ...search, page: data.selected + 1 })
  }

  const handlePageLengthChange = (e: any) => {
    getData({ ...search, page: 1, per_page: +e.target.value })
  }

  const handleSearch = (e: any) => {
    e.preventDefault();
    getData({ ...search, page: 1 })
  }

  const handleClearSearch = (e: any) => {
    e.preventDefault();
    setSearch({ ...initSearch });
    getData({ ...initSearch });
    setArrDelete([]);
  }

  const handleExport = async () => {
    const { data } = await SupplierCRUD.exportCsv({ ...paramsExport });
    download(data, 'agency');
  }

  const handleEdit = (item: SupplierModel) => {
    setEditModal({
      show: true,
      mode: 'edit',
      data: item
    });
  }

  const handleSubmit = async (values: any, actions: any) => {
    let result;
    let message: string = 'Success';
    if (values?.id) {
      result = SupplierCRUD.update(values);
      message = 'Update nhà cung cấp thành công!'
    } else {
      let name = values.name;
      let supplierCode = generateCode(name);
      
      result = SupplierCRUD.store({...values, supplier_code: supplierCode, status: 'Đang giao dịch'});
      message = 'Tạo nhà cung cấp thành công!'
    }
    result.then(res => {
      Toast.fire({
        icon: 'success',
        title: message
      })
      setCreateModal({ ...createModal, show: false })
      getData({ ...search, page: 1 })

    }).catch(error => {
      const response = error.response
      let message: string = '';
      if (response?.status === 422) {
        if (response?.data?.errors) {
          const arrMess = [];
          for (const [key, mess] of Object.entries(response?.data?.errors)) {
            arrMess.push(mess);
          }
          message = arrMess.join("\n")
        }
      } else {
        message = 'Đã có lỗi xảy ra vui lòng liên hệ Admin'
      }
      Toast.fire({
        icon: 'error',
        title: message
      })
    });
  };

  const handleDelete = async () => {
    Confirm.fire({
      icon: 'question',
      title: intl.formatMessage({ id: 'COMMON.CONFIRM_DELETE' }, { name: 'các NCC đã chọn' }),
      confirmButtonText: intl.formatMessage({ id: 'COMMON.YES' }),
      cancelButtonText: intl.formatMessage({ id: 'COMMON.NO' }),
    }).then(async (res) => {
      if (res.isConfirmed) {
        const res = await SupplierCRUD.remove(arrDelete);
        Toast.fire({
          icon: 'success',
          title: res?.data?.message || intl.formatMessage({ id: 'COMMON.SUCCESS_NOTY' }),
        })
        getData({ ...search, page: 1 })
      }
    })
  }

  const handleCheckAll = (e: any) => {
    const checked = e.target.checked;
    let ids: number[] = [];
    if (checked) {
      ids = map(dataPaginate?.data, 'id');
    }
    setArrDelete([...ids]);
  }

  const handleCheckbox = (id: number) => {
    const ids = includes(arrDelete, id) ? without(arrDelete, id) : [...arrDelete, id];
    setArrDelete([...ids]);
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  }

  const header: HeaderModel[] = [
    {
        label: intl.formatMessage({ id: 'SUPPLIER.NAME' }),
        style: { minWidth: "150px" },
        field: 'name',
        sortable: true
    },
    {
        label: intl.formatMessage({ id: 'SUPPLIER.PHONE' }),
        style:  {},
        field: 'phone',
        sortable: true
    },
    {
        label: intl.formatMessage({ id: 'SUPPLIER.EMAIL' }),
        style:  { minWidth: "100px" },
        field: 'email',
        sortable: true
    },
    {
        label: intl.formatMessage({ id: 'SUPPLIER.ADDRESS' }),
        style:  { minWidth: "150px" },
        field: 'address',
        sortable: true
    },
    {
        label: intl.formatMessage({ id: 'SUPPLIER.DESCRIPTION' }),
        style:  { minWidth: "120px" },
        field: 'desc',
        sortable: true
    },
    {
        label: intl.formatMessage({ id: 'SUPPLIER.STATUS' }),
        style:  { minWidth: "80px" },
        field: 'status',
        sortable: true
    }
  ]

  const handleChangeVisCols = (e: any) => {
    let hiddenCols = [...hideCols];
    if (!e.target?.checked) {
        setHideCols([...hiddenCols, e.target.name])
    } else {
        setHideCols(hiddenCols.filter((item) => item !== e.target.name ))
    }
  }
  return (
    <div className="card">
      <div className="card-header py-4">
        <div className="col-md-12">
          <form onSubmit={handleSearch}>
            <div className="row">
              <div className="col-md-2">
                <label className="form-label">{intl.formatMessage({ id: 'SUPPLIER.NAME' })}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  name="name"
                  value={search.keyword || ''}
                  onChange={(e) => setSearch({ ...search, keyword: e.target.value })}
                  autoComplete='nope'
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </div>
              {sento &&
                <div className="col-md-3 mb-2">
                  <label className="form-label">Store</label>
                  <select
                    className="form-select"
                    value={search.store_id}
                    onChange={(e) => setSearch({...search, store_id: e.target.value})}
                    name="store_id"
                  >
                    <option value=''>Tất cả</option>
                    {master?.master?.stores?.map((store, index) => {
                      return (
                        <option value={store.id}>{store.name}</option>
                      )
                    })}
                  </select>
                </div>
                }
              <div className="col-md-4">
                <div className="row">
                  <button
                    className="col-auto btn btn-primary mt-8 me-2"
                    onClick={handleSearch}
                  >
                    <i className="bi bi bi-search fs-4"></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
                  </button>
                  <button
                    className="col-auto btn btn-danger mt-8 me-2"
                    type="button"
                    onClick={handleClearSearch}
                  >
                    <i className="bi bi-eraser-fill fs-1"></i> Re-search
                  </button>
                </div>

              </div>
              <div className="col-md-2 text-end">
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="card-body">
        <div className="row justify-content-between align-items-center mb-3">
          <div className='col-md-6'>
            <PaginationHeader from={dataPaginate?.meta?.from || 0} to={dataPaginate?.meta?.to || 0}
              total={dataPaginate?.meta?.total || 0} />
          </div>
          <div className='col-md-6 text-end'>
            {arrDelete.length > 0 && <button
              className="btn btn-danger btn-sm me-2"
              type="button"
              onClick={handleDelete}
            >
              <i className="bi bi-trash"></i> {intl.formatMessage({ id: 'SUPPLIER.REMOVE_SUPPLIERS_SELECTED' })}
            </button>}
            <button className="btn btn-icon btn-sm me-2 btn-light-info text-hover-white" type="button"
              onClick={handleExport}>
              <i className="bi bi-cloud-arrow-down-fill fs-1 text-info" />
            </button>
            <Link
              className="btn btn-primary btn-sm"
              to="#"
              onClick={() => {
                setCreateModal({
                  show: true,
                  mode: 'add'
                })
              }}
            ><i className="bi bi-plus fs-4"></i> {intl.formatMessage({ id: 'COMMON.CREATE' })}
            </Link>
          </div>
        </div>

        <SupplierTable
          data={dataPaginate?.data || []}
          arrDelete={arrDelete}
          onEdit={handleEdit}
          handleCheckbox={handleCheckbox}
          handleCheckAll={handleCheckAll}
          openColConfig={() => setConfigModal({...configModal, show: true})}
          header={header}
          hideCols={hideCols}
          handleSort={handleSort}
        />
      </div>
      <div className="card-footer d-flex justify-content-between">
        <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
        <Paginate
          pageCount={dataPaginate?.meta?.last_page || 0}
          onPageChange={handlePageChange}
          forcePage={(search.page || 1) - 1}
        />
      </div>
      <SupplierCreateModal
        {...createModal}
        onClose={() => { setCreateModal({ ...createModal, show: false }) }}
        onSubmit={handleSubmit}
      />
      <SupplierEditModal
        {...editModal}
        onClose={() => { setEditModal({ ...editModal, show: false }) }}
        onSubmit={handleSubmit}
      />
      <ConfigModal
          {...configModal}
          header={header}
          hideCols={hideCols}
          handleChangeVisCols={handleChangeVisCols}
          onClose={() => { setConfigModal({ ...configModal, show: false }) }}
      />
    </div>
  )
}
export default Supplier;