import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import _, { debounce, map } from "lodash";
import { Toast } from "../../../modules/common/helper";
import { Prompt, useHistory } from "react-router-dom";
import * as Yup from "yup";
import axios, { AxiosError } from "axios";
import { IAxiosError } from "../../../models/AxiosModel";
import { HTTP_UNPROCESSABLE_ENTITY } from "../../../constants/status";
import ProductTable from "./ProductTable";
import { PaginationHeader } from "../../../../_metronic/partials/pagination/PaginationHeader";
import { PaginateModel } from "../../../models/PaginateModel";
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../../_metronic/partials/pagination/PaginationLength";
import { Paginate } from "../../../../_metronic/partials/pagination/Paginate";
import { Dictionary } from "@reduxjs/toolkit";
import { AsyncSelect2 } from "../../../../_metronic/partials/select/AsyncSelect2";
import { GET_SALES_OPTIONS } from "../../../constants/endpoints";
import * as SalesProductCRUD from "../SalesProductCRUD";
import { SearchModel } from "../../../models/SalesProductModel";
import { IInventoryOfSento } from "../../../models/AgentProduct";
import { Form, Formik, FormikHelpers } from "formik";
import { revertPriceNumner } from "../../../selectors";

export interface selectedProdModel {
    id: number,
    price: number
}
const AddProducts: FC = () => {
    const initSearch = {
        page: 1,
        per_page: 10,
    }

    const intl = useIntl();

    const schema = Yup.object().shape({
        //sales_id: Yup.object().nullable().required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { name: 'Nhân viên kinh doanh' })),
    });

    const [products, setProducts] = useState<PaginateModel<IInventoryOfSento>>()
    const [placedProducts, setPlacedProducts] = useState<Dictionary<IInventoryOfSento>>({})
    const [selectedProds, setSelectedProds] = useState<selectedProdModel[]>([])
    const [needAlert, setNeedAlert] = useState<any>(false)
    const [search, setSearch] = useState<SearchModel>(initSearch)
    const [data, setData] = useState<IInventoryOfSento>()
    const [hideCols, setHideCols] = useState<string[]>([]);

    const history = useHistory();

    useEffect(() => {
        document.title = 'Sento - Thêm sản phẩm cho nhân viên kinh doanh';
    })

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (Object.keys(placedProducts).length > 0) {
            setNeedAlert(true)
        }
    }, [placedProducts]);

    const getListProducts = async (params: SearchModel) => {
        let { data } = await SalesProductCRUD.getListProducts({ ...params })
        setProducts(data);
    }

    const handlePageLengthChange = (e: any) => {
        getListProducts({ ...search, page: 1, per_page: +e.target.value })
    }

    const handlePageChange = (data: any) => {
        getListProducts({ ...search, page: data.selected + 1 })
    }

    const onSubmit = async (values: any, { setFieldError }: FormikHelpers<any>) => {
        const countItem = selectedProds?.length ?? 0;
        if (countItem <= 0) {
            Toast.fire({
                icon: 'warning',
                title: 'Vui lòng chọn sản phẩm!',
            })
            return;
        }
        try {
            let params: any = {
                sales_id: values.sales_id,
                selected_prods: selectedProds
            }
            const {data} = await SalesProductCRUD.assignProducs({...params});
            Toast.fire({
                icon: 'success',
                title: data?.message || intl.formatMessage({id: 'COMMON.SUCCESS_NOTY'}),
            })
            setNeedAlert(false);
            history.push('/sales-products')
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError<IAxiosError>;
                if (axiosError.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
                    const errors = axiosError.response?.data?.errors ?? []
                    Object.keys(errors)?.map((key: string) => setFieldError(key, errors[key][0]));
                }
            }
        }
    }

    const debounceGetListProd = useCallback(debounce((params) => getListProducts(params), 300), [placedProducts]);

    const onSearchProducts = (e: any, name = 'sales_id') => {
        const value = e.target.value;
        let params = { ...search, ...initSearch, [name]: null }
        if (value) {
            params = {
                ...params,
                [name]: value
            }
        }
        setSearch(params)
        debounceGetListProd(params);
    }

    const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
        const currentSearch = { ...search };
        const sortParams: SearchModel = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
        if (direction === 'none') {
            delete sortParams.sort_by;
            delete sortParams.sort_direction;
        }
        getListProducts({ ...sortParams });
    }

    const handleCheckbox = (id: number, price: number, checked: boolean) => {
        var curSelected = [...selectedProds];
        if (checked) {
            curSelected = [...curSelected, {id: id, price: price}];
        } else {
            curSelected = curSelected.filter((e) => {
                return e.id != id;
            })
        }
        setSelectedProds(curSelected);
    }

    const handleCheckAll = (checked: boolean) => {
        var curSelected = [...selectedProds];
        var curProds: selectedProdModel[] = [];
        if (products) {
            curProds = products?.data?.map((e) => {
                return {
                    id: e.id ?? 0,
                    price: e.price ?? 0
                }
            });
        }
        if (checked) {
            curSelected = curSelected.concat(curProds);
        } else {
            curSelected = curSelected.filter((e) => {
                return !_.some(curProds, {id: e.id});
            })
        }
        setSelectedProds(curSelected);
    }

    const handleChangeProdVal = (e: any, item: IInventoryOfSento) => {
        let val = revertPriceNumner(e.target.value);
        let sItem = {id: item.id ?? 0, price: val};
        var curSelected = [...selectedProds];
        let sIndex = _.findIndex(curSelected, {id: item.id});
        if (sIndex >= 0) {
            sItem = {...curSelected[sIndex], price: val};
            curSelected[sIndex] = sItem
        } else {
            curSelected = curSelected.concat(sItem);
        }
        
        setSelectedProds(curSelected);
    }
    return <Formik enableReinitialize validationSchema={schema} initialValues={{ ...data }} onSubmit={onSubmit}>
        {({ values, handleChange, setTouched }) => (
            <div className="card">
                <Prompt
                    when={needAlert}
                    message="Dữ liệu đơn hàng chưa được lưu, bạn có chăc chắn muốn chuyển trang?"
                />
                <Form>
                    <div className="card-header pb-5">
                        <div className="col-12">
                            <div className="row mt-2">
                                <label htmlFor="note"
                                    className="col-4 col-md-3 col-form-label mb-2 required">Nhân viên kinh doanh</label>
                                <div className="col-8 col-md-4 mb-2">
                                    <AsyncSelect2
                                        isGray
                                        sourceUrl={`${GET_SALES_OPTIONS}`}
                                        defaultOptions
                                        default
                                        className="mt-8"
                                        onChange={(selectedOption: any) => {
                                            handleChange({target: {
                                                name: 'sales_id', 
                                                value: selectedOption.value,
                                                label: selectedOption.label
                                            }});
                                            onSearchProducts({target: {
                                                name: 'sales_id', 
                                                value: selectedOption.value,
                                                label: selectedOption.label
                                            }})
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-success me-3"
                                    type="submit">{intl.formatMessage({ id: 'COMMON.SAVE' })}</button>
                                <button className="btn btn-secondary" type="button"
                                    onClick={history.goBack}>{intl.formatMessage({ id: 'COMMON.BACK' })}</button>
                            </div>
                        </div>
                    </div>
                </Form>

                <div className="card-body">
                    <div className="row mt-2">
                        <label htmlFor="product_info"
                            className="col-4 col-md-2 col-form-label mb-2">{intl.formatMessage({ id: 'PRODUCTS.INFO' })}</label>
                        <div className="col-8 col-md-4 mb-2">
                            <input
                                type='text'
                                className='form-control'
                                name="product_name"
                                onChange={(e) => onSearchProducts(e, 'product_name')}
                            />
                        </div>
                    </div>
                   
                            <div className="justify-content-between align-items-center mt-5">
                                <PaginationHeader from={products?.meta?.from || 0} to={products?.meta?.to || 0}
                                    total={products?.meta?.total || 0} />
                            </div>
                            <ProductTable
                                products={products} 
                                flagPlacedProd={1}
                                handleSort={handleSort} 
                                hideCols={hideCols}
                                setHideCols={setHideCols}
                                handleCheckbox={handleCheckbox}
                                handleCheckAll={handleCheckAll}
                                selectedProds={selectedProds}
                                handleChangeProdVal={handleChangeProdVal}
                            />
                            <div className="d-flex justify-content-between">
                                <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]}
                                    onChange={handlePageLengthChange} />
                                <Paginate
                                    pageCount={products?.meta?.last_page || 0}
                                    onPageChange={handlePageChange}
                                    forcePage={(search.page || 1) - 1}
                                />
                            </div>
                </div>
            </div>
        )}
    </Formik>
}
export default AddProducts;