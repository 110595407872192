import { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import queryString from 'query-string'
import Flatpickr from "react-flatpickr";
import {Vietnamese} from "flatpickr/dist/l10n/vn.js"
import { PaginateModel } from "../../../models/PaginateModel";
import { AbilityModel, SearchModel } from "../../../models/AbilityModel";
import { formatPrice, openNewTabWithHtml } from "../../../selectors";
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../../_metronic/partials/pagination/PaginationLength";
import * as AbilitiesCRUD from '../AbilitiesCRUD';
import { PaginationHeader } from "../../../../_metronic/partials/pagination/PaginationHeader";
import { Paginate } from "../../../../_metronic/partials/pagination/Paginate";
import PaymentVoucherTable from "./PaymentVoucherTable";
import { ABILITIES_TYPES, PAYMENT_VOUCHER } from "../../../constants/common";
import PaymentVoucherModal from "./PaymentVoucherModal";
import { Toast, download } from "../../../modules/common/helper";
import moment from "moment";
import {Sento, UserLoggedIn} from "../../../hooks/PermissionChecker";
import { HeaderModel, ModalModel } from "../../../models/CommonModel";
import ConfigModal from "./ConfigModal";
import useMasterData from "../../../hooks/useMaster";

const PaymentVoucher: FC = () => {
    const intl = useIntl();
    const history = useHistory();
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<AbilityModel>>();
    const sento = Sento();
    const { master } = useMasterData();
    const fp = useRef<any>(null);
    const [hideCols, setHideCols] = useState<string[]>([]);
    const [configModal, setConfigModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const type: any = ABILITIES_TYPES.find((e) => {
        return e.label == PAYMENT_VOUCHER
    });
    const [paymentVoucherModal, setModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const initSearch = {
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0],
        store_id: UserLoggedIn().store_id
    }
    const [search, setSearch] = useState<SearchModel>(initSearch)
    const [subTotal, setSubTotal] = useState<number>(0)
    const parseParams = (params: SearchModel) => {
        if (params.page) {
            params.page = +params.page
        }
        if (params.per_page) {
            params.per_page = +params.per_page
        }
        return params
    }

    useEffect(() => {
		document.title = 'Sento - Phiếu chi';
	})

    useEffect(() => {
        const searchHistory = history.location.search;
        const initParams: SearchModel = parseParams(queryString.parse(searchHistory))
        getData({ ...search, ...initParams });
    }, []);

    const getData = async (params?: SearchModel) => {
        const { data: resData } = await AbilitiesCRUD.getList({...params, type: type?.id});
        const objQuery = { ...params }
        if (objQuery.page === 1) {
            delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
            delete objQuery.per_page
        }
        history.push({ search: `?${queryString.stringify(objQuery)}` })
        setDataPaginate(resData?.paginate_data);
        setSubTotal(resData?.sub_total);
        setSearch(params ?? {});
    }

    const handlePageChange = (data: any) => {
        getData({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getData({ ...search, page: 1, per_page: +e.target.value })
    }

    const handleSearch = (e: any) => {
        e.preventDefault();
        getData({ ...search, page: 1 })
    }

    const handleClearSearch = (e: any) => {
        e.preventDefault();
        setSearch(initSearch);
        if (fp.current?.flatpickr) {
            fp.current.flatpickr.clear();
        }
        getData(initSearch)
    }

    const handleSubmit = async (values: any, actions: any) => {
        let result;
        result = AbilitiesCRUD.store(values);
        let message = 'Tạo phiếu thu thành công!'
        result.then(res => {
            Toast.fire({
                icon: 'success',
                title: message
            })
            setModal({ ...paymentVoucherModal, show: false })
            getData({ ...search, page: 1 })
        }).catch(error => {
            const response = error.response
            let message: string = '';
            if (response?.status === 422) {
                if (response?.data?.errors) {
                    const arrMess = [];
                    for (const [key, mess] of Object.entries(response?.data?.errors)) {
                        arrMess.push(mess);
                    }
                    message = arrMess.join("\n")
                }
            } else {
                message = 'Đã có lỗi xảy ra vui lòng liên hệ Admin'
            }
            Toast.fire({
                icon: 'error',
                title: message
            })
        });
    };

    const handleExportPdf = async (id: number) => {
        const {data: resData} = await AbilitiesCRUD.exportPdf({id: id, type: type?.id})
        openNewTabWithHtml(resData);
    }
    
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleSearch(e);
        }
    }

    const header: HeaderModel[] = [
        {
            label: intl.formatMessage({ id: 'ABILITIES.DATE' }),
            style: { minWidth: "150px" },
            field: 'date',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ABILITIES.PAYER' }),
            style: { minWidth: "150px" },
            field: 'name',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ABILITIES.DESCRIPTION' }),
            style: { minWidth: "250px" },
            field: 'note',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ABILITIES.PAYMENT_AMOUNT' }),
            style: { minWidth: "150px" },
            field: 'value',
            sortable: true
        },
        {
            label: 'Phương thức thanh toán',
            style: { minWidth: "150px" },
            field: 'payment_method',
            sortable: true
        }
    ]

    const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
        const currentSearch = { ...search };
        const sortParams: SearchModel = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
        if (direction === 'none') {
            delete sortParams.sort_by;
            delete sortParams.sort_direction;
        }
        getData({ ...sortParams });
    }

    const handleChangeVisCols = (e: any) => {
        let hiddenCols = [...hideCols];
        if (!e.target?.checked) {
            setHideCols([...hiddenCols, e.target.name])
        } else {
            setHideCols(hiddenCols.filter((item) => item !== e.target.name))
        }
    }

    const handleExport = async () => {
        const { data } = await AbilitiesCRUD.exportCsv({ ...search, type: type?.id });
        download(data, 'ability');
    }

    return <>
        <div className="card">
            <div className="card-header py-4">
                <div className="col-md-12">
                    <form onSubmit={handleSearch}>
                        <div className="row">
                            <div className="col-md-2">
                                <label className="form-label">{intl.formatMessage({ id: 'COMMON.KEYWORD' })}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="keyword"
                                    value={search.keyword || ''}
                                    onChange={(e) => setSearch({ ...search, keyword: e.target.value })}
                                    autoComplete='nope'
                                    onKeyDown={(e) => handleKeyDown(e)}
                                />
                            </div>
                            <div className='col-md-3 mb-2'>
                                <label className="form-label">{intl.formatMessage({id: 'COMMON.TIME'})}</label>
                                <Flatpickr
                                    ref={fp}
                                    onChange={(date) => {
                                        let startDate = moment(date[0]).format('YYYY-MM-DD');
                                        let endDate = moment(date[1]).format('YYYY-MM-DD');
                                        setSearch({ ...search, date: { startDate, endDate } });
                                    }}
                                    options={{
                                    mode: "range",
                                    dateFormat: "d-m-Y",
                                    locale: Vietnamese,
                                    shorthandCurrentMonth: true
                                    }}
                                    className='form-control'
                                    placeholder=''
                                />
                                </div>
                                <div className="col-md-3 mb-2">
                                    <label className="form-label">Dòng tiền thu</label>
                                    <select
                                        className="form-select"
                                        value={search.payment_method}
                                        onChange={(e) => setSearch({ ...search, payment_method: e.target.value })}
                                        name="payment_method"
                                    >
                                        <option value=''>Tất cả</option>
                                        <option value='banking'>Chuyển khoản</option>
                                        <option value='cash'>Tiền mặt</option>
                                    </select>
                                </div>
                                {sento &&
                                <div className="col-md-3 mb-2">
                                    <label className="form-label">Store</label>
                                    <select
                                        className="form-select"
                                        value={search.store_id}
                                        onChange={(e) => setSearch({ ...search, store_id: e.target.value })}
                                        name="store_id"
                                    >
                                        <option value=''>Tất cả</option>
                                        {master?.stores?.map((store, index) => {
                                            return (
                                                <option value={store.id}>{store.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            }
                            <div className="col-md-4">
                                <div className="row">
                                    <button
                                        className="col-auto btn btn-primary mt-8 me-2"
                                        onClick={handleSearch}
                                    >
                                        <i className="bi bi bi-search fs-4"></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
                                    </button>
                                    <button
                                        className="col-auto btn btn-danger mt-8 me-2"
                                        type="button"
                                        onClick={handleClearSearch}
                                    >
                                        <i className="bi bi-eraser-fill fs-1"></i> Re-search
                                    </button>
                                    <button className="btn btn-icon mt-8 btn-sm me-2 ms-auto btn-light-info text-hover-white" type="button"
                                    onClick={handleExport}>
                                        <i className="bi bi-cloud-arrow-down-fill fs-1 text-info" />
                                    </button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card-body">
                <div className="row justify-content-between align-items-center mb-3">
                    <div className='col-7 col-md-9'>
                        <PaginationHeader from={dataPaginate?.meta?.from || 0} to={dataPaginate?.meta?.to || 0}
                            total={dataPaginate?.meta?.total || 0} />
                        <h3>Tổng cộng: <span className="text-bold">{ formatPrice(subTotal) } VNĐ</span></h3>
                    </div>
                    <div className='col-5 col-md-3 text-end'>
                        <button
                            className="col-auto btn btn-primary btn-sm"
                            type="button"
                            onClick={() => setModal({...paymentVoucherModal, show: true})}
                        >
                            <i className="bi bi-plus fs-14"></i> {intl.formatMessage({id: 'COMMON.CREATE'})}
                        </button>
                    </div>
                </div>
                <PaymentVoucherTable 
                    data={dataPaginate?.data || []}
                    handleExportPdf={handleExportPdf}
                    openColConfig={() => setConfigModal({ ...configModal, show: true })}
                    header={header}
                    hideCols={hideCols}
                    handleSort={handleSort}
                />
                <PaymentVoucherModal
                    {...paymentVoucherModal}
                    onClose={() => { setModal({ ...paymentVoucherModal, show: false }) }}
                    onSubmit={handleSubmit}
                />
            </div>
            <div className="card-footer d-flex justify-content-between">
                <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
                <Paginate
                    pageCount={dataPaginate?.meta?.last_page || 0}
                    onPageChange={handlePageChange}
                    forcePage={(search.page || 1) - 1}
                />
            </div>
            <ConfigModal
                {...configModal}
                header={header}
                hideCols={hideCols}
                handleChangeVisCols={handleChangeVisCols}
                onClose={() => { setConfigModal({ ...configModal, show: false }) }}
            />
        </div>
    </>
}
export default PaymentVoucher;