/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { SupplierModel } from '../../models/SupplierModel'

type Props = {
    show: boolean
    mode: string
    data?: SupplierModel
    onClose: () => void
    onSubmit: (values: any, actions: any) => void
}
const addSchema = Yup.object().shape({
    name: Yup.string().required().max(255).label('Tên nhà cung cấp'),
    phone: Yup.string().required().label('Số điện thoại nhà cung cấp'),
    address: Yup.string().required().label('Địa chỉ nhà cung cấp')
})

const SupplierCreateModal: React.FC<Props> = ({ show, mode, data, onClose, onSubmit }) => {
    let initValues: any = {
        name: '',
        phone: '',
        address: ''
    };
    return (
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header className='d-flex'>
                <Modal.Title>Tạo mới nhà cung cấp</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-10">
                <Formik validationSchema={addSchema} initialValues={initValues} onSubmit={onSubmit}>
                    {({ values, handleChange }) => (
                        <Form>
                            <div className="d-flex flex-column  gap-5 pt-3">
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Tên nhà cung cấp</label>
                                        <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='name'
                                    />
                                    <ErrorMessage className='text-danger' component='span' name='name' />
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Phone</label>
                                        <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='phone'
                                    />
                                    <ErrorMessage className='text-danger' component='span'  name='phone' />
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Địa chỉ nhà cung cấp</label>
                                        <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='address'
                                    />
                                    <ErrorMessage className='text-danger' component='span' name='address' />
                                </div>
                            </div>
                            <div className='d-flex mb-4 justify-content-between pt-8'>
                                <button className="btn btn-bg-secondary" type="button" onClick={() => onClose()}>Hủy</button>
                                <button className="btn btn-bg-success" type="submit">Thêm</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default SupplierCreateModal
