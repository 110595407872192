import {FC, useEffect, ChangeEvent, useState} from 'react'
import {useIntl} from 'react-intl'
import {PaginateModel} from '../../models/PaginateModel'
import {DATA_PAGINATION_LENGTH} from '../../../_metronic/partials/pagination/PaginationLength'
import {Toast} from '../../modules/common/helper'
import * as RoleCRUD from './components/role/RoleCRUD'
import * as AccountCRUD from './components/user/AccountCRUD'
import {FilterList} from './components/role/FilterList'
import RolesTable from './components/role/RolesTable'
import Pagination from './components/role/Pagination'
import AccountsTableHeader from './components/role/RolesTableHeader'
import {RolePermissionModel, TSearch} from '../../modules/auth/models/RoleModels'
import {ModalProvider} from './components/ModalContext'
import {usePermissionChecker, UserLoggedIn} from '../../hooks/PermissionChecker'

const GrantAccess: FC = () => {
  const intl = useIntl()
  const [dataPaginate, setDataPaginate] = useState<PaginateModel<RolePermissionModel>>()
  const [modalVisible, setModalVisible] = useState(false)
  const [reloadData, setReloadData] = useState(false)
  const [selectedId, setSelectedId] = useState<number>()
  const {hasPermission} = usePermissionChecker()

  const intItSearch = {
    page: 1,
    per_page: DATA_PAGINATION_LENGTH[0],
  }
  const [search, setSearch] = useState<TSearch>(intItSearch)

  useEffect(() => {
    getData()
    setReloadData(false)
  }, [reloadData])

  const getData = async (params?: TSearch) => {
    try {
      const {data: dataPaginate} = await RoleCRUD.getList(params)
      setDataPaginate(dataPaginate)
      setSearch(params ?? {})
    } finally {
      setReloadData(false)
    }
  }

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target

    setSearch({...search, [name]: value})
  }
  const handlePageChange = (data: any) => {
    getData({...search, page: data.selected + 1})
  }

  const handlePageLengthChange = (e: any) => {
    getData({...search, page: 1, per_page: +e.target.value})
  }
  const handleSearch = () => {
    getData({...search})
  }

  const handleClearSearch = () => {
    setSearch(intItSearch)
    getData()
  }
  const handleDelete = async (id: number) => {
    try {
      await RoleCRUD.destroy(id)
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage({id: 'COMMON.SUCCESS_NOTY'}),
      })
    } finally {
      getData({...search, page: 1})
    }
  }

  const toggleModal = (id?: number) => {
    setModalVisible(!modalVisible)
    setSelectedId(id)
  }

  const handleReloadData = () => {
    setReloadData(true)
  }

  return (
    <ModalProvider>
      <div className='card'>
        <div className='card-header py-4'>
          <div className='col-md-12'>
            <FilterList
              search={search}
              handleChangeSearch={handleChangeSearch}
              handleSearch={handleSearch}
              handleClearSearch={handleClearSearch}
            />
          </div>
        </div>
        <div className='card-body'>
          <AccountsTableHeader
            meta={dataPaginate?.meta}
            hasPermission={hasPermission}
            toggleModal={toggleModal}
          ></AccountsTableHeader>
          <RolesTable
            data={dataPaginate?.data ?? []}
            handleDelete={handleDelete}
            modalVisible={modalVisible}
            toggleModal={toggleModal}
            selectedId={selectedId}
            reloadData={reloadData}
            handleReloadData={handleReloadData}
          />
        </div>
        <div className='card-footer d-flex justify-content-between'>
          <Pagination
            search={search}
            handlePageLengthChange={handlePageLengthChange}
            handlePageChange={handlePageChange}
            dataPaginate={dataPaginate}
          />
        </div>
      </div>
    </ModalProvider>
  )
}
export default GrantAccess
