import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from "react-intl";
import Abilities from './Abilities';

const AbilitiesWrapper: FC = () => {

  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({id: 'MENU.ABILITIES'})}</PageTitle>
      <Abilities />
    </>
  )
}

export default AbilitiesWrapper
