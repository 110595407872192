import axios from "axios";
import {IOrderDetail, ISearchDetail} from "../../../models/OrderModel";
import {ResponseModel} from "../../../models/ResponseModel";
import {ADMIN_ORDER_ENDPOINT, EXPORT_QRCODE_ENDPOINT} from "../../../constants/endpoints";
import { PaginateModel } from "../../../models/PaginateModel";

export function getDetail(params: ISearchDetail, mergeDate: any) {
  let id = params.id;
  const index = id?.indexOf("?");
  if (index && index > -1) {
    id = id?.substring(0, index);
  }
  if (mergeDate?.startDate && mergeDate?.endDate) {
    return axios.get<ResponseModel>(`${ADMIN_ORDER_ENDPOINT}/${params.id}?startDate=${mergeDate?.startDate}&endDate=${mergeDate?.endDate}`)
  } else {
    return axios.get<ResponseModel>(`${ADMIN_ORDER_ENDPOINT}/${params.id}`)
  }
}

export function searchProduct(params: ISearchDetail) {
  return axios.get<PaginateModel<IOrderDetail>>(`${ADMIN_ORDER_ENDPOINT}/find-inventory`, {params})
}

export function exportSearchProduct(params: ISearchDetail) {
  return axios.get(`${ADMIN_ORDER_ENDPOINT}/export-product-search`, {params, responseType: 'blob'})
}

export function upsertOrder(params: any) {
  return axios.post<ResponseModel>(`${ADMIN_ORDER_ENDPOINT}/upsert`, params)
}

export function exportPdf(params: { 
  id?: string, is_sento?: boolean, agency_id?: number, hideCols?: string[],
  startDate?: string,
  endDate?: string
}) {
  return axios.post(`${ADMIN_ORDER_ENDPOINT}/export-pdf`, params)
}


export function exportQrCode(params: {reqData: {name: string, url: string, nums: number}[]}) {
  return axios.post<Blob>(`${EXPORT_QRCODE_ENDPOINT}`,params, {
    responseType: 'blob'
  })
}