import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import queryString from 'query-string'
import AgencyDebtTable from "./AgencyDebtTable";
import AgencyDebtModal from "./AgencyDebtModal";
import ConfigModal from "./ConfigModal";
import { PaginateModel } from "../../../../models/PaginateModel";
import { IAgent } from "../../../../models/AgentModel";
import { ABILITIES_TYPES, PAYMENT_VOUCHER } from "../../../../constants/common";
import { HeaderModel, ModalModel } from "../../../../models/CommonModel";
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../../../_metronic/partials/pagination/PaginationLength";
import { SearchModel } from "../../../../models/DebtModel";
import * as DebtCRUD from "../../../debt/DebtCRUD";
import { Toast } from "../../../../modules/common/helper";
import { PaginationHeader } from "../../../../../_metronic/partials/pagination/PaginationHeader";
import { formatPrice } from "../../../../selectors";
import { Paginate } from "../../../../../_metronic/partials/pagination/Paginate";

const AgencyDebt: FC = () => {
    const intl = useIntl();
    const history = useHistory();
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<IAgent>>();
    const fp = useRef<any>(null);
    const [hideCols, setHideCols] = useState<string[]>([]);
    const [configModal, setConfigModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });

    const [debtDetailModal, setDebtDetailModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const [totalDebt, setTotalDebt] = useState<number>(0);
    const initSearch = {
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0]
    }
    const [search, setSearch] = useState<SearchModel>(initSearch)

    const parseParams = (params: SearchModel) => {
        if (params.page) {
            params.page = +params.page
        }
        if (params.per_page) {
            params.per_page = +params.per_page
        }
        return params
    }

    useEffect(() => {
		document.title = 'Sento - Công nợ đại lý';
	})

    useEffect(() => {
        const searchHistory = history.location.search;
        const initParams: SearchModel = parseParams(queryString.parse(searchHistory))
        getData({ ...search, ...initParams });
    }, []);

    const getData = async (params?: SearchModel) => {
        const { data: resData } = await DebtCRUD.getAgencyDebt({...params});
        const objQuery = { ...params }
        if (objQuery.page === 1) {
            delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
            delete objQuery.per_page
        }
        history.push({ search: `?${queryString.stringify(objQuery)}` })
        setDataPaginate(resData?.paginate_data);
        setTotalDebt(resData?.total_debt)
        setSearch(params ?? {});
    }

    const handlePageChange = (data: any) => {
        getData({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getData({ ...search, page: 1, per_page: +e.target.value })
    }

    const handleSearch = (e: any) => {
        e.preventDefault();
        getData({ ...search, page: 1 })
    }

    const handleClearSearch = (e: any) => {
        e.preventDefault();
        setSearch(initSearch);
        if (fp.current?.flatpickr) {
            fp.current.flatpickr.clear();
        }
        getData(initSearch)
    }

    const handleViewDetail = (agency: IAgent) => {
        setDebtDetailModal({ ...debtDetailModal, show: true, data: {...debtDetailModal?.data, agency: agency} })
    }

    const header: HeaderModel[] = [
        {
            label: intl.formatMessage({ id: 'AGENCY.NAME' }),
            style: { minWidth: "150px" },
            field: 'name',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'AGENCY.DEBT.DATE' }),
            style: { minWidth: "150px" },
            field: 'ability_max_date',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'AGENCY.DEBT.TOTAL' }),
            style: { minWidth: "250px" },
            field: 'debt',
            sortable: true
        }
    ]

    const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
        const currentSearch = { ...search };
        const sortParams: SearchModel = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
        if (direction === 'none') {
            delete sortParams.sort_by;
            delete sortParams.sort_direction;
        }
        getData({ ...sortParams });
    }

    const handleChangeVisCols = (e: any) => {
        let hiddenCols = [...hideCols];
        if (!e.target?.checked) {
            setHideCols([...hiddenCols, e.target.name])
        } else {
            setHideCols(hiddenCols.filter((item) => item !== e.target.name))
        }
    }

    const onChangeHasDebt = (e: any) => {
        const checked = e.target.checked
        let newSearch = {
            ...initSearch,
            has_debt: checked ? 1 : 0
          }
          getData(newSearch);
    }

    return <div className="card">
            <div className="card-header py-4">
                <div className="col-md-12">
                    <form onSubmit={handleSearch}>
                        <div className="row">
                            <div className="col-md-2">
                                <label className="form-label">Tìm kiếm</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="keyword"
                                    value={search.keyword || ''}
                                    onChange={(e) => setSearch({ ...search, keyword: e.target.value })}
                                    autoComplete='nope'
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <button
                                        className="col-auto btn btn-primary mt-8 me-2"
                                        onClick={handleSearch}
                                    >
                                        <i className="bi bi bi-search fs-4"></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
                                    </button>
                                    <button
                                        className="col-auto btn btn-danger mt-8 me-2"
                                        type="button"
                                        onClick={handleClearSearch}
                                    >
                                        <i className="bi bi-eraser-fill fs-1"></i> Re-search
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-check mt-10">
                                    <input className="form-check-input" type="checkbox" value="" id="has_debt"
                                        name='has_debt'
                                        onChange={onChangeHasDebt}/>
                                    <label htmlFor="has_debt" className="form-check-label mb-2">Hiển thị công nợ</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card-body">
                <div className="row justify-content-between align-items-center mb-3">
                    <div className='col-7 col-md-6'>
                        <PaginationHeader from={dataPaginate?.meta?.from || 0} to={dataPaginate?.meta?.to || 0}
                            total={dataPaginate?.meta?.total || 0} />
                    </div>
                    <div className='col-12 col-md-6 text-end'>
                        <h3>Tổng cộng: <span className="text-bold">{ formatPrice(totalDebt) } VNĐ</span></h3> 
                    </div>
                </div>
                <AgencyDebtTable 
                    data={dataPaginate?.data || []}
                    handleViewDetail={handleViewDetail}
                    openColConfig={() => setConfigModal({ ...configModal, show: true })}
                    header={header}
                    hideCols={hideCols}
                    handleSort={handleSort}
                />
                <AgencyDebtModal
                    {...debtDetailModal}
                    onClose={() => { setDebtDetailModal({ ...debtDetailModal, show: false }) }}
                />
            </div>
            <div className="card-footer d-flex justify-content-between">
                <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
                <Paginate
                    pageCount={dataPaginate?.meta?.last_page || 0}
                    onPageChange={handlePageChange}
                    forcePage={(search.page || 1) - 1}
                />
            </div>
            <ConfigModal
                {...configModal}
                header={header}
                hideCols={hideCols}
                handleChangeVisCols={handleChangeVisCols}
                onClose={() => { setConfigModal({ ...configModal, show: false }) }}
            />
        </div>
}
export default AgencyDebt;