import axios from 'axios'
import {AuthModel} from '../../../models/AuthModel'
import {UserModel} from '../models/UserModel'
import {
  GET_USER_BY_ACCESS_TOKEN_URL,
  LOGIN_URL,
  LOGOUT_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL
} from "../../../constants/endpoints";


// Server should return AuthModel
export function login(user_name: string, password: string) {
  return axios.post(LOGIN_URL, {user_name, password})
}

export function logout() {
  return axios.post(LOGOUT_URL)
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESS_TOKEN_URL)
}
