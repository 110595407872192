import {
  ADMIN_STORES_ENDPOINT
} from "../../constants/endpoints";
import axios from "axios";
import {ResponseModel} from "../../models/ResponseModel";
import { PaginateModel } from '../../models/PaginateModel'
import {IStoreModel, TSearch} from "../../models/StoreModel";

export function getList(params?: TSearch) {
  return axios.get<PaginateModel<IStoreModel>>(ADMIN_STORES_ENDPOINT, {params});
}

export function create(params: object) {
  return axios.post<ResponseModel>(ADMIN_STORES_ENDPOINT, params);
}

export function getDetail(id?: number) {
  return axios.get<ResponseModel>(`${ADMIN_STORES_ENDPOINT}/${id}`);
}

export function getStore(params: any) {
  return axios.get<ResponseModel>(`${ADMIN_STORES_ENDPOINT}`, {params})
}

export function update(params: object, id?: number) {
  return axios.put(`${ADMIN_STORES_ENDPOINT}/${id}`, params);
}

export function destroy(id: number) {
  return axios.delete<ResponseModel>(`${ADMIN_STORES_ENDPOINT}/${id}`);
}

