import React, {FC, useEffect, useMemo, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {Field, Form, Formik} from "formik";
import * as OrderDetailCRUD from "./OrderDetailCRUD";
import {map, chain, includes, without, remove} from "lodash";
import {Dictionary} from "@reduxjs/toolkit";
import * as AgentCRUD from "../../Agency/AgentCRUD";
import ProductTable from "./ProductTable";
import * as Yup from "yup";
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../../../_metronic/partials/pagination/PaginationLength";
import { IOrder, IOrderDetail, IOrderProduct, ISearch, ISearchProduct, OrderDetailModel, OrderModel } from "../../../../models/OrderModel";
import { PaginateModel } from "../../../../models/PaginateModel";
import { ReceiptDetailModel } from "../../../../models/ReceiptDetailModel";
import { usePermissionChecker } from "../../../../hooks/PermissionChecker";
import { formatPrice, getCostPriceSento, getMarketPrice, getPriceDetail } from "../../../../selectors";
import { APPROVED_STT } from "../../../../constants/common";
import { Toast } from "../../../../modules/common/helper";
import { PaginationHeader } from "../../../../../_metronic/partials/pagination/PaginationHeader";
import { Paginate } from "../../../../../_metronic/partials/pagination/Paginate";
import OrderDetailTable from "./OrderDetailTable";

const OrderDetail: FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const schema = Yup.object().shape({});
  const {id} = useParams<{ id?: string }>();

  const initSearch = {
    page: 1,
    per_page: DATA_PAGINATION_LENGTH[0],
  }

  const [data, setData] = useState<OrderModel>()
  const [products, setProducts] = useState<OrderDetailModel[]>([])
  const [search, setSearch] = useState<ISearchProduct>(initSearch)
  const [arrDelete, setArrDelete] = useState<number[]>([])
  const [hideCols, setHideCols] = useState<string[]>([]);

  useEffect(() => {
		document.title = 'Sento - Chi tiết đơn hàng';
	})

  useEffect(() => {
    getOrderDetail();
  }, [])

  const getListProducts = async (params: ISearchProduct, orderDetails: OrderDetailModel[] = [], init = false) => {
    const details = orderDetails?.length ? orderDetails : data?.details;
    // Empty product detail
    if (!details?.length) {
      return;
    }
    setProducts(details);
    setSearch(params);
  }


  const getOrderDetail = async () => {
    try {
      const {data} = await OrderDetailCRUD.getDetail({id})
      const details = data.data?.details ?? []
      if (details?.length) {
        const invSentoIds = map(details, 'inventory_of_sento_id');
        getListProducts({inventory_of_sento_id: invSentoIds}, details, true)
      } 
      setData(data.data)
    } catch (error: any) {
      history.push('/my-orders')
    }
  }
  const onSubmit = async (values: any) => {
    let params: any = {
      id,
      note: values?.note ?? '',
      products: products
    }

    const {data} = await OrderDetailCRUD.updateOrder({...params});
    Toast.fire({
      icon: 'success',
      title: data?.message || intl.formatMessage({id: 'COMMON.SUCCESS_NOTY'}),
    })
    getOrderDetail();
  }

  const handleCheckbox = (id: number) => {
    const ids = includes(arrDelete, id) ? without(arrDelete, id) : [...arrDelete, id];
    setArrDelete([...ids]);
  }

  const handleCheckAll = (e: any) => {
    const checked = e.target.checked;
    let ids: number[] = [];
    if (checked) {
      ids = map(products, 'id');
    }
    setArrDelete([...ids]);
  }

  const handleDelete = async () => {
    try {
      const res = await OrderDetailCRUD.removeOrderDetail(arrDelete);
      Toast.fire({
        icon: 'success',
        title: res?.data?.message || intl.formatMessage({id: 'COMMON.SUCCESS_NOTY'}),
      })
      setArrDelete([]);
    } finally {
      getOrderDetail()
    }
  }

  const openNewTabWithHtml = (htmlString: any) => {
    let newTab = window.open();
    if (newTab !== null) {
      newTab.document.write(htmlString);
      newTab.document.close();
    } else {
      console.error('Failed to open new tab.');
    }
  }
  const handleExportPdf = async (isSento: boolean = false) => {
    const {data: resData} = await OrderDetailCRUD.exportPdf({id, is_sento: isSento, agency_id: data?.agency?.id, hideCols})
    openNewTabWithHtml(resData);
  }

  const showDelete = useMemo(() => !!arrDelete.length, [arrDelete])

  const priceDetail = useMemo(() => {
    const arrKey = Object.keys(products)
    let totalTax = 0;
    let totalPrice = 0;
    if (products.length) {
      products.forEach((el: OrderDetailModel) => {
        const quantity = el.new_quantity ?? el.quantity;
        const vat = el?.vat ?? 0;
        totalPrice += quantity* el.unit_price;
        totalTax += (el.unit_price / 100) * vat * quantity;
      })
    }
    return {
      price: formatPrice(totalPrice),
      tax: formatPrice(totalTax),
      taxInc: formatPrice(totalPrice + totalTax)
    };
  }, [products])
  const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
    const currentSearch = {...search};
    const sortParams: ISearch = {...currentSearch, sort_by: sortBy, sort_direction: direction};
    if (direction === 'none') {
      delete sortParams.sort_by;
      delete sortParams.sort_direction;
    }
    getListProducts({...sortParams});
  }
  return <>
    <Formik enableReinitialize validationSchema={schema} initialValues={{...data}} onSubmit={onSubmit}>
      {({values}) => {
        const isApproved = values.status === APPROVED_STT;
        const canEdit = !isApproved
        return (
          <Form>
            <div className="card">
              <div className="card-header py-5">
                <div className="col-12">
                  <div className="row">
                    <label
                      className="col-3 col-md-2 col-form-label mb-2">{intl.formatMessage({id: 'ORDER.CODE'})}:</label>
                    <p className="col-3 col-md-4 mb-2 col-form-label mb-2"><strong>{data?.order_code || ''}</strong></p>

                    <label
                      className="col-3 col-md-2 col-form-label mb-2">{intl.formatMessage({id: 'ORDER.STATUS'})}:</label>
                    <p className="col-3 col-md-4 mb-2 col-form-label mb-2"><strong>{data?.status || ''}</strong></p>

                    <label
                      className="col-3 col-md-2 col-form-label mb-2">{intl.formatMessage({id: 'AGENT.NAME'})}:</label>
                    <p className="col-3 col-md-4 mb-2 col-form-label mb-2"><strong>{data?.agency?.name || ''}</strong>
                    </p>

                    <label
                      className="col-3 col-md-2 col-form-label mb-2">{intl.formatMessage({id: 'COMMON.PHONE'})}:</label>
                    <p className="col-3 col-md-4 mb-2 col-form-label mb-2"><strong>{data?.agency?.phone || ''}</strong>
                    </p>
                    <label htmlFor="note"
                           className="col-12 col-md-2 col-form-label mb-2">{intl.formatMessage({id: 'ORDER.NOTE'})}:</label>
                    <p className="col-12 col-md-10 mb-2 col-form-label mb-2">
                      {canEdit ? (
                        <Field
                          as='textarea'
                          className="form-control"
                          id="note"
                          name="note"
                          value={values?.note || ''}
                        />
                      ) : (
                        <strong>
                        { values?.note || ''}
                        </strong>
                      )}
                    </p>
                    <div className="col-12 mt-2">
                      <div className="d-flex justify-content-center">
                        {showDelete && (
                          <button
                            className="btn btn-sm btn-danger me-2"
                            type="button"
                            onClick={handleDelete}
                          >
                            <i className="bi bi bi-trash fs-4"></i> {intl.formatMessage({id: 'COMMON.DELETE'})}
                          </button>
                        )}
                        {(canEdit) &&
                          <button className="btn btn-success me-3"
                                  type="submit">{intl.formatMessage({id: 'COMMON.SAVE'})}</button>
                        }
                        <button className="btn btn-primary me-3"
                                type="button"
                                onClick={() => handleExportPdf()}>{intl.formatMessage({id: 'ORDER.PRINT'})}</button>
                       
                        <button className="btn btn-secondary" type="button"
                                onClick={history.goBack}>{intl.formatMessage({id: 'COMMON.BACK'})}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row mt-2">
                  <div className="col-3 d-flex">
                    <h4>{intl.formatMessage({id: 'ORDER.MERCHANDISE'})}:</h4>
                    <h3 className="text-primary ps-2">{priceDetail.price}</h3>
                  </div>
                  <div className="col-3 d-flex">
                    <h4>{intl.formatMessage({id: 'ORDER.TAX'})}:</h4>
                    <h3 className="text-primary ps-2">{priceDetail.tax}</h3>
                  </div>
                
                  <div className="col-3 d-flex">
                    <h4>{intl.formatMessage({id: 'ORDER.PRICE'})}:</h4>
                    <h3 className="text-primary ps-2">{priceDetail.taxInc}</h3>
                  </div>
                </div>
                <OrderDetailTable 
                  products={products} 
                  setProducts={setProducts} 
                  canEdit={canEdit} 
                  handleSort={handleSort} 
                  hideCols={hideCols} 
                  setHideCols={setHideCols}
                  arrDelete={arrDelete} 
                  handleCheckAll={handleCheckAll} 
                  handleCheckbox={handleCheckbox}
                  />
              </div>
            </div>

          </Form>
        )
      }}
    </Formik>
  </>

}
export default OrderDetail