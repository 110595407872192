import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from "react-intl";
import SalesReport from './SalesReport';

const SalesReportWrapper: FC = () => {

  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Báo cáo kinh doanh</PageTitle>
      <SalesReport />
    </>
  )
}

export default SalesReportWrapper
