import {ADMIN_AGENT_ENDPOINT, ADMIN_SALES_ENDPOINT} from '../../constants/endpoints'
import {IAgent, ISearch} from '../../models/AgentModel'
import {PaginateModel} from '../../models/PaginateModel'
import axios from 'axios'
import {ResponseModel} from '../../models/ResponseModel'
import { SalesProductModel, SearchModel } from '../../models/SalesProductModel'

export interface PostAssignProductModel {
  sales_id: number,
  product_ids: number[]
}

export function getSales(params?: ISearch) {
  
}

export function assignProducs(params?: PostAssignProductModel) {
  return axios.post<ResponseModel>(`${ADMIN_SALES_ENDPOINT}/assign-products`, params)
}

export function updaeAssignedProducs(params?: { sales_id: number, selected_prods: SalesProductModel[]}) {
  return axios.post<ResponseModel>(`${ADMIN_SALES_ENDPOINT}/update-products`, params)
}

export function cloneSalesProds(params?: { sales_id: number, clone_id: number}) {
  return axios.post<ResponseModel>(`${ADMIN_SALES_ENDPOINT}/clone-products`, params)
}

export function getProducts(params?: ISearch) {
  
}

export function deleteSelected(products: SalesProductModel[], salesId: number) {
  return axios.post<ResponseModel>(`${ADMIN_SALES_ENDPOINT}/remove-products`, {products: products, sales_id: salesId})
}
export function exportCSV(params: ISearch) {
  return axios.get(`${ADMIN_AGENT_ENDPOINT}/export`, {params, responseType: 'blob'})
}

export function getListProducts(params: SearchModel) {
  return axios.get(`${ADMIN_SALES_ENDPOINT}/products`, {params})
}

export function getAssigned(params: SearchModel) {
  return axios.get(`${ADMIN_SALES_ENDPOINT}/assigned-products`, {params})
}

