import { ADMIN_ROLES_ENDPOINT } from '../../../../constants/endpoints'
import axios from 'axios'
import { ResponseModel } from "../../../../models/ResponseModel"
import { TSearch } from '../../../../modules/auth/models/RoleModels';
import { PaginateModel } from '../../../../models/PaginateModel'
import { RolePermissionModel } from '../../../../modules/auth/models/RoleModels'


export function getList(params?: TSearch) {
    return axios.get<PaginateModel<RolePermissionModel>>(`${ADMIN_ROLES_ENDPOINT}`, { params });
}
export function getDetail(id: number) {
    return axios.get<ResponseModel>(`${ADMIN_ROLES_ENDPOINT}/${id}`);
}
export function create(data: any) {
    return axios.post<ResponseModel>(`${ADMIN_ROLES_ENDPOINT}`, data);
}
export function update(id: number, data: any) {
    return axios.put<ResponseModel>(`${ADMIN_ROLES_ENDPOINT}/${id}`, data);
}
export function destroy(id: number) {
    return axios.delete<ResponseModel>(`${ADMIN_ROLES_ENDPOINT}/${id}`);
}