import axios from "axios";
import { ADMIN_ORDER_ENDPOINT, ADMIN_SALES_ENDPOINT } from "../../../../constants/endpoints";
import { ResponseModel } from "../../../../models/ResponseModel";
import { ISearchDetail } from "../../../../models/OrderModel";

export function getDetail(params: ISearchDetail) {
  return axios.get<ResponseModel>(`${ADMIN_SALES_ENDPOINT}/order/${params.id}`)
}

export function upsertOrder(params: any) {
  return axios.post<ResponseModel>(`${ADMIN_SALES_ENDPOINT}/order/upsert`, params)
}

export function createOrder(params: any) {
  return axios.post<ResponseModel>(`${ADMIN_SALES_ENDPOINT}/order/create`, params)
}
export function updateOrder(params: any) {
  return axios.post<ResponseModel>(`${ADMIN_SALES_ENDPOINT}/order/update`, params)
}

export function exportPdf(params: { id?: string, is_sento?: boolean, agency_id?: number, hideCols?: string[] }) {
  return axios.post(`${ADMIN_ORDER_ENDPOINT}/export-pdf`, params)
}

export function removeOrderDetail(ids?: number[]) {
  return axios.post<ResponseModel>(`${ADMIN_SALES_ENDPOINT}/order/remove-details`, {ids: ids})
}