import React, { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  IOrderDetail,
  ISearchProductModel,
} from "../../../models/OrderModel";
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../../_metronic/partials/pagination/PaginationLength";
import { PaginateModel } from "../../../models/PaginateModel";
import { Paginate } from "../../../../_metronic/partials/pagination/Paginate";
import { Sento, usePermissionChecker, UserLoggedIn } from "../../../hooks/PermissionChecker";
import * as OrderDetailCRUD from "../detail/OrderDetailCRUD";
import OrderDetailTable from "./OrderDetailTable";
import { HeaderModel, ModalModel } from "../../../models/CommonModel";
import ConfigModal from "../../abilities/ConfigModal";
import AsyncSelectAgent from "../AsyncSelectAgent";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { IStore } from "../../../models/StoreModel";
import * as StoreCRUD from "../../store/StoreCRUD";
import { download } from "../../../modules/common/helper";
import {Vietnamese} from "flatpickr/dist/l10n/vn.js"

const DetailSearch: FC = () => {
  const intl = useIntl();
  const initRange: any = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
 
  const initSearch = {
    page: 1,
    per_page: DATA_PAGINATION_LENGTH[0],
    date: {startDate: initRange[0], endDate: initRange[1]},
    store_id: UserLoggedIn().store_id
  }
  const [configModal, setConfigModal] = useState<ModalModel>({
    show: false,
    mode: 'add',
  });
  const [dataPaginate, setDataPaginate] = useState<PaginateModel<IOrderDetail>>()
  const [search, setSearch] = useState<ISearchProductModel>(initSearch)
  const [hideCols, setHideCols] = useState<string[]>([]);
  const { hasPermission } = usePermissionChecker();
  const [paramsExport, setParamsExport] = useState<ISearchProductModel>({})
  const [stores, setStores] = useState<IStore[]>()
  const sento = Sento();


  useEffect(() => {
    document.title = 'Sento - Tìm kiếm sản phẩm theo đơn hàng';
  })

  useEffect(() => {
    getData(initSearch);
    getListStore();
  }, [])

  const getListStore = async () => {
    const {data} = await StoreCRUD.getStore({});
    setStores(data.data);
  }
  const getData = async (params: ISearchProductModel) => {

    const { data: dataPaginate } = await OrderDetailCRUD.searchProduct(params);
    const objQuery = { ...params }
    if (objQuery.page === 1) {
      delete objQuery.page
    }
    if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
      delete objQuery.per_page
    }
    setDataPaginate(dataPaginate);
    setSearch(params ?? {});
    setParamsExport({ ...params })
  }


  const handlePageLengthChange = (e: any) => {
    getData({ ...search, page: 1, per_page: +e.target.value })
  }

  const handlePageChange = (data: any) => {
    getData({ ...search, page: data.selected + 1 })
  }

  const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
    const currentSearch = { ...search };
    const sortParams: ISearchProductModel = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
    if (direction === 'none') {
      delete sortParams.sort_by;
      delete sortParams.sort_direction;
    }
    getData({ ...sortParams });
  }
  const header: HeaderModel[] = [
    {
      label: 'Tên sản phẩm',
      style: {},
      field: 'inventory_name',
      sortable: true
    },
    {
      label: 'Tên đại lý',
      style: { minWidth: "150px" },
      field: 'agency_name',
      sortable: true
    },
    {
      label: 'SL.HĐ',
      style: { minWidth: "50px" },
      field: 'quantity',
      sortable: true
    },
    {
      label: 'Giá vốn',
      style: { minWidth: "80px" },
      field: 'capital_price',
      sortable: true
    },
    {
      label: 'Giá bán',
      style: { minWidth: "80px" },
      field: 'unit_price',
      sortable: true
    },
    {
      label: 'Giá thị trường',
      style: { minWidth: "80px" },
      field: 'market_price',
      sortable: true
    },
    {
      label: 'Chiết khấu',
      style: { minWidth: "50px" },
      field: 'discount',
      sortable: true
    },
    {
      label: 'Mã HĐ',
      style: { minWidth: "100px" },
      field: 'order_code',
      sortable: true
    },
    {
      label: 'Ngày HĐ',
      style: { minWidth: "120px" },
      field: 'created_at',
      sortable: true
    }
  ];
  const fp = useRef<any>(null);
  const agentRef = useRef<any>(null);
  const handleChangeVisCols = (e: any) => {
    let hiddenCols = [...hideCols];
    if (!e.target?.checked) {
      setHideCols([...hiddenCols, e.target.name])
    } else {
      setHideCols(hiddenCols.filter((item) => item !== e.target.name))
    }
  }
  const handleSearch = (e: any) => {
      e.preventDefault();
      getData({ ...search, page: 1 })
  }
  const handleClearSearch = (e: any) => {
    e.preventDefault();
    agentRef?.current?.clearValue()
    setSearch({
      ...initSearch
    });
    if (fp.current?.flatpickr) {
      fp.current.flatpickr.clear();
    }
    getData({...initSearch})
  }
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
        handleSearch(e);
    }
  }
  const handleExport = async () => {
    const {data} = await OrderDetailCRUD.exportSearchProduct({...paramsExport});
    download(data, 'search_product');
  }
  return <>
    <div className='card'>
      <div className="card-header py-4">
        <div className="col-md-12">
          <form onSubmit={handleSearch}>
            <div className="row">
              <div className="col-md-3">
                <label className="form-label">Đại lý</label>
                <AsyncSelectAgent
                  agentsAppend={[]}
                  name="agency_id"
                  handleChange={(e:any) => setSearch({ ...search, agency_id: e.target.value })} 
                  forwardRef={agentRef}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Tên sản phẩm</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  name="name"
                  value={search.name || ''}
                  onChange={(e) => setSearch({ ...search, name: e.target.value })}
                  autoComplete='nope'
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </div>
              <div className='col-md-3 mb-2'>
                <label className="form-label">Ngày</label>
                <Flatpickr
                  ref={fp}
                  onChange={(date) => {
                    let startDate = moment(date[0]).format('YYYY-MM-DD');
                    let endDate = moment(date[1]).format('YYYY-MM-DD');
                    setSearch({...search, date: {startDate, endDate}});
                  }}
                  options={{
                    mode: "range",
                    dateFormat: "d-m-Y",
                    locale: Vietnamese,
                    shorthandCurrentMonth: true,
                    defaultDate: [moment().startOf('month').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
                  }}
                  className='form-control'
                  placeholder=''
                />
              </div>
              <div className="col-md-4">
                <div className="row">
                  <button
                    className="col-auto btn btn-primary mt-8 me-2"
                    onClick={handleSearch}
                  >
                    <i className="bi bi bi-search fs-4"></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
                  </button>
                  <button
                    className="col-auto btn btn-danger mt-8 me-2"
                    type="button"
                    onClick={handleClearSearch}
                  >
                    <i className="bi bi-eraser-fill fs-1"></i> Re-search
                  </button>
                </div>

              </div>
              {sento &&
              <div className="col-md-3 mb-2">
                <label className="form-label">{intl.formatMessage({id: 'ORDER.STORE'})}</label>
                <select className="form-select" value={search.store_id} key={`store_id_${search.store_id}`}
                        onChange={(e) => setSearch({...search, store_id: e.target.value})}
                        name='store_id'>
                  <option value="">Chọn cửa hàng</option>
                  {stores?.map(store => <option value={store.id}>{store.name}</option>)}
                </select>
              </div>
            }
              <div className="col-md-2 text-end">
                <button className="btn btn-icon mt-8 me-2 btn-light-info me-5 text-hover-white" type="button"
                  onClick={handleExport}>
                  <i className="bi bi bi-cloud-arrow-down-fill fs-1 text-info"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='card-body'>
        <OrderDetailTable
          data={dataPaginate?.data || []}
          openColConfig={() => setConfigModal({ ...configModal, show: true })}
          header={header}
          hideCols={hideCols}
          handleSort={handleSort}
        />
      </div>
      <div className='card-footer d-flex justify-content-between'>
        <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
        <Paginate
            pageCount={dataPaginate?.meta?.last_page || 0}
            onPageChange={handlePageChange}
            forcePage={(search.page || 1) - 1}
        />
      </div>
      <ConfigModal
        {...configModal}
        header={header}
        hideCols={hideCols}
        handleChangeVisCols={handleChangeVisCols}
        onClose={() => { setConfigModal({ ...configModal, show: false }) }}
      />
    </div>
  </>

}
export default DetailSearch