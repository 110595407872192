import { FC, useState} from "react"
import { Button, Modal } from "react-bootstrap-v5"
import { useIntl } from "react-intl"
import { AsyncSelect2 } from "../../../_metronic/partials/select/AsyncSelect2"
import { GET_SALES_OPTIONS } from "../../constants/endpoints"

interface SelectStaffDuplicateModalProps {
    show: boolean,
    curSaleId?: number,
    onHide: () => void,
    onSubmit: (saleId?: number) => void
}

const SelectStaffDuplicateModal: FC<SelectStaffDuplicateModalProps> = ({
    show,
    onHide,
    onSubmit,
    curSaleId
}) => {
    const inlt = useIntl();
    const [saleId, setSalesId] = useState<number>();

    const handleSubmit = () => {
        onSubmit(saleId)
    }
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Sao chép sản phẩm sang nhân viên</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label className='form-label'>Chọn nhân viên kinh doanh</label>
                <AsyncSelect2
                    isGray
                    sourceUrl={`${GET_SALES_OPTIONS}`}
                    defaultOptions
                    default
                    excludeId={curSaleId}
                    className="mt-8"
                    onChange={(selectedOption: any) => {
                        setSalesId(selectedOption.value);
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {inlt.formatMessage({ id: 'COMMON.CLOSE' })}
                </Button>
                <Button variant="success" onClick={handleSubmit}>
                    {inlt.formatMessage({ id: 'COMMON.SAVE' })}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SelectStaffDuplicateModal;