/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { AbilityModel } from '../../../models/AbilityModel'
import { GET_AGENCY_OPTIONS, GET_SUPPLIER_OPTIONS } from '../../../constants/endpoints'
import { formatPrice, revertPriceNumner } from '../../../selectors'
import { useIntl } from 'react-intl'
import { ABILITIES_PAYMENT_METHOD } from '../../../constants/common'
import { AsyncSelect2Supplier } from '../../../../_metronic/partials/select/AsyncSelect2Supplier'
import { NumericFormat } from 'react-number-format'

type Props = {
    show: boolean
    mode: string
    data?: AbilityModel
    onClose: () => void
    onSubmit: (values: any, actions: any) => void
}
const addSchema = Yup.object().shape({
    name: Yup.string().required('Vui lòng chọn nhập tên'),
    supplier_id: Yup.string().nullable().required('Vui lòng chọn nhà cung cấp'),
    value: Yup.string().required('Vui lòng nhập giá trị')
})

const PaymentVoucher: React.FC<Props> = ({ show, mode, data, onClose, onSubmit }) => {
    const intl = useIntl();
    const supplier = data?.supplier
    let initValues: any = {
        supplier: {label: supplier?.name, value: supplier?.id},
        supplier_id: supplier?.id ?? null,
        name: supplier?.name ?? '',
        address: supplier?.address ?? '',
        dept: (supplier?.receipt_detail_sum_sub_total ?? 0) - (supplier?.ability_sum_value ?? 0)
    };
    
    return (
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header className='d-flex'>
                <Modal.Title>Tạo phiếu chi</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-10">
                <Formik validationSchema={addSchema} initialValues={initValues} onSubmit={onSubmit}>
                    {({ values, handleChange }) => (
                        <Form>
                            <div className="d-flex flex-column  gap-5 pt-3">
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Tên người chi</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='name'
                                        required
                                    />
                                    <ErrorMessage className='text-danger' component='span' name='name' />
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Nhà cung cấp</label>
                                    <AsyncSelect2Supplier
                                        name="supplier"
                                        placeholder=""
                                        isGray
                                        sourceUrl={GET_SUPPLIER_OPTIONS}
                                        defaultOptions
                                        defaultValue={initValues?.supplier ?? null}
                                        default
                                        required
                                        onChange={(selectedOption: any) => {
                                            handleChange({target: {name: 'supplier', value: selectedOption}});
                                            handleChange({target: {name: 'supplier_id', value: selectedOption.value}});
                                            handleChange({target: {name: 'dept', value: selectedOption.dept}});
                                            handleChange({target: {name: 'address', value: selectedOption.address}});
                                        }}
                                    />
                                    <ErrorMessage className='text-danger' component='span' name='supplier_id' />
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Địa chỉ</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='address'
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='address' /></div>
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Công nợ cũ</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        name='dept'
                                        value={formatPrice(values.dept)}
                                        readOnly
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='address' /></div>
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="required form-label">Số tiền chi</label>
                                    <NumericFormat
                                        thousandSeparator=","
                                        name='value'
                                        required
                                        className='form-control form-control-sm'
                                        onBlur={(e) => {
                                            handleChange({target: {name: 'value', value: revertPriceNumner(e.target.value)}});
                                            handleChange({target: {name: 'new_dept', value: values.dept - revertPriceNumner(e.target.value)}});
                                        }}
                                    />
                                    
                                    <ErrorMessage className='text-danger' component='span' name='value' />
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Công nợ còn lại</label>
                                    <Field
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        autoComplete='nope'
                                        value={formatPrice(values.new_dept)}
                                        name='new_dept'
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='value' /></div>
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Phương thức thanh toán</label>
                                    <Field
                                            as='select'
                                            name='payment_method'
                                            className='form-select'
                                    >
                                        <option value=""></option>
                                        {ABILITIES_PAYMENT_METHOD.map((method: any) => (
                                            <option key={method.id} value={method.id}>{method.label}</option>
                                        ))}
                                    </Field>
                                </div>
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                    <label className="form-label">Ghi chú</label>
                                    <Field
                                        as="textarea"
                                        className="form-control"
                                        value={values.note ?? ""}
                                        id="note"
                                        name="note"
                                        rows={3}
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"><ErrorMessage name='note' /></div>
                                </div>
                            </div>
                            <div className='d-flex mb-4 justify-content-between pt-8'>
                                <button className="btn btn-bg-secondary" type="button" onClick={() => onClose()}>Hủy</button>
                                <button className="btn btn-bg-success" type="submit">Thêm</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default PaymentVoucher
