import React, {FC} from 'react'
import {useIntl} from "react-intl";
import {PageTitle} from "../../../../_metronic/layout/core";
import DetailSearch from './DetailSearch';

const DetailSearchWrapper: FC = () => {

  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Tìm kiếm sản phẩm</PageTitle>
      <DetailSearch />
    </>
  )
}

export default DetailSearchWrapper
