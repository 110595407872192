import React, {FC, useEffect, useState} from "react";
import AgentBasic from "./AgentBasic";
import AgentInfo from "./AgentInfo";
import AgentOther from "./AgentOther";
import {useHistory, useParams} from "react-router-dom";
import * as AgentDetailCRUD from "./AgentDetailCRUD";
import {useIntl} from "react-intl";
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import { IAgent } from "../../../models/AgentModel";
import { Toast } from "../../../modules/common/helper";


const AgentDetail: FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const [data, setData] = useState<IAgent>()
  const {id} = useParams<{ id?: string }>();

  useEffect(() => {
		document.title = 'Sento - Cập nhật đại lý';
	})

  useEffect(() => {
    if (id) {
      getAgentDetail();
    }
  }, [])

  const getAgentDetail = async () => {
    const {data} = await AgentDetailCRUD.getAgentDetail(id)
    setData({...data?.data})
  }

  const currentSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({id: 'COMMON.REQUIRED'}, {name: 'tên'})),
    phone: Yup.string().required(intl.formatMessage({id: 'COMMON.REQUIRED'}, {name: 'số điện thoại'})),
    address: Yup.string().required(intl.formatMessage({id: 'COMMON.REQUIRED'}, {name: 'địa chỉ'})),

  });

  const onSubmit = async (params: any) => {
    const {data} = await AgentDetailCRUD.upsertAgent({...params});
    Toast.fire({
      icon: 'success',
      title: data?.message || intl.formatMessage({id: 'COMMON.SUCCESS_NOTY'}),
    })
    if (!params['id']) {
      history.push('/agents')
    }
  }
  return <>
    <Formik enableReinitialize validationSchema={currentSchema} initialValues={{...data}} onSubmit={onSubmit}>
      <Form>
        <AgentBasic/>
        <AgentInfo/>
        <div className="card mt-4 py-5">
          <div className="d-flex justify-content-center">
              <button className="btn btn-success me-3"
                      type="submit">{intl.formatMessage({id: 'COMMON.SAVE'})}</button>
            <button className="btn btn-secondary" type="button"
                    onClick={history.goBack}>{intl.formatMessage({id: 'COMMON.BACK'})}</button>
          </div>
        </div>
      </Form>
    </Formik>
  </>
}
export default AgentDetail