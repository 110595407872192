import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PaginateModel } from "../../models/PaginateModel";
import * as DebtCRUD from './DebtCRUD';
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../_metronic/partials/pagination/PaginationLength";
import { Paginate } from "../../../_metronic/partials/pagination/Paginate";
import {  useHistory } from "react-router-dom";
import {  download } from "../../modules/common/helper";
import queryString from 'query-string'
import { UserModel } from "../../modules/auth/models/UserModel";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { formatPrice } from "../../selectors";
import { ModalModel, SearchModel, AbilityModel } from "../../models/AbilityModel";
import SalesReportTable from "./SalesReportTable";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import {Sento, UserLoggedIn} from "../../hooks/PermissionChecker";
import { SalesReportItemModel } from "../../models/OrderModel";
import { HeaderModel } from "../../models/CommonModel";
import ConfigModal from "./ConfigModal";
import {Vietnamese} from "flatpickr/dist/l10n/vn.js"
import useMasterData from "../../hooks/useMaster";
import { AsyncSelect2 } from "../../../_metronic/partials/select/AsyncSelect2";
import { GET_SALES_OPTIONS } from "../../constants/endpoints";
export interface ReportModel {
    total_expenses: number
    total_sales: number
}
const SalesReport: FC = () => {
    const intl = useIntl();
    const history = useHistory();
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<SalesReportItemModel>>();
    const salesRef = useRef<any>(null);
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const {master} = useMasterData();
    const sento = Sento();
    const fp = useRef<any>(null);
    const initRange:any = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    const [hideCols, setHideCols] = useState<string[]>([]);
    const [configModal, setConfigModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const initSearch = {
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0],
        date:{startDate: initRange[0], endDate: initRange[1]},
        store_id: UserLoggedIn().store_id
    }
    const [reportData, setReport] = useState<ReportModel>({
        total_expenses: 0,
        total_sales: 0
    });
    const [search, setSearch] = useState<SearchModel>(initSearch)

    const parseParams = (params: SearchModel) => {
        if (params.page) {
            params.page = +params.page
        }
        if (params.per_page) {
            params.per_page = +params.per_page
        }
        return params
    }

    useEffect(() => {
		document.title = 'Sento - Báo cáo doanh thu';
	})

    useEffect(() => {
        const searchHistory = history.location.search;
        const initParams: SearchModel = parseParams(queryString.parse(searchHistory))
        getData({ ...search, ...initParams });
    }, []);

    const getData = async (params?: SearchModel) => {
        const { data: resData } = await DebtCRUD.getSalesReport(params);
        const objQuery = { ...params }
        if (objQuery.page === 1) {
            delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
            delete objQuery.per_page
        }
        history.push({ search: `?${queryString.stringify(objQuery)}` })
        setDataPaginate(resData?.paginate_data);
        setReport(resData?.total_report);
        setSearch(params ?? {});
    }
 
    const handlePageChange = (data: any) => {
        getData({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getData({ ...search, page: 1, per_page: +e.target.value })
    }

    const handleSearch = (e: any) => {
        e.preventDefault();
        getData({ ...search, page: 1 })
    }

    const handleClearSearch = (e: any) => {
        e.preventDefault();
        setSearch(initSearch);
        if (fp.current?.flatpickr) {
            fp.current.flatpickr.clear();
        }
        salesRef?.current?.clearValue()
        getData(initSearch)
    }
    
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleSearch(e);
        }
    }
    
    const header: HeaderModel[] = [
        {
            label: 'Mã đơn hàng',
            style: { minWidth: "150px" },
            field: 'order_code',
            sortable: true
        },
        {
            label: 'Đại lý',
            style: { minWidth: "150px" },
            field: 'agency_name',
            sortable: true
        },
        {
            label: 'Giá vốn',
            style: { minWidth: "150px" },
            field: 'total_expenses',
            sortable: true
        },
        {
            label: 'Doanh thu',
            style: { minWidth: "150px" },
            field: 'total_sales_no_vat',
            sortable: true
        },
        {
            label: 'VAT',
            style: { minWidth: "150px" },
            field: 'total_vat',
            sortable: true
        },
        {
            label: 'Tổng doanh thu',
            style: { minWidth: "150px" },
            field: 'total_sales',
            sortable: true
        },
        {
            label: 'Lợi nhuận trước thuế',
            style: { minWidth: "150px" },
            field: 'profit',
            sortable: true
        },
        {
            label: 'Ngày duyệt',
            style: { minWidth: "150px" },
            field: 'approved_at',
            sortable: true
        }
    ]

    const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
        const currentSearch = { ...search };
        const sortParams: SearchModel = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
        if (direction === 'none') {
            delete sortParams.sort_by;
            delete sortParams.sort_direction;
        }
        getData({ ...sortParams });
    }

    const handleChangeVisCols = (e: any) => {
        let hiddenCols = [...hideCols];
        if (!e.target?.checked) {
            setHideCols([...hiddenCols, e.target.name])
        } else {
            setHideCols(hiddenCols.filter((item) => item !== e.target.name))
        }
    }

    const handleExportReport = async () => {
        const { data } = await DebtCRUD.exportSalesreport({ ...search });
        download(data, 'sales_report');
    }

    return <>
        <div className="card">
            <div className="card-header py-4">
                <div className="col-md-12">
                    <form onSubmit={handleSearch}>
                        <div className="row">
                            <div className="col-md-2">
                                <label className="form-label">{intl.formatMessage({ id: 'COMMON.KEYWORD' })}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="keyword"
                                    value={search.keyword || ''}
                                    onChange={(e) => setSearch({ ...search, keyword: e.target.value })}
                                    autoComplete='nope'
                                    onKeyDown={(e) => handleKeyDown(e)}
                                />
                            </div>
                            <div className='col-md-3 mb-2'>
                                <label className="form-label">{intl.formatMessage({ id: 'COMMON.TIME' })}</label>
                                <Flatpickr
                                    ref={fp}
                                    onChange={(date) => {
                                        let startDate = moment(date[0]).format('YYYY-MM-DD');
                                        let endDate = moment(date[1]).format('YYYY-MM-DD');
                                        setSearch({ ...search, date: { startDate, endDate } });
                                    }}
                                    options={{
                                        mode: "range",
                                        dateFormat: 'd-m-Y',
                                        locale: Vietnamese,
                                        enableTime: false,
                                        defaultDate: [moment().startOf('month').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
                                    }}
                                    className='form-control'
                                    placeholder=''
                                />
                            </div>
                            <div className="col-md-3 mb-2">
                                <label className="form-label">Nhân viên kinh doanh</label>
                                <AsyncSelect2
                                    sourceUrl={`${GET_SALES_OPTIONS}`}
                                    defaultOptions
                                    default
                                    name='sales_id' 
                                    forwardRef={salesRef}
                                    onChange={(selectedOption: any) => {
                                        setSearch({...search, sales_id: selectedOption?.value ?? null})
                                    }}
                                />
                                </div>
                            {sento &&
                                <div className="col-md-3 mb-2">
                                    <label className="form-label">Store</label>
                                    <select
                                        className="form-select"
                                        value={search.store_id}
                                        onChange={(e) => setSearch({ ...search, store_id: e.target.value })}
                                        name="store_id"
                                    >
                                        <option value=''>Tất cả</option>
                                        {master?.stores?.map((store, index) => {
                                            return (
                                                <option value={store.id}>{store.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            }
                            <div className="col-md-4">
                                <div className="row">
                                    <button
                                        className="col-auto btn btn-primary mt-8 me-2"
                                        onClick={handleSearch}
                                    >
                                        <i className="bi bi bi-search fs-4"></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
                                    </button>
                                    <button
                                        className="col-auto btn btn-danger mt-8 me-2"
                                        type="button"
                                        onClick={handleClearSearch}
                                    >
                                        <i className="bi bi-eraser-fill fs-1"></i> Re-search
                                    </button>
                                    <button className="btn btn-icon mt-8 btn-sm me-2 ms-auto btn-light-info text-hover-white" type="button"
                                    onClick={handleExportReport}>
                                        <i className="bi bi-cloud-arrow-down-fill fs-1 text-info" />
                                    </button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card-body">
                <div className="row justify-content-between align-items-center mb-5">
                    <div className='col-12 col-md-6 col-lg-3'>
                        <label className="fw-bolder">Doanh thu</label><br/>
                        <span className="fs-3">{ formatPrice(reportData?.total_sales) } VNĐ</span>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>
                        <label className="fw-bolder">Giá vốn</label><br/>
                        <span className="fs-3">{ formatPrice(reportData?.total_expenses) } VNĐ</span>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>
                        <label className="fw-bolder">Lợi nhuận</label><br/>
                        <span className="fs-3">{ formatPrice(reportData?.total_sales - reportData?.total_expenses ) } VNĐ</span>
                    </div>
                </div>
                <hr/>
                <SalesReportTable
                    data={dataPaginate?.data || []}
                    openColConfig={() => setConfigModal({ ...configModal, show: true })}
                    header={header}
                    hideCols={hideCols}
                    handleSort={handleSort}
                />
            </div>
            <div className="card-footer d-flex justify-content-between">
                <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
                <Paginate
                    pageCount={dataPaginate?.last_page || 0}
                    onPageChange={handlePageChange}
                    forcePage={(search.page || 1) - 1}
                />
            </div>
            <ConfigModal
                {...configModal}
                header={header}
                hideCols={hideCols}
                handleChangeVisCols={handleChangeVisCols}
                onClose={() => { setConfigModal({ ...configModal, show: false }) }}
            />
        </div>
    </>
}
export default SalesReport;