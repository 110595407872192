import React, {FC, useEffect, useState} from "react";
import {CharsOrder} from "./ChartsOrder";
import ChartAbilities from "./ChartAbilities";
import * as StoreCRUD from "../store/StoreCRUD";
import {IStore} from "../../models/StoreModel";
import BlockReport from "./BlockReport";
import {Sento, UserLoggedIn} from "../../hooks/PermissionChecker";
import * as DashboardCRUD from "./DashboardCRUD";
import {ITotalReport} from "../../models/DashboardModel";

const DashboardPage: FC = () => {
  const isSento = Sento();
  const user = UserLoggedIn();
  const initStore = isSento ? null : user.store_id
  const [stores, setStores] = useState<IStore[]>([])
  const [store, setStore] = useState<any>(initStore)
  const [total, setTotal] = useState<ITotalReport>()

  useEffect(() => {
		document.title = 'Sento - Thống kê chung';
	})

  useEffect(() => {
    const getListStore = async () => {
      const promiseStore = StoreCRUD.getStore({});
      const promiseTotal = DashboardCRUD.getTotal({store});
      const res = await Promise.all([promiseStore, promiseTotal]);
      setStores(res[0].data.data);
      setTotal(res[1].data.data);
    }
    getListStore();

  }, []);

  useEffect(() => {
    const getTotal = async () => {
      const {data} = await DashboardCRUD.getTotal({store});
      setTotal(data.data);
    }
    getTotal();

  }, [store]);

  return <>
    <div className='row gy-5 g-xl-8'>
      {isSento &&
        <div className="col-4">
          <select className="form-select form-select-sm"
                  onChange={(e) => setStore(e.target.value)}
                  name='store_id'>
            <option value="">Tất cả store</option>
            {stores?.map(store => <option value={store.id}>{store.name}</option>)}
          </select>
        </div>

      }
      <div className='col-xxl-12'>
        <BlockReport total={total}/>
      </div>
      <div className='col-xxl-12'>
        <ChartAbilities
          store={store}
          className='mb-5'
          colorBase='--bs-primary'
        />
      </div>
      <div className='col-xxl-12'>
        <CharsOrder
          store={store}
          className='mb-5'
          colorBase='--bs-primary'
        />
      </div>
    </div>
  </>
}
export default DashboardPage;