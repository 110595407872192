import {IPermission} from '../auth/models/PermissionsModel'
export interface UserPermission {
  id: number
  name: string
  permissions: IPermission[]
}

export const hasPermission = (
    userPermissions: IPermission[],
    requiredPermission: string
  ): boolean => {
    return userPermissions?.some((p) => p?.name === requiredPermission);
  }
