import React, { FC, useMemo } from 'react';
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { AbilityModel } from '../../models/AbilityModel';
import { PAYMENT_VOUCHER, RECIEPT_VOUCHER } from '../../constants/common';
import { formatPrice } from '../../selectors';
import SortableColumn from '../../components/Table/SortableColumn';
import { HeaderModel } from './Abilities';

type Props = {
    data: Array<AbilityModel>
    header: HeaderModel[],
    handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void;
    openColConfig: () => void
    hideCols: string[]
};

const AbilitiesTable: FC<Props> = ({ data, header, handleSort, openColConfig, hideCols }) => {
    const intl = useIntl();
    const history = useHistory();

    return (
        <div className='table-responsive'>
            <table className="table table-hover table-bordered table-striped border gy-7 gs-7">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th>
                            <i 
                                className="fa fa-cogs"
                                style={{cursor: 'pointer'}}
                                onClick={openColConfig}
                            ></i>
                        </th>
                        {header.map((th, index) => {
                            if (!hideCols.includes(th.field)) {
                                if (th.sortable) {
                                    return (
                                        <SortableColumn
                                            key={'th-'+index}
                                            sortBy={th?.field}
                                            onSortChange={handleSort}
                                            style={th?.style ?? {}}
                                        >
                                            {th.label}
                                        </SortableColumn>
                                    )
                                }
                                return (
                                    <th style={th?.style ?? {}}>{th.label}</th>
                                )
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item: AbilityModel, index: number) => {
                        return (
                            <tr key={`ability_${index}`} className="cursor-pointer">
                                <td></td>
                                {!hideCols.includes('type') && (
                                    <td>{item.supplier_id === null ? RECIEPT_VOUCHER : PAYMENT_VOUCHER}</td>
                                )}
                                {!hideCols.includes('date') && (
                                    <td>{item.date}</td>
                                )}
                                {!hideCols.includes('name') && (
                                    <td>{item.name}</td>
                                )}
                                {!hideCols.includes('value') && (
                                    <td>{formatPrice(item.value)}</td>
                                )}
                                {!hideCols.includes('note') && (
                                    <td>{item.note}</td>
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default AbilitiesTable;