import AsyncSelect from 'react-select/async'
import {FormikValues} from 'formik'
import React, {FC, useEffect, useMemo, useState} from 'react'
import * as StoreCRUD from '../StoreCRUD'
import {debounce} from 'lodash'
import { IStore, ISearchStore } from '../../../../models/StoreModel'
interface IStoreOption {
  label: string
  value: number
}

const initSearchStore = {
  page: 1,
  per_page: 200,
  name: '',
}

type TProps = {
  values?: FormikValues
  handleChange: (params: object) => void
  storeAppend: any
  name: string
  forwardRef?: any
}

const AsyncSelectStore: FC<TProps> = ({values, handleChange, storeAppend, name, forwardRef}) => {
  const [stores, setStores] = useState<IStore[]>()

  useEffect(() => {
    getStores(initSearchStore)
  }, [])

  useEffect(() => {
    if (storeAppend?.label) {
      setStores([...(stores || []), storeAppend])
    }
  }, [storeAppend?.label])

  let storeOptions = useMemo(
    () => stores?.map((store) => ({label: store?.name, value: store?.id})),
    [stores]
  )

  const getStores = async (params: ISearchStore) => {
    const {data: resData} = await StoreCRUD.getList({...params})
    setStores([...(stores || []), ...(resData?.data || [])])
  }

  const fetchOptStore = async (
    inputVal: string,
    callback: (result: IStoreOption[]) => void
  ): Promise<void> => {
    const {data} = await StoreCRUD.getList({name: inputVal})

    callback(
      data.data.reduce(function (result: IStoreOption[], agent) {
        // if (agent?.name?.toLowerCase().includes(inputVal.toLowerCase())) {
        result.push({label: agent.name, value: agent.id})
        // }
        return result
      }, [])
    )
  }
  const _loadSuggestions = (inputVal: string, callback: (result: IStoreOption[]) => void) => {
    fetchOptStore(inputVal, callback)
  }

  const loadSuggestions = debounce(_loadSuggestions, 1000)
  return (
    <AsyncSelect
      className='mb-2'
      isClearable
      cacheOptions
      loadOptions={loadSuggestions}
      defaultOptions={storeOptions}
      defaultValue={storeOptions?.find((option) => option.value === values?.store_id)}
      value={storeOptions?.find((option) => option.value === values?.store_id)}
      onChange={(option) => { handleChange({target: {name: name, value: option?.value}}) }}
      loadingMessage={() => 'Đang tải...'}
      noOptionsMessage={() => 'Không có dữ liệu'}
      id={name}
      name={name}
      placeholder='Chọn cửa hàng được quản lý'
      ref={forwardRef}
    />
  )
}
export default AsyncSelectStore
