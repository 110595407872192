import { IOrder, SalesReportItemModel } from './../../models/OrderModel';
import axios from "axios";
import {PaginateModel,} from "../../models/PaginateModel";
import {ResponseModel} from "../../models/ResponseModel";
import { ADMIN_ABILITIES_ENDPOINT, ADMIN_AGENT_ENDPOINT, ADMIN_ORDER_ENDPOINT, ADMIN_SUPPLIER_ENDPOINT, GET_AGENCY_DEBT } from "../../constants/endpoints";
import { SearchModel } from "../../models/DebtModel";
import { AbilityModel } from "../../models/AbilityModel";
import { ReportModel } from "./SalesReport";
import { SupplierModel } from "../../models/SupplierModel";
import { AbilityListModel } from "../abilities/AbilitiesCRUD";
import { IAgent } from "../../models/AgentModel";

export interface SupplierDebtListModel {
  paginate_data: PaginateModel<SupplierModel>,
  total_debt: number
}

export interface SalesReportModel {
  paginate_data: PaginateModel<SalesReportItemModel>,
  total_report: ReportModel
}

export interface AgencyDebtListModel {
  paginate_data: PaginateModel<IAgent>,
  total_debt: number
}
export function getList(params?: SearchModel) {
  return axios.get<PaginateModel<any>>(`${GET_AGENCY_DEBT}`, {params})
}

export function getAgencyDebt(params?: SearchModel) {
  return axios.get<AgencyDebtListModel>(`${ADMIN_AGENT_ENDPOINT}/debt-report`, {params})
}

export function getSupplierDebt(params?: SearchModel) {
  return axios.get<SupplierDebtListModel>(`${ADMIN_SUPPLIER_ENDPOINT}/debt-report`, {params})
}

export function getAbilityReport(params?: any) {
  return axios.get<ReportModel>(`${ADMIN_ABILITIES_ENDPOINT}/ability-report`, {params})
}

export function store(data: AbilityModel) {
    return axios.post<ResponseModel>(`${ADMIN_ABILITIES_ENDPOINT}`, data)
}

export function exportPdf(params: { id?: number, type: string }) {
  return axios.post(`${ADMIN_ABILITIES_ENDPOINT}/export-pdf`, params)
}

export function getDetailsById(id: number) {
  return axios.get<AbilityListModel>(`${ADMIN_ABILITIES_ENDPOINT}`, {params: {supplier_id: id}})
}

export function getSalesReport(params?: SearchModel)
{
  return axios.get<SalesReportModel>(`${ADMIN_ORDER_ENDPOINT}/sales-report`, {params})
}

export function exportSalesreport(params: SearchModel) {
  return axios.get(`${ADMIN_ORDER_ENDPOINT}/export-sales-report`, {params, responseType: 'blob'})
}

export function exportSupplierDebt(params: SearchModel) {
  return axios.get(`${ADMIN_SUPPLIER_ENDPOINT}/export-debt`, {params, responseType: 'blob'})
}

export function exportAgencyDebt(params: SearchModel) {
  return axios.get(`${ADMIN_AGENT_ENDPOINT}/export-debt`, {params, responseType: 'blob'})
}


