import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import QuantityModal from "./QuantityModal";
import { ModalModel } from "../../models/CommonModel";
import { getProduct } from "./CartCRUD";
import { SessionCartModel } from "../../models/SessionCartModel";
import _ from "lodash";
import { IOrderProduct } from "../../models/OrderModel";
import { Toast } from "../../modules/common/helper";
import { IProductOrderResource } from "../../modules/auth/models/ProductsModel";
import { useHistory } from "react-router-dom";

type Props = {
    id?: string
}

const AddToCart: React.FC<Props> = ({id}) => {
    const [quantityModal, setQuantityModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const history = useHistory();
    useEffect(() => {
        if (id) {
            initAddToCartProcess();
        }
    }, [])

    const initAddToCartProcess = async () => {
        const { data }: { data: IProductOrderResource } = await getProduct(id);
        setQuantityModal({...quantityModal, data: data.data, show: true});
    }

    const handleAddTocart = async (data: IOrderProduct, qty: number) => {
        let cartData = JSON.parse(localStorage.getItem('cart') || '[]');
        
        let pdIndex = _.findIndex(cartData, function (item: SessionCartModel)  {
            return item.id == data.id;
        })

        if (pdIndex >= 0) {
            cartData[pdIndex] = {...cartData[pdIndex], qty: qty, inventory_of_sento: data}
        } else {
            cartData.push({id: id, qty: qty, inventory_of_sento: data});
        }
        localStorage.setItem('cart', JSON.stringify(cartData));
        setQuantityModal({...quantityModal, show: false})
        Toast.fire({
            icon: 'success',
            title: 'Đã thêm vào giỏ hàng'
        })
        history.push("/orders/detail?createFromCart=1");
    } 

    const handleCancel = () => {
        setQuantityModal({ ...quantityModal, show: false });
        history.push("/orders");
    }
    return (
    
        <QuantityModal 
            {...quantityModal}
            handleAddTocart={handleAddTocart}
            onClose={handleCancel}
        />
    )
}
export default AddToCart;