import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PaginateModel } from "../../models/PaginateModel";
import * as AbilitiesCRUD from './AbilitiesCRUD';
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../_metronic/partials/pagination/PaginationLength";
import { Paginate } from "../../../_metronic/partials/pagination/Paginate";
import { PaginationHeader } from "../../../_metronic/partials/pagination/PaginationHeader";
import { useHistory } from "react-router-dom";
import queryString from 'query-string'
import { formatPrice } from "../../selectors";
import { SearchModel, AbilityModel } from "../../models/AbilityModel";
import AbilitiesTable from "./AbilitiesTable";
import Flatpickr from "react-flatpickr";
import { ABILITIES_PAYMENT_METHOD, ABILITIES_TYPES } from "../../constants/common";
import moment from "moment";
import {Sento, UserLoggedIn} from "../../hooks/PermissionChecker";
import { ModalModel } from "../../models/CommonModel";
import ConfigModal from "./ConfigModal";
import _ from "lodash";
import {Vietnamese} from "flatpickr/dist/l10n/vn.js"
import useMasterData from "../../hooks/useMaster";
export interface ReportModel {
    openning_balance: number
    total_expense: number
    total_revenue: number
    ending_balance: number
}

export interface HeaderModel {
    label: string
    style?: any 
    class?: string 
    field: string,
    sortable?: boolean
}

const Abilities: FC = () => {
    const intl = useIntl();
    const history = useHistory();
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<AbilityModel>>();
    const [hideCols, setHideCols] = useState<string[]>([]);
    const sento = Sento();
    const { master } = useMasterData();
    const fp = useRef<any>(null);
    const initRange:any = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    const initSearch = {
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0],
        date:{startDate: initRange[0], endDate: initRange[1]},
        store_id: UserLoggedIn().store_id
    }
    const [configModal, setConfigModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const [reportData, setReport] = useState<ReportModel>({
        openning_balance: 0,
        total_expense: 0,
        total_revenue: 0,
        ending_balance: 0
    });
    const [search, setSearch] = useState<SearchModel>(initSearch)
    const header: HeaderModel[] = [
        {
            label: intl.formatMessage({ id: 'ABILITIES.TYPE' }),
            style: { minWidth: "150px" },
            field: 'type',
            sortable: false
        },
        {
            label: intl.formatMessage({ id: 'ABILITIES.DATE' }),
            style:  { minWidth: "150px" },
            field: 'date',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ABILITIES.NAME' }),
            style:  { minWidth: "150px" },
            field: 'name',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ABILITIES.AMOUNT' }),
            style:  { minWidth: "250px" },
            field: 'value',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ABILITIES.DESCRIPTION' }),
            style:  { minWidth: "250px" },
            field: 'note',
            sortable: true
        }
    ]
    const parseParams = (params: SearchModel) => {
        if (params.page) {
            params.page = +params.page
        }
        if (params.per_page) {
            params.per_page = +params.per_page
        }
        return params
    }

    useEffect(() => {
		document.title = 'Sento - Sổ quỹ';
	})

    useEffect(() => {
        const searchHistory = history.location.search;
        const initParams: SearchModel = parseParams(queryString.parse(searchHistory))
        getData({ ...search, ...initParams });
        getReport({ ...search, ...initParams });
    }, []);

    const getData = async (params?: SearchModel) => {
        const { data: resData } = await AbilitiesCRUD.getList(params);
        const objQuery = { ...params }
        if (objQuery.page === 1) {
            delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
            delete objQuery.per_page
        }
        history.push({ search: `?${queryString.stringify(objQuery)}` })
        setDataPaginate(resData?.paginate_data);
        setSearch(params ?? {});
    }

    const getReport = async (params?: SearchModel) => {
        AbilitiesCRUD.getAbilityReport(params).then(({ data }) => {
            setReport(data);
        })
    }

    const handlePageChange = (data: any) => {
        getData({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getData({ ...search, page: 1, per_page: +e.target.value })
    }

    const handleSearch = (e: any) => {
        e.preventDefault();
        getData({ ...search, page: 1 })
        getReport({ ...search, page: 1 })
    }

    const handleClearSearch = (e: any) => {
        e.preventDefault();
        setSearch(initSearch);
        if (fp.current?.flatpickr) {
            fp.current.flatpickr.clear();
        }
        getData(initSearch)
    }
    
    const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
        const currentSearch = { ...search };
        const sortParams: SearchModel = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
        if (direction === 'none') {
          delete sortParams.sort_by;
          delete sortParams.sort_direction;
        }
        getData({ ...sortParams });
    }

    const handleChangeVisCols = (e: any) => {
        let hiddenCols = [...hideCols];
        if (!e.target?.checked) {
            setHideCols([...hiddenCols, e.target.name])
        } else {
            setHideCols(hiddenCols.filter((item) => item !== e.target.name ))
        }
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleSearch(e);
        }
    }

    return <>
        <div className="card">
            <div className="card-header py-4">
                <div className="col-md-12">
                    <form onSubmit={handleSearch}>
                        <div className="row">
                            <div className="col-md-2">
                                <label className="form-label">{intl.formatMessage({ id: 'COMMON.KEYWORD' })}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="keyword"
                                    value={search.keyword || ''}
                                    onChange={(e) => setSearch({ ...search, keyword: e.target.value })}
                                    autoComplete='nope'
                                    onKeyDown={(e) => handleKeyDown(e)}
                                />
                            </div>
                            <div className='col-md-3 mb-2'>
                                <label className="form-label">{intl.formatMessage({ id: 'COMMON.TIME' })}</label>
                                <Flatpickr
                                    ref={fp}
                                    onChange={(date) => {
                                        let startDate = moment(date[0]).format('YYYY-MM-DD');
                                        let endDate = moment(date[1]).format('YYYY-MM-DD');
                                        setSearch({ ...search, date: { startDate, endDate } });
                                    }}
                                    options={{
                                        mode: "range",
                                        dateFormat: 'd-m-Y',
                                        locale: Vietnamese,
                                        enableTime: false,
                                        defaultDate: [moment().startOf('month').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
                                    }}
                                    className='form-control'
                                    placeholder=''
                                />
                            </div>
                            
                            <div className="col-md-3 mb-2">
                                <label className="form-label">{intl.formatMessage({ id: 'ABILITIES.TYPE' })}</label>
                                <select className="form-select" value={search?.type ?? ''}
                                    onChange={(e) => setSearch({ ...search, type: e.target.value })}
                                    name="type">
                                    <option value=""></option>
                                    {ABILITIES_TYPES.map((type, key) => <option value={type?.id} key={`a_type_${key}`}>{type?.label}</option>)}
                                </select>
                            </div>
                            <div className="col-md-3 mb-2">
                                <label className="form-label">{intl.formatMessage({ id: 'ABILITIES.PAYMENT_MOTHOD' })}</label>
                                <select className="form-select" value={search?.payment_method ?? ''}
                                    onChange={(e) => setSearch({ ...search, payment_method: e.target.value })}
                                    name="payment_method">
                                    <option value=""></option>
                                    {ABILITIES_PAYMENT_METHOD.map((payment, key) => <option value={payment?.id} key={`a_payment_${key}`}>{payment?.label}</option>)}
                                </select>
                            </div>
                            {sento &&
                                <div className="col-md-3 mb-2">
                                    <label className="form-label">Store</label>
                                    <select
                                    className="form-select"
                                    value={search.store_id}
                                    onChange={(e) => setSearch({ ...search, store_id: e.target.value })}
                                    name="store_id"
                                    >
                                    <option value=''>Tất cả</option>
                                    {master?.stores?.map((store, index) => {
                                        return (
                                        <option value={store.id}>{store.name}</option>
                                        )
                                    })}
                                    </select>
                                </div>
                            }
                            <div className="col-md-4">
                                <div className="row">
                                    <button
                                        className="col-auto btn btn-primary mt-8 me-2"
                                        onClick={handleSearch}
                                    >
                                        <i className="bi bi bi-search fs-4"></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
                                    </button>
                                    <button
                                        className="col-auto btn btn-danger mt-8 me-2"
                                        type="button"
                                        onClick={handleClearSearch}
                                    >
                                        <i className="bi bi-eraser-fill fs-1"></i> Re-search
                                    </button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card-body">
                <div className="row justify-content-between align-items-center mb-5">
                    <div className='col-12 col-md-6 col-lg-3'>
                        <label className="fw-bolder">Số dư đầu kỳ</label><br/>
                        <span className="fs-3">{ formatPrice(reportData?.openning_balance) } VNĐ</span>
                        
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>
                        <label className="fw-bolder">Tổng thu</label><br/>
                        <span className="fs-3">{ formatPrice(reportData?.total_revenue) } VNĐ</span>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>
                        <label className="fw-bolder">Tổng chi</label><br/>
                        <span className="fs-3">{ formatPrice(reportData?.total_expense) } VNĐ</span>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>
                        <label className="fw-bolder">Tồn cuối kỳ</label><br/>
                        <span className="fs-3">{ formatPrice(reportData?.ending_balance) } VNĐ</span>
                    </div>
                </div>
                <hr/>
                <div className="row justify-content-between align-items-center mb-3">
                    <div className='col-7 col-md-9'>
                        <PaginationHeader from={dataPaginate?.meta?.from || 0} to={dataPaginate?.meta?.to || 0}
                            total={dataPaginate?.meta?.total || 0} />
                    </div>
                </div>
                <AbilitiesTable
                    data={dataPaginate?.data || []}
                    header={header}
                    handleSort={handleSort}
                    openColConfig={() => setConfigModal({...configModal, show: true})}
                    hideCols={hideCols}
                />
            </div>
            <div className="card-footer d-flex justify-content-between">
                <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
                <Paginate
                    pageCount={dataPaginate?.meta?.last_page || 0}
                    onPageChange={handlePageChange}
                    forcePage={(search.page || 1) - 1}
                />
            </div>
        </div>
        <ConfigModal
            {...configModal}
            header={header}
            hideCols={hideCols}
            handleChangeVisCols={handleChangeVisCols}
            onClose={() => { setConfigModal({ ...configModal, show: false }) }}
        />
    </>
}
export default Abilities;