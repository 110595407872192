import { FC } from "react";
import { useIntl } from "react-intl";
import { MetaPaginateModel } from "../../../../models/PaginateModel";
import { PaginationHeader } from "../../../../../_metronic/partials/pagination/PaginationHeader";

type TProps = {
	meta?: MetaPaginateModel,
}

const ProductsTableHeader: FC<TProps> = ({ meta }) => {
	const intl = useIntl()
	return (
		<div className="row justify-content-between align-items-center mb-3">
			<div className='col-md-6'>
				<PaginationHeader from={meta?.from ?? 0} to={meta?.to ?? 0} total={meta?.total ?? 0} />
			</div>
			<div className='col-md-6 text-end'>
			</div>
		</div >
	)
}

export default ProductsTableHeader