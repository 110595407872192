import React, { FC } from "react";
import { useIntl } from "react-intl";
import { IReceipt } from "../../../models/WareHouseModel";
import { OverlayTrigger, Tooltip } from "react-bootstrap-v5";
import { Link, useHistory } from "react-router-dom";
import { HeaderModel } from "../../../models/CommonModel";
import SortableColumn from "../../../components/Table/SortableColumn";
import { formatPrice } from "../../../selectors";
import _ from "lodash";


type TProps = {
    selectedIds: Array<number>
    data: Array<IReceipt>,
    header: HeaderModel[],
    hideCols: string[],
    openColConfig: () => void,
    handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void
    handleExportPdf: (id: number) => void
    setSelectedIds: (ids: Array<number>) => void
}

const ReceiptTable: FC<TProps> = ({ data, header, hideCols, selectedIds, openColConfig, handleSort, handleExportPdf, setSelectedIds }) => {
    const intl = useIntl();
    const history = useHistory();

    const handleRedirectDetail = (item: IReceipt) => history.push({
        pathname: `/warehouse/inventory-delivery/detail/${item.id}`,
        state: { receipt_code: item.receipt_code }
    });

    const handleCheckbox = (id: number) => {
        const ids = _.includes(selectedIds, id) ? _.without(selectedIds, id) : [...selectedIds, id];
        setSelectedIds([...ids]);
    }

    return <div className='table-responsive'>
        <table className="table table-hover table-bordered table-striped border gy-7 gs-7">
            <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                    <th>
                        <i
                            className="fa fa-cogs"
                            style={{ cursor: 'pointer' }}
                            onClick={openColConfig}
                        ></i>
                    </th>
                    {header.map((th, index) => {
                        if (!hideCols.includes(th.field)) {
                            if (th.sortable) {
                                return (
                                    <SortableColumn
                                        key={'th-' + index}
                                        sortBy={th?.field}
                                        onSortChange={handleSort}
                                        style={th?.style ?? {}}
                                    >
                                        {th.label}
                                    </SortableColumn>
                                )
                            }
                            return (
                                <th style={th?.style ?? {}}>{th.label}</th>
                            )
                        }
                    })}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data?.map((item: IReceipt, index: number) => {
                    var checked = _.includes(selectedIds, item.id)
                    return (
                        <tr key={`receipt_${item.id}_${index}`} className='cursor-pointer'>
                            <td>
                                {item.status !== 'Duyệt' && (
                                    <div className="form-check form-check-custom">
                                        <input key={`item_checkbox_${item.id}_${checked}`} className="form-check-input" type="checkbox"
                                            value={item.id}
                                            defaultChecked={checked}
                                            onChange={() => handleCheckbox(item.id ?? 0)}/>
                                    </div>
                                )}
                            </td>
                            {!hideCols.includes('receipt_code') && (
                                <td onClick={() => handleRedirectDetail(item)}>{item.receipt_code}</td>
                            )}
                            {!hideCols.includes('supplier_name') && (
                                <td onClick={() => handleRedirectDetail(item)}>{item.supplier?.name || ''}</td>
                            )}
                            {!hideCols.includes('sub_total') && (
                                <td onClick={() => handleRedirectDetail(item)}>{formatPrice(item.sub_total ?? 0)}</td>
                            )}
                            {!hideCols.includes('status') && (
                                <td onClick={() => handleRedirectDetail(item)}>{item.status}</td>
                            )}
                            {!hideCols.includes('approved_at') && (
                                <td onClick={() => handleRedirectDetail(item)}>{item.approved_at}</td>
                            )}
                            {!hideCols.includes('note') && (
                                <td onClick={() => handleRedirectDetail(item)}>{item.note}</td>
                            )}
                            <td>
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={<Tooltip id='tooltip-receipts'
                                        className="tooltip">{intl.formatMessage({ id: 'COMMON.VIEW' })}</Tooltip>}
                                >
                                    <Link to={{
                                        pathname: `/warehouse/inventory-delivery/detail/${item.id}`,
                                        state: { receipt_code: item.receipt_code }
                                    }}>
                                        <button className="btn btn-icon btn-light-primary w-30px h-30px ps-2 me-2">
                                            <i className="bi bi-eye-fill fs-4 me-2"></i>
                                        </button>
                                    </Link>
                                </OverlayTrigger></td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </div>
}
export default ReceiptTable
