import clsx from 'clsx'
import { useFormik } from 'formik'
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import * as StoreCRUD from '../StoreCRUD'
import { Toast } from '../../../modules/common/helper'
import Flatpickr from "react-flatpickr";
import moment from 'moment'
import {Vietnamese} from "flatpickr/dist/l10n/vn.js"
interface IFormData {
  name: string
  business_name: string
  email: string
  phone: string
  address: string
  status: string
  expired_at?: string
}

const initialValues: IFormData = {
  name: '',
  business_name: '',
  email: '',
  phone: '',
  address: '',
  status: '1'
}

interface IStoreInfoProps {
  modalVisible: boolean;
  toggleModal: (id?: number) => void;
  selectedId?: number;
  reloadData: boolean;
  handleReloadData: () => void;

}
const StoreInfo: FC<IStoreInfoProps> = ({ modalVisible, toggleModal, selectedId, reloadData, handleReloadData }) => {
  const intl = useIntl()
  const accountSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { name: 'họ tên' })),
    business_name: Yup.string().required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { business_name: 'Tên đăng ký kinh doanh' })),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'COMMON.EMAIL_NOT_VALID' }))
      .required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { name: 'email' })),
    phone: Yup.string()
      .max(255, intl.formatMessage({ id: 'COMMON.MAX_SYMBOLS' }, { max: 255 }))
      .required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { name: 'số điện thoại' })),
    address: Yup.string().required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { name: 'địa chỉ' })),
  })

  const formik = useFormik<IFormData>({
    initialValues,
    validationSchema: accountSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        let res = null;
        if (selectedId) {
          res = await StoreCRUD.update(values, selectedId);
        } else {
          res = await StoreCRUD.create(values);
        }

        Toast.fire({
          icon: 'success',
          title: intl.formatMessage({ id: 'COMMON.SUCCESS_NOTY' }),
        })
        toggleModal()
        handleReloadData()

      } catch (error: any) {
        if (error?.response && error?.response?.status === 422) {
          const validationErrors = error?.response?.data?.errors;
          formik.setErrors(validationErrors);
        }
      } finally {
        setSubmitting(false);
      }
    },
  })

  useEffect(() => {
    if (selectedId) {
      const fetchData = async () => {
        try {
          const { data } = await StoreCRUD.getDetail(selectedId);
          formik.setValues({
            name: data?.data?.name || '',
            business_name: data?.data?.business_name || '',
            email: data?.data?.email || '',
            phone: data?.data?.phone || '',
            address: data?.data?.address || '',
            status: data?.data?.status || '1',
            expired_at: data?.data?.expired_at
          });
        } catch (error) {
          console.error('Error:', error);
        }
      };

      fetchData();
    }
  }, [selectedId]);
  return (
    <div className={clsx('store-modal', { 'open': modalVisible })}>
      <div className='modal-content'>
        <form className='form w-100' onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card mb-5'>
                <div className='card-body row'>
                  <div className='col-12 mb-3'>
                    <label className='form-label' htmlFor='name'>
                      {intl.formatMessage({ id: 'STORE.NAME' })}
                    </label>
                    <input
                      {...formik.getFieldProps('name')}
                      id='name'
                      name='name'
                      value={formik.values.name || ''}
                      type='text'
                      className={clsx(
                        'form-control form-control-lg',
                        { 'is-invalid': formik.touched.name && formik.errors.name },
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      placeholder='Nhập họ tên'
                      autoComplete='off'
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    )}
                  </div>
                  <div className='col-12 mb-3'>
                    <label className='form-label' htmlFor='name'>
                      Tên đăng ký kinh doanh
                    </label>
                    <input
                      {...formik.getFieldProps('business_name')}
                      id='business_name'
                      name='business_name'
                      value={formik.values.business_name || ''}
                      type='text'
                      className={clsx(
                        'form-control form-control-lg',
                        { 'is-invalid': formik.touched.business_name && formik.errors.business_name },
                        {
                          'is-valid': formik.touched.business_name && !formik.errors.business_name
                        }
                      )}
                      placeholder='Nhập tên đăng ký kinh doanh'
                      autoComplete='off'
                    />
                    {formik.touched.business_name && formik.errors.business_name && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.business_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='col-12 mb-3'>
                    <label className='form-label' htmlFor='email'>
                      {intl.formatMessage({ id: 'STORE.EMAIL' })}
                    </label>
                    <input
                      {...formik.getFieldProps('email')}
                      id='email'
                      name='email'
                      type='text'
                      className={clsx(
                        'form-control form-control-lg',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                      placeholder='Nhập địa chỉ email'
                      autoComplete='off'
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    )}
                  </div>
                  <div className='col-12 mb-3'>
                    <label className='form-label' htmlFor='phone'>
                      {intl.formatMessage({ id: 'STORE.PHONE' })}
                    </label>
                    <input
                      {...formik.getFieldProps('phone')}
                      id='phone'
                      name='phone'
                      type='text'
                      maxLength={255}
                      className={clsx(
                        'form-control form-control-lg',
                        { 'is-invalid': formik.touched.phone && formik.errors.phone },
                        {
                          'is-valid': formik.touched.phone && !formik.errors.phone,
                        }
                      )}
                      placeholder='Nhập  số điện thoại'
                      autoComplete='off'
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.phone}</span>
                      </div>
                    )}
                  </div>
                  <div className='col-12 mb-3'>
                    <label className='form-label' htmlFor='address'>
                      {intl.formatMessage({ id: 'STORE.ADDRESS' })}
                    </label>
                    <input
                      {...formik.getFieldProps('address')}
                      id='address'
                      name='address'
                      type='text'
                      className={clsx(
                        'form-control form-control-lg',
                        { 'is-invalid': formik.touched.address && formik.errors.address },
                        {
                          'is-valid': formik.touched.address && !formik.errors.address,
                        }
                      )}
                      placeholder='Nhập địa chỉ'
                      autoComplete='off'
                    />
                    {formik.touched.address && formik.errors.address && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.address}</span>
                      </div>
                    )}
                  </div>
                  <div className='col-12 mb-3'>
                    <label className='form-label' htmlFor='status'>
                      {intl.formatMessage({ id: 'ACCOUNT.STATUS' })}
                    </label>
                    <select
                      {...formik.getFieldProps('status')}
                      id='status'
                      name='status'
                      className={clsx(
                        'form-select form-select-lg',
                        { 'is-invalid': formik.touched.status && formik.errors.status },
                        {
                          'is-valid': formik.touched.status && !formik.errors.status,
                        }
                      )}
                      autoComplete='off'
                    >
                      <option value='1'>{intl.formatMessage({ id: 'ACCOUNT.STATUS.ACTIVE' })}</option>
                      <option value='0'>{intl.formatMessage({ id: 'ACCOUNT.STATUS.INACTIVE' })}</option>
                    </select>
                    {formik.touched.status && formik.errors.status && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{formik.errors.status}</span>
                      </div>
                    )}
                  </div>
                  <div className='col-12 mb-3'>
                    <label className='form-label' htmlFor='status'>
                      Ngày hết hạn
                    </label>
                  <Flatpickr
                    name='expired_at'
                    key={formik.values.expired_at}
                    options={{
                        dateFormat: "d-m-Y",
                        locale: Vietnamese,
                        shorthandCurrentMonth: true,
                        defaultDate: moment(formik.values.expired_at).format('DD-MM-YYYY')
                    }}
                    onChange={(date) => {
                      formik.setValues({...formik.values, expired_at: moment(date[0]).format('YYYY-MM-DD')})
                    }}
                    className='form-control'
                    placeholder=''
                  />
                  </div>
                  <div className='col-md-12 text-end'>
                    <button type='submit' className='btn btn-primary'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-floppy'
                        viewBox='0 0 16 16'
                      >
                        <path d='M11 2H9v3h2z' />
                        <path d='M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z' />
                      </svg>{' '}
                      Lưu
                    </button>
                    <button
                      type='button'
                      className='btn btn-secondary ms-2 '
                      onClick={() => {
                        toggleModal();
                        document.querySelector('.store-modal')?.classList.add('modal-hidden');
                      }}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-x'
                        viewBox='0 0 16 16'
                      >
                        <path
                          fillRule='evenodd'
                          d='M3.646 3.646a.5.5 0 0 1 .708 0L8 
                          7.293l3.646-3.647a.5.5 0 0 1 .708.708L8.707 
                          8l3.647 3.646a.5.5 0 0 1-.708.708L8 8.707l-
                          3.646 3.647a.5.5 0 0 1-.708-.708L7.293 
                          8 3.646 4.354a.5.5 0 0 1 0-.708z'
                        />
                      </svg>{' '}
                      Đóng
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

  )
}

export default StoreInfo
