import React, {FC, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import _, {set, includes} from "lodash";
import { ReceiptDetailModel } from "../../../../models/ReceiptDetailModel";
import { IOrderProduct } from "../../../../models/OrderModel";
import { PaginateModel } from "../../../../models/PaginateModel";
import { Dictionary } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import { HeaderModel, ModalModel } from "../../../../models/CommonModel";
import { formatPrice, getQuantity, revertPriceNumner } from "../../../../selectors";
import commaToPoint from "../../../../selectors/commaToPoint";
import { Toast } from "../../../../modules/common/helper";
import SortableColumn from "../../../../components/Table/SortableColumn";
import { IInventoryOfSupplier } from "../../../../models/AgentProduct";
import { NumericFormat } from "react-number-format";
import ConfigModal from "../../../../components/Modal/ConfigModal";

type TProps = {
  receiptDetails?: Array<ReceiptDetailModel>
  products?: PaginateModel<IOrderProduct>,
  setProducts: any,
  setPlacedProducts: any,
  placedProducts: Dictionary<IOrderProduct>,
  flagPlacedProd?: number,
  handleCheckbox?: (id: number) => void,
  handleCheckAll?: (e: any) => void,
  arrDelete?: number[],
  canEdit?: boolean,
  handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void;
  hideCols: string[],
  setHideCols: (cols: string[]) => void
}

const ProductTable: FC<TProps> = ({
                                    products,
                                    setProducts,
                                    setPlacedProducts,
                                    placedProducts,
                                    flagPlacedProd,
                                    handleCheckbox,
                                    handleCheckAll,
                                    arrDelete,
                                    canEdit,
                                    handleSort,
                                    hideCols,
                                    setHideCols
                                  }) => {
  const intl = useIntl();
  const {id} = useParams<{ id?: string }>();

  const [configModal, setConfigModal] = useState<ModalModel>({
    show: false,
    mode: 'add',
  });

  const headers: HeaderModel[] = [
    {
      label: 'Image',
      style: { minWidth: "100px" },
      field: 'image_path',
      sortable: false
    },
    {
      label: intl.formatMessage({id: 'PRODUCTS.NAME'}),
      field: 'name',
      sortable: true,
      style: {maxWidth: "350px", minWidth: "350px"}
    },
    {
      label: intl.formatMessage({id: id ? 'PRODUCTS.ORDER_QUANTITY' : 'PRODUCTS.QUANTITY'}),
      field: 'order_quantity',
      sortable: false,
      style: {maxWidth: "130px", minWidth: "130px"}
    },
  ]
  headers.push({
    label: intl.formatMessage({id: 'PRODUCTS.PRICE_SELL'}),
    field: 'sale_price',
    sortable: true,
    style: {maxWidth: "120px", minWidth: "120px"}
  },{
    label: intl.formatMessage({id: 'PRODUCTS.VAT'}),
    field: 'vat',
    sortable: true,
    style: {maxWidth: "120px", minWidth: "120px"}
  }, {
    label: intl.formatMessage({id: 'ORDER.PRICE'}),
    field: 'order_price',
    sortable: false,
    style: {maxWidth: "120px", minWidth: "120px"}
  })

  const handleChange = (e: any, index: number, item: IOrderProduct, name = 'new_label', isRevert = false, handleBlur = false) => {
    let value = e.target.value;
    if (e.target.type == 'checkbox') {
      value = e.target.checked;
    }

    let newItem = {...item}
    set(newItem, name, value);

    let newData = {...products};
    if (newData.data) {
      newData = {
        ...newData,
        data: set(newData.data, index, newItem)
      }
      let newPlaceProd = {...placedProducts};
      newData?.data?.forEach(item => {
        const isAdd = getQuantity(item) > 0;
        if (isAdd) {
          newPlaceProd = {
            ...newPlaceProd,
            [item.id]: item
          }
        } else {
          delete newPlaceProd[item.id];
        }
      })
      setPlacedProducts({...newPlaceProd});
      setProducts(newData)
    }
  }

  const arrCanEdit = canEdit ? products?.data : [];
  const isCheckedAll = useMemo(() => arrDelete && arrDelete?.length > 0 && arrDelete?.length === arrCanEdit?.length, [arrDelete?.length])
  const isChecked = (id: number) => includes(arrDelete, id);

  const showCheckAll = !!(arrCanEdit?.length && id);


  const classTable = !!flagPlacedProd && Object.keys(placedProducts).length ? '' : ' table-hover table-striped '

  const handleChangeVisCols = (e: any) => {
    let hiddenCols = [...hideCols];
    if (!e.target?.checked) {
      setHideCols([...hiddenCols, e.target.name])
    } else {
      setHideCols(hiddenCols.filter((item) => item !== e.target.name))
    }
  }


  return <>
    <div className='table-responsive mt-5'>
      <table className={`table table-bordered border gy-7 gs-7 ${classTable}`}>
        <thead>
        <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
          <th>
            <i
              className="fa fa-cogs"
              style={{cursor: 'pointer'}}
              onClick={() => setConfigModal({...configModal, show: true})}
            ></i>
          </th>
          {showCheckAll &&
            <th>
              <input key={`checked_all_${isCheckedAll}`} className="form-check-input" type="checkbox"
                     checked={isCheckedAll}
                     onChange={handleCheckAll}/>
            </th>
          }
          {headers.map((th, index) => {
            if (!hideCols.includes(th.field)) {
              if (th.sortable) {
                return (
                  <SortableColumn
                    key={'th-' + index}
                    sortBy={th?.field}
                    onSortChange={handleSort}
                    style={th?.style ?? {}}
                  >
                    {th.label}
                  </SortableColumn>
                )
              }
              return (
                <th style={th?.style ?? {}}>{th.label}</th>
              )
            }
          })}
        </tr>
        </thead>
        <tbody>
        {products?.data?.map((item: IOrderProduct, index: number) => {
          const priceOfAgency = item.price_of_agency ?? {};
          const quantity = getQuantity(item);
          const salePrice = priceOfAgency?.sale_price ?? item.sales_product_by_sale?.price;
          const vat = item?.vat ?? 0;
          const price = salePrice * quantity + (salePrice * quantity * vat / 100);
          const addBackground = !!flagPlacedProd && quantity > 0;
          const checked = isChecked(item.id);
          return (
            <tr key={`order_product${index}`} className={`cursor-pointer ${quantity > 0 ? 'fw-bolder' : ''} ${addBackground ? 'bg-primary' : ''}`}>
              <td></td>
              {!hideCols.includes('image_path') && (
								<td>
									{item.image_path && item.image_path != '' ? (
										<img src={item.image_path} alt="Preview" style={{ maxWidth: "100%" }} />
									) : (
										'No image'
									)}
								</td>
								)}
              {(showCheckAll && id) &&
                <td>
                  {canEdit &&
                    <div className="form-check form-check-custom">
                      <input key={`item_checkbox_${item.id}_${checked}`} className="form-check-input" type="checkbox"
                             value={item.id}
                             defaultChecked={checked}
                             onChange={() => handleCheckbox && handleCheckbox(item.id)}/>
                    </div>
                  }
                </td>
              }
              {!hideCols.includes('name') &&
                <td>
                  {item.name}
                </td>
              }
              {!hideCols.includes('order_quantity') &&
                <td>
                  {canEdit ?
                    <>
                      <div className="input-group input-group-sm mb-3">
                        <input className="form-control form-control-sm" key={`quantity_${item.id}`}
                              min={0} defaultValue={quantity}
                              onChange={(e) => handleChange(e, index, item, 'new_quantity')}
                              onBlur={(e) => handleChange(e, index, item, 'new_quantity', false, true)}/>
                        <span className="input-group-text text-sm-center">{item?.units?.name || ""}</span>
                      </div>
                    </>
                    : `${quantity} ${item?.units?.name || ""}`
                  }
                </td>
              }
              {!hideCols.includes('sale_price') &&
                <td>
                  {formatPrice(salePrice)}
                </td>
              }
              {!hideCols.includes('vat') &&
                <td style={{width: '100px'}}>
                    {vat + '%'}
                </td>
              }
              {!hideCols.includes('order_price') &&
                <td key={`total_price_${item.id}`}><strong>{formatPrice(price)}</strong></td>
              }
            </tr>
          )
        })}
        </tbody>
      </table>
    </div>
    <ConfigModal
      {...configModal}
      header={headers}
      hideCols={hideCols}
      handleChangeVisCols={handleChangeVisCols}
      onClose={() => {
        setConfigModal({...configModal, show: false})
      }}
    />
  </>
}
export default ProductTable