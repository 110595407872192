import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {debounce, map} from "lodash";
import {Prompt, useHistory} from "react-router-dom";
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import * as OrderDetailCRUD from "./OrderDetailCRUD";
import axios, {AxiosError} from "axios";
import ProductTable from "./ProductTable";
import {Dictionary} from "@reduxjs/toolkit";
import { PaginateModel } from "../../../../models/PaginateModel";
import { IOrderDetail, IOrderProduct, ISearch, ISearchProduct } from "../../../../models/OrderModel";
import * as AgentCRUD from "../../Agency/AgentCRUD"
import { getCostPriceSento, getMarketPrice, getPriceDetail, getSalePrice } from "../../../../selectors";
import { Toast } from "../../../../modules/common/helper";
import { IAxiosError } from "../../../../models/AxiosModel";
import { HTTP_UNPROCESSABLE_ENTITY } from "../../../../constants/status";
import AsyncSelectAgent from "../../../order/AsyncSelectAgent";
import { PaginationHeader } from "../../../../../_metronic/partials/pagination/PaginationHeader";
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../../../_metronic/partials/pagination/PaginationLength";
import { Paginate } from "../../../../../_metronic/partials/pagination/Paginate";
import { ADMIN_SALES_ENDPOINT } from "../../../../constants/endpoints";

const OrderCreate: FC = () => {
  const initSearch = {
    page: 1,
    per_page: 10,
  }

  const initData = {
    agency_option: null,
    note: ''
  }

  const intl = useIntl();

  const schema = Yup.object().shape({
    agency_option: Yup.object().nullable().required(intl.formatMessage({id: 'COMMON.REQUIRED'}, {name: intl.formatMessage({id: 'ORDER.AGENCY'}).toLowerCase()})),
  });

  const [products, setProducts] = useState<PaginateModel<IOrderProduct>>()
  const [placedProducts, setPlacedProducts] = useState<Dictionary<IOrderProduct>>({})
  const [needAlert, setNeedAlert] = useState<any>(false)
  const [search, setSearch] = useState<ISearchProduct>(initSearch)
  const [data, setData] = useState<IOrderDetail>(initData)
  const agentRef = useRef<any>(null);
  const [hideCols, setHideCols] = useState<string[]>([]);

  const history = useHistory();

  useEffect(() => {
    document.title = 'Sento - Tạo đơn hàng';
  })

  useEffect(() => {
    const params = {...search}
    getListProducts(params);
    setSearch(params)
  }, []);

  useEffect(() => {
    if (Object.keys(placedProducts).length >  0) {
      setNeedAlert(true)
    }
  }, [placedProducts]);

  const getListProducts = async (params: ISearchProduct, newPlacedProd: Dictionary<IOrderProduct> = {}) => {
    let {data} = await AgentCRUD.getListProducts({...params})
    const newData = data.data.map((product: IOrderProduct) => {
      const placedProd = newPlacedProd?.[product.id] || placedProducts?.[product.id]
      let reFormatProd = placedProd ? placedProd : product;
      let salePrice = product.sales_product_by_sale?.price ?? getSalePrice(product);
      let marketPrice = getMarketPrice(product);
      let costPrice = getCostPriceSento(product);
      reFormatProd = {
        ...reFormatProd,
        price_of_agency: {
          ...reFormatProd.price_of_agency,
          sale_price: salePrice,
          market_price: marketPrice,
          cost_price_sento: costPrice,
        }
      }
      return reFormatProd;
    })
    data = {
      ...data,
      data: newData
    }
    setSearch(params);
    setProducts(data)
  }

  const handlePageLengthChange = (e: any) => {
    getListProducts({...search, page: 1, per_page: +e.target.value})
  }

  const handlePageChange = (data: any) => {
    getListProducts({...search, page: data.selected + 1})
  }

  const onChangePlacedProd = (e: any) => {
    const checked = e.target.checked
    let newSearch = {
      ...search,
      ...initSearch,
      placed_products: checked ? 1 : 0
    }
    let newPlacedProd = {};
    if (products?.data && checked) {
      newSearch = {
        ...newSearch,
        order_by_ids: map(placedProducts, 'id')
      }
    }
    getListProducts({...newSearch}, newPlacedProd)

  }

  const onSubmit = async (values: any, {setFieldError}: FormikHelpers<any>) => {
    const countItem = Object.keys(placedProducts)?.length ?? 0;
    if (countItem <= 0) {
      Toast.fire({
        icon: 'warning',
        title: 'Vui lòng chọn sản phẩm!',
      })
      return;
    }
    try {
      let params: any = {
        agency: values.agency_option,
        note: values?.note ?? '',
        products: placedProducts
      }
      const {data} = await OrderDetailCRUD.createOrder({...params});
      Toast.fire({
        icon: 'success',
        title: data?.message || intl.formatMessage({id: 'COMMON.SUCCESS_NOTY'}),
      })
      setNeedAlert(false);
      history.push('/my-orders')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<IAxiosError>;
        if (axiosError.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const errors = axiosError.response?.data?.errors ?? []
          Object.keys(errors)?.map((key: string) => setFieldError(key, errors[key][0]));
        }
      }
    }
  }

  const debounceGetListProd = useCallback(debounce((params) => getListProducts(params), 300), [placedProducts]);

  const onSearchProducts = (e: any, name = 'agency_id') => {
    const value = name === 'agency_id' ? e.target.value?.value : e.target.value;
    let params = {...search, ...initSearch, [name]: null}
    if (value) {
      params = {
        ...params,
        [name]: value
      }
    }
    debounceGetListProd(params);
  }
  const priceDetail = useMemo(() => getPriceDetail(placedProducts), [placedProducts])

  const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
    const currentSearch = {...search};
    const sortParams: ISearch = {...currentSearch, sort_by: sortBy, sort_direction: direction};
    if (direction === 'none') {
      delete sortParams.sort_by;
      delete sortParams.sort_direction;
    }
    getListProducts({...sortParams});
  }
  return <Formik enableReinitialize validationSchema={schema} initialValues={{...data}} onSubmit={onSubmit}>
    {({values, handleChange, setTouched}) => (
      <div className="card">
        <Prompt
          when={needAlert}
          message="Dữ liệu đơn hàng chưa được lưu, bạn có chăc chắn muốn chuyển trang?"
        />
        <Form>
          <div className="card-header pb-5">
            <div className="col-12">
              <div className="row mt-2">
                <label htmlFor="note"
                       className="col-4 col-md-3 col-form-label mb-2 required">{intl.formatMessage({id: 'AGENT.INFO'})}</label>
                <div className="col-8 col-md-4 mb-2">
                  <AsyncSelectAgent 
                    values={values.agency_option}
                    handleChange={(e) => {
                      setTouched({agency_option: true});
                      handleChange(e)
                      onSearchProducts(e)
                    }}
                    agentsAppend={[]}
                    forwardRef={agentRef}
                    name='agency_option'
                  />
                  <ErrorMessage name='agency_option' className='text-danger' component='span'/>
                </div>
              </div>
            </div>
            <label htmlFor="note"
                   className="col-12 col-md-3 col-form-label mb-2">{intl.formatMessage({id: 'ORDER.NOTE'})}</label>
            <div className="col-12 col-md-10 mb-2">
              <Field
                as='textarea'
                className="form-control"
                id="note"
                name="note"
                value={values?.note || ''}
              />
            </div>
            <div className="col-12 mt-2">
              <div className="d-flex justify-content-center">
                  <button className="btn btn-success me-3"
                          type="submit">{intl.formatMessage({id: 'COMMON.SAVE'})}</button>
                <button className="btn btn-secondary" type="button"
                        onClick={history.goBack}>{intl.formatMessage({id: 'COMMON.BACK'})}</button>
              </div>
            </div>
          </div>
        </Form>

          <div className="card-body">
            <div className="row mt-2">
              <label htmlFor="product_info"
                     className="col-4 col-md-2 col-form-label mb-2">{intl.formatMessage({id: 'PRODUCTS.INFO'})}</label>
              <div className="col-8 col-md-4 mb-2">
                <input
                  type='text'
                  className='form-control'
                  name="product_info"
                  onChange={(e) => onSearchProducts(e, 'name')}
                />
              </div>
              <div className="col-5 offset-1">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="placed_products"
                         name='placed_products'
                         onChange={onChangePlacedProd}/>
                  <label htmlFor="placed_products" className="form-check-label mb-2">Hiển thị sản phẩm đặt</label>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              
              <div className="col d-flex">
                <h4>{intl.formatMessage({id: 'ORDER.PRICE'})}:</h4>
                <h3 className="ps-2">{priceDetail.taxInc || 0}</h3>
              </div>
            </div>
            { values?.agency_option && (
              <>
                <div className="justify-content-between align-items-center mt-5">
                  <PaginationHeader from={products?.meta?.from || 0} to={products?.meta?.to || 0}
                                    total={products?.meta?.total || 0}/>
                </div>
                <ProductTable canEdit products={products} placedProducts={placedProducts} setProducts={setProducts}
                              setPlacedProducts={setPlacedProducts} flagPlacedProd={search.placed_products}
                              handleSort={handleSort} hideCols={hideCols} setHideCols={setHideCols}/>
                <div className="d-flex justify-content-between">
                  <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]}
                                    onChange={handlePageLengthChange}/>
                  <Paginate
                    pageCount={products?.meta?.last_page || 0}
                    onPageChange={handlePageChange}
                    forcePage={(search.page || 1) - 1}
                  />
                </div>
              </>
            )}
          </div>
        </div>
    )}
  </Formik>
}
export default OrderCreate;