import { ChangeEvent, FC } from 'react'
import { useIntl } from 'react-intl'
import { TSearch } from '../../../../modules/auth/models/RoleModels'

type TProps = {
  handleChangeSearch: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void,
  handleSearch: () => void,
  handleClearSearch: () => void
  search: TSearch
}



const FilterList: FC<TProps> = ({ handleChangeSearch, handleSearch, handleClearSearch, search }) => {
  const intl = useIntl()
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  return (
    <div className='card'>
      <div className='card-body pb-0 px-0'>
        <div className='row'>
          <div className='col-md-3'>
            <label className='form-label'>{intl.formatMessage({ id: 'ROLE.NAME' })}</label>
            <input type='text' value={search.name || ''} className='form-control' placeholder='' name='name' onChange={handleChangeSearch} onKeyDown={(e) => handleKeyDown(e)} />
          </div>
          <div className='col-md-4 text-end'>
            <button type='button' className='btn btn-primary mt-8 me-2' onClick={handleSearch}>
              <i className='bi bi bi-search fs-4'></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
            </button>
            <button type='button' className='btn btn-danger mt-8' onClick={handleClearSearch}>
              <i className='fa fa-times'></i> {intl.formatMessage({ id: 'COMMON.DELETE_SEARCH' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export  { FilterList }