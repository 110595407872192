export const ORDER_STATUS = ['Chưa Duyệt', 'Duyệt']
export const SENTO = 'Super Admin'
export const LIST_PERMISSIONS = {
  DASHBOARD: 'dashboard',
  PRODUCTS: 'products',
  ORDERS: 'orders',
  SUPPLIERS: 'suppliers',
  AGENTS: 'agents',
  WAREHOUSE: 'warehouse',
  ABILITIES: 'abilities',
  DEBTS: 'debts',
  ACCOUNTS: 'accounts',
  STORES: 'stores',
  DASHBOARD_LIST: 'dashboard-list',
  DASHBOARD_CREATE: 'dashboard-create',
  DASHBOARD_UPDATE: 'dashboard-update',
  DASHBOARD_DELETE: 'dashboard-delete',

  PRODUCT_LIST: 'product-list',
  PRODUCT_CREATE: 'product-create',
  PRODUCT_UPDATE: 'product-update',
  PRODUCT_DELETE: 'product-delete',

  ORDER_LIST: 'order-list',
  ORDER_CREATE: 'order-create',
  ORDER_UPDATE: 'order-update',
  ORDER_DELETE: 'order-delete',

  SUPPLIER_LIST: 'supplier-list',
  SUPPLIER_CREATE: 'supplier-create',
  SUPPLIER_UPDATE: 'supplier-update',
  SUPPLIER_DELETE: 'supplier-delete',

  CUSTOMER_LIST: 'customer-list',
  CUSTOMER_CREATE: 'customer-create',
  CUSTOMER_UPDATE: 'customer-update',
  CUSTOMER_DELETE: 'customer-delete',

  INVENTORY_LIST: 'inventory-list',
  INVENTORY_CREATE: 'inventory-create',
  INVENTORY_UPDATE: 'inventory-update',
  INVENTORY_DELETE: 'inventory-delete',

  ABILITIES_LIST: 'abilities-list',
  ABILITIES_CREATE: 'abilities-create',
  ABILITIES_UPDATE: 'abilities-update',
  ABILITIES_DELETE: 'abilities-delete',

  DEBT_LIST: 'debt-list',
  DEBT_CREATE: 'debt-create',
  DEBT_UPDATE: 'debt-update',
  DEBT_DELETE: 'debt-delete',

  ACCOUNT_LIST: 'account-list',
  ACCOUNT_CREATE: 'account-create',
  ACCOUNT_UPDATE: 'account-update',
  ACCOUNT_DELETE: 'account-delete',

  STORE_LIST: 'store-list',
  STORE_CREATE: 'store-create',
  STORE_UPDATE: 'store-update',
  STORE_DELETE: 'store-delete',
  
}

export const APPROVED_STT = 'Duyệt'
export const PAYMENT_VOUCHER = 'Phiếu chi'
export const RECIEPT_VOUCHER ='Phiếu thu'
export const ABILITIES_TYPES = [
  {id: 'payment_voucher', label: PAYMENT_VOUCHER},
  {id: 'receipt_voucher', label: RECIEPT_VOUCHER}
]
export const BANKING_METHOD = 'Chuyển khoản'
export const CASH_METHOD ='Tiền mặt'
export const ABILITIES_PAYMENT_METHOD = [
  {id: 'banking', label: BANKING_METHOD},
  {id: 'cash', label: CASH_METHOD}
]
