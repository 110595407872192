import clsx from 'clsx'
import { useFormik } from 'formik'
import { ChangeEvent, FC, useEffect, useState, useMemo } from 'react'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router-dom'
import { IPermission } from '../../modules/auth/models/PermissionsModel'
import { Toast } from '../../modules/common/helper'
import { IAccount } from '../../modules/auth/models/AccountModel'
import LoginInfo from '../accounts/components/user/LoginInfo'
import * as AccountInfoCRUD from "./AccountInfoCRUD";
import { UserModel } from '../../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'

interface IFormData {
    store_id: string
    name: string
    email: string
    password: string
    user_name: string
    phone: string
    identity_number: string
    address: string
    status: string
    role: string
    permissions: IPermission[]
}
const initialValues: IFormData = {
    store_id: '',
    name: '',
    email: '',
    password: '',
    user_name: '',
    phone: '',
    identity_number: '',
    address: '',
    status: '1',
    role: '',
    permissions: [],
}
type TProps = {}

const AccountInfo: FC<TProps> = () => {
    const intl = useIntl()
    const [data, setData] = useState<IAccount>()

    const accountSchema = Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { name: 'họ tên' })),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'COMMON.EMAIL_NOT_VALID' }))
            .required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { name: 'email' })),
        password: Yup.string().when('isNew', {
            is: true,
            then: Yup.string()
                .min(6, intl.formatMessage({ id: 'COMMON.MIN_SYMBOLS' }, { min: 6 }))
                .required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { name: 'mật khẩu' })),
            otherwise: Yup.string().min(6, intl.formatMessage({ id: 'COMMON.MIN_SYMBOLS' }, { min: 6 })),
        }),
        user_name: Yup.string()
            .max(255, intl.formatMessage({ id: 'COMMON.MAX_SYMBOLS' }, { max: 30 }))
            .required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { name: 'tên đăng nhập' })),
        phone: Yup.string()
            .max(255, intl.formatMessage({ id: 'COMMON.MAX_SYMBOLS' }, { max: 20 }))
            .required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { name: 'số điện thoại' })),
        identity_number: Yup.string()
            .max(255, intl.formatMessage({ id: 'COMMON.MAX_SYMBOLS' }, { max: 20 }))
            .required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { name: 'số chứng minh' })),
        address: Yup.string().required(intl.formatMessage({ id: 'COMMON.REQUIRED' }, { name: 'địa chỉ' })),
    })

    const formik = useFormik<IFormData>({
        initialValues,
        validationSchema: accountSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            try {
                const { data } = await AccountInfoCRUD.update(values)
                Toast.fire({
                    icon: 'success',
                    title: data?.message || intl.formatMessage({ id: 'COMMON.SUCCESS_NOTY' }),
                })
            } catch (error: any) {
                if (error?.response && error?.response?.status === 422) {
                    const validationErrors = error?.response?.data?.errors;
                    formik.setErrors(validationErrors);
                }
                Toast.fire({
                    icon: 'error',
                    title: intl.formatMessage({ id: 'COMMON.ERROR_NOTY' }),
                })
            } finally {
                setSubmitting(false)
            }
        },
    })

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await AccountInfoCRUD.getDetail()
                setData(data?.data)
                formik.setValues({
                    store_id: data?.data?.store_id || '',
                    name: data?.data?.name || '',
                    email: data?.data?.email || '',
                    password: '',
                    user_name: data?.data?.user_name || '',
                    phone: data?.data?.phone || '',
                    identity_number: data?.data?.identity_number || '',
                    address: data?.data?.address || '',
                    status: data?.data?.status || '1',
                    role: data?.data?.roles[0]?.id || '',
                    permissions: [],
                })
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [])

    const handleChangeStatus = (e: ChangeEvent<HTMLSelectElement>) => {
        formik.setFieldValue('status', e.target.value)
    }
    return (
        <form className='form w-100' onSubmit={formik.handleSubmit}>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='card mb-5'>
                        <div className='card-body row'>
                            <div className='col-12 mb-3'>
                                <label className='form-label' htmlFor='name'>
                                    {intl.formatMessage({ id: 'ACCOUNT.NAME' })}
                                </label>
                                <input
                                    {...formik.getFieldProps('name')}
                                    id='name'
                                    name='name'
                                    value={formik.values.name || ''}
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.name && formik.errors.name },
                                        {
                                            'is-valid': formik.touched.name && !formik.errors.name,
                                        }
                                    )}
                                    placeholder='Nhập họ tên'
                                    autoComplete='off'
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.name}</span>
                                    </div>
                                )}
                            </div>
                            <div className='col-12 mb-3'>
                                <label className='form-label' htmlFor='email'>
                                    {intl.formatMessage({ id: 'ACCOUNT.EMAIL' })}
                                </label>
                                <input
                                    {...formik.getFieldProps('email')}
                                    id='email'
                                    name='email'
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.email && formik.errors.email },
                                        {
                                            'is-valid': formik.touched.email && !formik.errors.email,
                                        }
                                    )}
                                    placeholder='Nhập địa chỉ email'
                                    autoComplete='off'
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.email}</span>
                                    </div>
                                )}
                            </div>

                            <div className='col-12 mb-3'>
                                <label className='form-label' htmlFor='phone'>
                                    {intl.formatMessage({ id: 'ACCOUNT.PHONE' })}
                                </label>
                                <input
                                    {...formik.getFieldProps('phone')}
                                    id='phone'
                                    name='phone'
                                    type='text'
                                    maxLength={20}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.phone && formik.errors.phone },
                                        {
                                            'is-valid': formik.touched.phone && !formik.errors.phone,
                                        }
                                    )}
                                    placeholder='Nhập  số điện thoại'
                                    autoComplete='off'
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.phone}</span>
                                    </div>
                                )}
                            </div>
                            <div className='col-12 mb-3'>
                                <label className='form-label' htmlFor='identity_number'>
                                    {intl.formatMessage({ id: 'ACCOUNT.INDENTIFY_NUMBER' })}
                                </label>
                                <input
                                    {...formik.getFieldProps('identity_number')}
                                    id='identity_number'
                                    name='identity_number'
                                    maxLength={20}
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.identity_number && formik.errors.identity_number },
                                        {
                                            'is-valid': formik.touched.identity_number && !formik.errors.identity_number,
                                        }
                                    )}
                                    placeholder='Nhập số chứng minh'
                                    autoComplete='off'
                                />
                                {formik.touched.identity_number && formik.errors.identity_number && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.identity_number}</span>
                                    </div>
                                )}
                            </div>
                            <div className='col-12 mb-3'>
                                <label className='form-label' htmlFor='address'>
                                    {intl.formatMessage({ id: 'ACCOUNT.ADDRESS' })}
                                </label>
                                <input
                                    {...formik.getFieldProps('address')}
                                    id='address'
                                    name='address'
                                    type='text'
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.address && formik.errors.address },
                                        {
                                            'is-valid': formik.touched.address && !formik.errors.address,
                                        }
                                    )}
                                    placeholder='Nhập địa chỉ'
                                    autoComplete='off'
                                />
                                {formik.touched.address && formik.errors.address && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.address}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='card-body row'>
                            <LoginInfo 
                                formik={formik} 
                                handleChangeStatus={handleChangeStatus}
                                hideStatus={true}
                            />
                        </div>
                        <div className='card-footer d-flex justify-content-between'>
                            <div className='col-md-12 text-end'>
                                <button type='submit' className='btn btn-primary'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        fill='currentColor'
                                        className='bi bi-floppy'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M11 2H9v3h2z' />
                                        <path d='M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z' />
                                    </svg>{' '}
                                    Lưu
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default AccountInfo
