import React from 'react';
import { Field, FieldProps } from 'formik';

interface IProps {
  id?: string;
  name: string;
  label: string;
  checked: boolean
  handleChange: (e: any) => void
}

const NormalCustomCheckbox: React.FC<IProps> = ({ id, name, label, checked = true, handleChange, ...props }) => (
  <div className='form-check form-check-custom mb-3'>
    <input
      {...props}
      type='checkbox'
      className='form-check-input'
      id={id ?? name}
      name={name}
      checked={checked}
      onChange={(e:any) => handleChange(e)}
    />
    <label className='form-check-label' htmlFor={id ?? name}>
      {label}
    </label>
  </div>
);

export default NormalCustomCheckbox;
