import React, { FC, useMemo } from 'react';
import { useIntl } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap-v5";
import _, { includes } from 'lodash'
import { Link, useHistory } from "react-router-dom";
import { IOrder, IOrderDetail } from '../../../models/OrderModel';
import { HeaderModel } from '../../../models/CommonModel';
import { usePermissionChecker } from '../../../hooks/PermissionChecker';
import { LIST_PERMISSIONS } from '../../../constants/common';
import SortableColumn from '../../../components/Table/SortableColumn';
import { IReceiptDetail } from '../../../models/WareHouseModel';
import { formatPrice } from '../../../selectors';
import moment from 'moment';

type Props = {
    data: Array<IOrderDetail>,
    header: HeaderModel[]
    hideCols: string[],
    openColConfig: () => void,
    handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void
};

const OrderDetailTable: FC<Props> = ({ data, header, hideCols, openColConfig, handleSort }) => {
    const intl = useIntl();

    const history = useHistory();

    const { hasPermission } = usePermissionChecker();

    return (
        <div className='table-responsive'>
            <table className="table table-hover table-bordered table-striped border gy-7 gs-7">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th>
                            <i 
                                className="fa fa-cogs"
                                style={{cursor: 'pointer'}}
                                onClick={openColConfig}
                            ></i>
                        </th>
                        {header.map((th, index) => {
                            if (!hideCols.includes(th.field)) {
                                if (th.sortable) {
                                    return (
                                        <SortableColumn
                                            key={'th-' + index}
                                            sortBy={th?.field}
                                            onSortChange={handleSort}
                                            style={th?.style ?? {}}
                                        >
                                            {th.label}
                                        </SortableColumn>
                                    )
                                }
                                return (
                                    <th style={th?.style ?? {}}>{th.label}</th>
                                )
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item: IOrderDetail, index: number) => {
                        let order = item.order;
                        let agency = order?.agency;
                        let iOS = item.inventory_of_sento;
                        let receiptDetail = _.find(order?.recript_details ?? [], (el: IReceiptDetail) => {
                           return  (el.inventory_of_sento_id == item.inventory_of_sento_id)
                        });
                        let marketPrice = iOS?.price ?? 0;
                        let unitPrice = item?.unit_price ?? 0;
                        let discount = 100*(marketPrice - unitPrice)/marketPrice;
                        return (
                            <tr key={`agent_${index}`} className="cursor-pointer">
                                <td></td>
                                {!hideCols.includes('inventory_name') && (
                                <td>
                                    {item.inventory_name}
                                </td>
                                )}
                                {!hideCols.includes('agency_name') && (
                                <td>{agency?.name ?? ''}</td>
                                )}
                                {!hideCols.includes('quantity') && (
                                <td>{item.quantity ?? 0}</td>
                                )}
                                {!hideCols.includes('capital_price') && (
                                <td >{formatPrice(receiptDetail?.price ?? 0)}</td>
                                )}
                                {!hideCols.includes('unit_price') && (
                                <td >{formatPrice(unitPrice)}</td>
                                )}
                                {!hideCols.includes('market_price') && (
                                <td >{formatPrice(marketPrice)}</td>
                                )}
                                {!hideCols.includes('discount') && (
                                <td >{discount.toFixed(2)}%</td>
                                )}
                                {!hideCols.includes('order_code') && (
                                <td >{order?.order_code}</td>
                                )}
                                {!hideCols.includes('created_at') && (
                                <td >{item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY') : ''}</td>
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default OrderDetailTable;