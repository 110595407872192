import React, {FC} from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import Store from './Store'
import {useParams} from 'react-router-dom'
import StoreInfo from './components/StoreInfo'

const StoretWrapper: FC = () => {
  const intl = useIntl()
  const {id} = useParams<{id?: string}>()
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.STORE'}),
      path: '/stores',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Switch>
      <Route path='/stores' exact>
        <PageTitle breadcrumbs={breadcrumbs}>
          {intl.formatMessage({id: 'MENU.STORE.LIST'})}
        </PageTitle>
        <Store />
      </Route>
      <Redirect from='/stores' to='/stores' />
    </Switch>
  )
}

export default StoretWrapper
