import React, {FC, ChangeEvent} from 'react'
import {IPermission} from '../../../../modules/auth/models/PermissionsModel'
import {UserPermission, hasPermission} from '../../../../modules/utils/UserPermissions '
import {IScreenModel} from '../../../../models/IScreenModel'

interface PermissionComponentProps {
  listScreen: IScreenModel[]
  selectedRole: string | null
  selectedRolePermissions: IPermission[] | null
  handleCheckedPermission: (e: ChangeEvent<HTMLInputElement>, permission: IPermission) => void
}

const PermissionComponent: FC<PermissionComponentProps> = ({
  listScreen,
  selectedRole,
  selectedRolePermissions,
  handleCheckedPermission,
}) => {
  const permissionName = (permissionName: string): string => {
    const translations: Record<string, string> = {
      list: 'Xem',
      create: 'Tạo mới',
      update: 'Sửa',
      delete: 'Xóa',
    }
    const parts = permissionName.split('-')
    const lastPart = parts[parts.length - 1]
    return translations[lastPart] || lastPart
  }

  return (
    <>
      {selectedRole ? (
        <div>
          <div className='fw-bold fs-5 border-bottom my-5'>Quyền hạn của vai trò</div>
          <div className='row'>
            {listScreen?.map((item) => (
              <div key={item.id}>
                <div className='fw-bold fs-5'>{item.name}</div>
                <div className='col-12 mb-3 border-bottom d-flex justify-content-between'>
                  {item.permissions.map((p) => (
                    <div key={p.id} className='form-check my-2 me-10'>
                      <label className='form-check-label mx-2'>
                        {permissionName(p.name)}
                        <input
                          className='cursor-pointer form-check-input mx-2 fs-5'
                          type='checkbox'
                          disabled={true}
                          checked={
                            selectedRolePermissions
                              ? hasPermission(selectedRolePermissions, p.name)
                              : false
                          }
                          onChange={(e) => handleCheckedPermission(e, p)}
                        />
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className='text-center'>Vui lòng chọn vai trò</div>
      )}
      
    </>
  )
}

export default PermissionComponent
