import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { IFilter, ISearch } from '../../../../modules/auth/models/ProductsModel'
import { GET_CATEGORIES_LIST } from '../../../../constants/endpoints'
import { AsyncSelect2 } from '../../../../../_metronic/partials/select/AsyncSelect2'

type TProps = {
  search: IFilter
  setSearch: (value: IFilter | ((prevState: IFilter) => IFilter)) => void,
  handleSearch: () => void,
  handleClearSearch: () => void
}

const FilterList: FC<TProps> = ({ search, setSearch, handleSearch, handleClearSearch }) => {
  const [filter, setFilter] = useState<IFilter>({})
  const isFirstRender = useRef(true);
  const cateRef = useRef(true);
  const intl = useIntl()

  const handleFillter = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    handleChangeSearch(e);
    setFilter({ ...filter, [name]: checked ? 1 : 0 })
  }

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    setSearch((prevState: ISearch) => {
      const updatedValue = type === 'checkbox' ? ((e.target as HTMLInputElement).checked ? 1 : 0) : value;

      return {
        ...prevState,
        [name]: updatedValue,
      };
    });
  }

  useEffect(() => {
    if (!isFirstRender.current) {
      handleSearch();
    } else {
      isFirstRender.current = false;
    }
  }, [filter])

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  return (
    <div className='card'>
      <div className='card-body pb-0 px-0'>
        <div className='row  mb-0'>
          <div className='col-md-3'>
            <label className='form-label'>{intl.formatMessage({ id: 'PRODUCTS.NAME' })}</label>
            <input
              type='text'
              className='form-control'
              placeholder=''
              name='name'
              onChange={handleChangeSearch}
              value={search?.name ?? ''}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </div>
          <div className="col-md-3 mb-2">
            <label className="form-label">Danh mục cha</label>
            <AsyncSelect2
              sourceUrl={`${GET_CATEGORIES_LIST}`}
              defaultOptions
              default
              name='category_id'
              value={{ value: search?.category_id ?? '', label: search?.category_name ?? 'Tất cả' }}
              onChange={(selectedOption: any) => {
                setSearch({ ...search, category_id: selectedOption.value, category_name: selectedOption.label })
              }}
            />
          </div>
          <div className="col-md-3 mb-2">
            <label className="form-label">Danh mục con</label>
            <AsyncSelect2
              sourceUrl={`${GET_CATEGORIES_LIST}?parent_id=${search?.category_id}`}
              defaultOptions
              default
              name='sub_cate_id'
              value={{ value: search?.sub_cate_id ?? '', label: search?.sub_cate_name ?? 'Tất cả' }}
              onChange={(selectedOption: any) => {
                setSearch({ ...search, sub_cate_id: selectedOption.value, sub_cate_name: selectedOption.label })
              }}
            />
          </div>
          <div className='col-md-3 row px-0'>
            <div className='col'>
              <label className='form-label' htmlFor='fromPrice'>
                {intl.formatMessage({ id: 'PRODUCTS.PRICE_FROM' })}
              </label>
              <input
                id='fromPrice'
                name='from_price'
                type='number'
                className='form-control'
                onChange={handleChangeSearch}
                value={search.from_price ?? ''}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>

            <div className='col'>
              <label className='form-label' htmlFor='toPrice'>
                {intl.formatMessage({ id: 'PRODUCTS.PRICE_TO' })}
              </label>
              <input
                id='toPrice'
                name='to_price'
                type='number'
                className='form-control'
                onChange={handleChangeSearch}
                value={search.to_price ?? ''}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col-md-3 text-end'>
            <button type='button' className='btn btn-primary mt-8 me-2' onClick={() => handleSearch()}>
              <i className='bi bi bi-search fs-4'></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
            </button>
            <button type='button' className='btn btn-danger mt-8' onClick={handleClearSearch}>
              <i className='fa fa-eraser'></i> Re-search
            </button>
          </div>
        </div>
        <div className="row">
          <div className='d-flex'>
            <label className='form-check form-check-sm form-check-custom form-check-solid'>
              <input className='form-check-input' type='checkbox' name='is_company' defaultChecked={false} onChange={handleFillter} />
              <span className='form-check-label'>Hàng công ty</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export { FilterList }