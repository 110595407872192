import { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { NumericFormat } from 'react-number-format';
import { IFilter, IProductsWithBestSupplier } from '../../../../modules/auth/models/ProductsModel';
import { HeaderModel } from '../../../../models/CommonModel';
import useMasterData from '../../../../hooks/useMaster';
import SortableColumn from '../../../../components/Table/SortableColumn';
import { formatPrice } from '../../../../selectors';
import { roundNumber } from '../../../../modules/common/helper';

interface ProductsTableProps {
	data: Array<IProductsWithBestSupplier>;
	handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void;
	header: HeaderModel[]
	hideCols: string[],
	openColConfig: () => void,
	search?:IFilter
}


const ProductsTable: FC<ProductsTableProps> = ({
	data,
	handleSort,
	header,
	hideCols,
	openColConfig,
	search
}) => {
	const intl = useIntl()

	return (
		<div className='table-responsive'>
			<table className='table table-hover table-bordered table-striped border gy-7 gs-7'>
				<thead>
					<tr key={search?.page ?? 1} className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
						<th>
							<i 
                                className="fa fa-cogs"
                                style={{cursor: 'pointer'}}
                                onClick={openColConfig}
                            ></i>
						</th>
						{header.map((th, index) => {
                            if (!hideCols.includes(th.field)) {
                                if (th.sortable) {
                                    return (
                                        <SortableColumn
                                            key={'th-'+index}
                                            sortBy={th?.field}
                                            onSortChange={handleSort}
                                            style={th?.style ?? {}}
                                        >
                                            {th.label}
                                        </SortableColumn>
                                    )
                                }
                                return (
                                    <th style={th?.style ?? {}}>
										{th.label}
									</th>
                                )
                            }
                        })}
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? data?.map((item: IProductsWithBestSupplier, index: number) => {
						var isCompany = item?.is_company;
						return (
							<tr key={`product_${item.id}_${index}`} className='cursor-pointer'>
								<td></td>
								{!hideCols.includes('image_path') && (
								<td>
									{item.image_path != '' ? (
										<img width={100} src={item.image_path} alt="Preview" style={{ maxWidth: "100%" }} />
									) : (
										'No image'
									)}
								</td>
								)}
								{!hideCols.includes('name') && (
								<td >
									{item?.name ?? ''}
								</td>
								)}
								{!hideCols.includes('category_id') && (
								<td >
									{item?.category?.name ?? ''}
								</td>
								)}
								{!hideCols.includes('supplier_price_sell') && (
								<td>
									{formatPrice(item?.sales_product_by_sale?.price ?? 0)}
								</td>
								)}
								{!hideCols.includes('unit') && (
								<td>
									{item?.units?.name ?? ''}
								</td>
								)}
								{!hideCols.includes('is_company') && (
								<td key={item.id + (isCompany ? 'true' : 'false')}>
									{isCompany ? 'Hàng công ty' : ''}
								</td>
								)}
							</tr>
						)
					}) : (<tr><th colSpan={8} className='text-center'>{intl.formatMessage({ id: 'COMMON.NO_DATA' })}</th></tr>)}
				</tbody>
			</table>
		</div >
	)
}

export { ProductsTable }
