import React, {FC} from "react";
import {useIntl} from "react-intl";
import AgentDetail from "./AgentDetail";
import {useParams} from "react-router-dom";
import { PageTitle } from "../../../../_metronic/layout/core";


const AgentDetailWrapper: FC = () => {
  const intl = useIntl();
  const {id} = useParams<{ id?: string }>();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.AGENT'}),
      path: '/agents',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]
  return <>
    <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({id: `${id ? 'COMMON.UPDATE': 'COMMON.CREATE'}`})}</PageTitle>
    <AgentDetail/>
  </>
}
export default AgentDetailWrapper;