/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import DashboardPage from "./DashboardPage";

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const breadcrumbs = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage/>
    </>
  )
}

export {
  DashboardWrapper
}
