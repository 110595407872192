import React, {FC} from 'react'
import {useIntl} from "react-intl";
import {PageTitle} from "../../../../_metronic/layout/core";
import {useLocation, useParams} from "react-router-dom";
import AddProducts from './AddProducts';

interface TLocation {
  order_code?: string;
}


const AddProductsWrapper: FC = () => {

  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Sản phẩm - Sales',
      path: '/sales-products',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]

  const {id} = useParams<{ id?: string }>();
  const location = useLocation<TLocation>();

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}>{id ? (location.state?.order_code ?? id) : intl.formatMessage({id: "COMMON.CREATE"})}</PageTitle>
        <AddProducts/>
    </>
  )
}

export default AddProductsWrapper
