import { FC, useEffect, ChangeEvent, useState } from 'react'
import { useIntl } from 'react-intl'
import { PaginateModel } from '../../models/PaginateModel'
import { IAccount, TSearch } from '../../modules/auth/models/AccountModel'
import { DATA_PAGINATION_LENGTH } from '../../../_metronic/partials/pagination/PaginationLength'
import { useHistory } from 'react-router-dom'
import { Toast } from '../../modules/common/helper'
import * as AccountCRUD from './components/user/AccountCRUD'
import { FilterList } from './components/user/FilterList'
import AccountTable from './components/user/AccountsTable'
import Pagination from './components/user/Pagination'
import AccountsTableHeader from './components/user/AccountsTableHeader'
import ConfigModal from './ConfigModal'
import { HeaderModel, ModalModel } from '../../models/CommonModel'

const Account: FC = () => {
    const intl = useIntl()
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<IAccount>>()
    const [hideCols, setHideCols] = useState<string[]>([]);
    const [configModal, setConfigModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const intItSearch = {
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0],
        status: '1'
    }
    const [search, setSearch] = useState<TSearch>(intItSearch)

    useEffect(() => {
        document.title = 'Sento - Tài khoản';
    })

    useEffect(() => {
        getData()
    }, [])

    const getData = async (params?: TSearch) => {
        const { data: dataPaginate } = await AccountCRUD.getList(params)
        setDataPaginate(dataPaginate)
        setSearch(params ?? {})
    }

    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target
        setSearch({ ...search, [name]: value })
    }
    const handlePageChange = (data: any) => {
        getData({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getData({ ...search, page: 1, per_page: +e.target.value })
    }
    const handleSearch = () => {
        getData({ ...search })
    }

    const handleClearSearch = () => {
        setSearch(intItSearch)
        getData()
    }
    const handleDelete = async (id: number) => {
        try {
            await AccountCRUD.destroy(id)
            Toast.fire({
                icon: 'success',
                title: intl.formatMessage({ id: 'COMMON.SUCCESS_NOTY' }),
            })
        } finally {
            getData({ ...search, page: 1 })
        }
    }

    const header: HeaderModel[] = [
        {
            label: intl.formatMessage({ id: 'ACCOUNT.NAME' }),
            style: { minWidth: "150px" },
            field: 'name',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ACCOUNT.STORE_MANAGE' }),
            style: { minWidth: "150px" },
            field: 'store_name',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ACCOUNT.PHONE' }),
            style: { minWidth: "100px" },
            field: 'phone',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ACCOUNT.STATUS' }),
            style: { minWidth: "100px" },
            field: 'status',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ACCOUNT.ROLE' }),
            style: { minWidth: "250px" },
            field: 'roles',
            sortable: true
        }
    ]

    const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
        const currentSearch = { ...search };
        const sortParams: TSearch = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
        if (direction === 'none') {
            delete sortParams.sort_by;
            delete sortParams.sort_direction;
        }
        getData({ ...sortParams });
    }

    const handleChangeVisCols = (e: any) => {
        let hiddenCols = [...hideCols];
        if (!e.target?.checked) {
            setHideCols([...hiddenCols, e.target.name])
        } else {
            setHideCols(hiddenCols.filter((item) => item !== e.target.name))
        }
    }
    
    return (
        <div className='card'>
            <div className='card-header py-4'>
                <div className='col-md-12'>
                    <FilterList
                        handleChangeSearch={handleChangeSearch}
                        handleSearch={handleSearch}
                        handleClearSearch={handleClearSearch}
                        search={search}
                    />
                </div>
            </div>
            <div className='card-body'>
                <AccountsTableHeader meta={dataPaginate?.meta}></AccountsTableHeader>
                <AccountTable data={dataPaginate?.data ?? []} 
                    handleDelete={handleDelete} 
                    openColConfig={() => setConfigModal({ ...configModal, show: true })}
                    header={header}
                    hideCols={hideCols}
                    handleSort={handleSort}
                />
            </div>
            <div className='card-footer d-flex justify-content-between'>
                <Pagination
                    search={search}
                    handlePageLengthChange={handlePageLengthChange}
                    handlePageChange={handlePageChange}
                    dataPaginate={dataPaginate}
                />
            </div>
            <ConfigModal
                {...configModal}
                header={header}
                hideCols={hideCols}
                handleChangeVisCols={handleChangeVisCols}
                onClose={() => { setConfigModal({ ...configModal, show: false }) }}
            />
        </div>
    )
}
export default Account
