import React, {FC} from "react";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import AccountInfo from "./AccontInfo";


const AccountInfoWrapper: FC = () => {
  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Thông tin tài khoản</PageTitle>
      <AccountInfo />
    </>
  )
}
export default AccountInfoWrapper;