import { FC, useEffect } from 'react'
import { IStoreModel } from '../../../models/StoreModel'
import { useIntl } from 'react-intl'
import { OverlayTrigger, Tooltip } from 'react-bootstrap-v5'
import StoreInfo from './StoreInfo'
import moment from 'moment'
import { format } from 'path'
import { HeaderModel } from '../../../models/CommonModel'
import SortableColumn from '../../../components/Table/SortableColumn'
type Props = {
    className?: string
    data: Array<IStoreModel>
    handleDelete: (id: number) => void
    modalVisible: boolean
    toggleModal: (id?: number) => void
    selectedId?: number
    reloadData: boolean
    handleReloadData: () => void
    header: HeaderModel[],
    hideCols: string[],
    openColConfig: () => void,
    handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void
}

const StoreTable: FC<Props> = ({
    className,
    data,
    handleDelete,
    modalVisible,
    toggleModal,
    selectedId,
    reloadData,
    handleReloadData,
    header, hideCols, openColConfig, handleSort
}) => {
    const intl = useIntl()

    const getStatus = (status: any) => {
        return status == 1
            ? intl.formatMessage({ id: 'STORE.STATUS.ACTIVE' })
            : intl.formatMessage({ id: 'STORE.STATUS.INACTIVE' })
    }
    useEffect(() => {
        if (reloadData) {
            handleReloadData();
        }
    }, [reloadData, handleReloadData]);
    return (
        <div className='table-responsive'>
            {data?.length ? (
                <table className='table table-hover table-bordered table-striped border gy-7 gs-7'>
                    <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                            <th>
                                <i
                                    className="fa fa-cogs"
                                    style={{ cursor: 'pointer' }}
                                    onClick={openColConfig}
                                ></i>
                            </th>
                            {header.map((th, index) => {
                                if (!hideCols.includes(th.field)) {
                                    if (th.sortable) {
                                        return (
                                            <SortableColumn
                                                key={'th-' + index}
                                                sortBy={th?.field}
                                                onSortChange={handleSort}
                                                style={th?.style ?? {}}
                                            >
                                                {th.label}
                                            </SortableColumn>
                                        )
                                    }
                                    return (
                                        <th style={th?.style ?? {}}>{th.label}</th>
                                    )
                                }
                            })}
                            <th style={{ minWidth: "100px" }} className='text-center'>{intl.formatMessage({ id: 'COMMON.ACTION_TEXT' })}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item: IStoreModel, index: number) => {
                            return (
                                <tr key={`store_${index}`} className='cursor-pointer text-wrap'>
                                    <td></td>
                                    {!hideCols.includes('name') && (
                                    <td style={{ maxWidth: '200px' }} >{item.name}</td>
                                    )}
                                    {!hideCols.includes('email') && (
                                    <td className='text-left'>{item?.email}</td>
                                    )}
                                    {!hideCols.includes('phone') && (
                                    <td className='text-left'>{item?.phone}</td>
                                    )}
                                    {!hideCols.includes('address') && (
                                    <td style={{ maxWidth: '200px' }} className='text-left'>{item?.address}</td>
                                    )}
                                    {!hideCols.includes('status') && (
                                    <td className='text-left'>
                                        {getStatus(item?.status)}<br />
                                        {moment(item?.expired_at).format('DD-MM-YYYY')}
                                    </td>
                                    )}
                                    <td className='text-center text-center'>
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip id='tooltip-create' className='tooltip'>
                                                    {intl.formatMessage({ id: 'COMMON.UPDATE' })}
                                                </Tooltip>
                                            }
                                        >
                                            <button
                                                className='btn btn-icon btn-light-warning w-30px h-30px ps-2 me-2'
                                                onClick={() => toggleModal(item?.id)}
                                            >
                                                <i className='bi bi-pencil-square fs-4 me-2'></i>
                                            </button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip id='tooltip-delete' className='tooltip'>
                                                    {intl.formatMessage({ id: 'COMMON.DELETE' })}
                                                </Tooltip>
                                            }
                                        >
                                            <button
                                                className='btn btn-icon btn-light-primary w-30px h-30px'
                                                type='button'
                                                onClick={() => handleDelete(item?.id)}
                                            >
                                                <i className='bi bi-trash'></i>
                                            </button>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            ) : (
                <div className='text-center'>{intl.formatMessage({ id: 'COMMON.NO_DATA' })}</div>
            )}

            <StoreInfo
                modalVisible={modalVisible}
                toggleModal={toggleModal}
                selectedId={selectedId}
                reloadData={reloadData}
                handleReloadData={handleReloadData}
            />
        </div>
    )
}

export default StoreTable
