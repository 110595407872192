import axios from "axios";
import {ADMIN_DASHBOARD_ENDPOINT} from "../../constants/endpoints";
import {IOrderReportSearch, IResponseModel} from "../../models/DashboardModel";

export function getReport(params: IOrderReportSearch){
  return axios.get<IResponseModel>(`${ADMIN_DASHBOARD_ENDPOINT}/order`, {params});
}

export function getReportAbilities(params: IOrderReportSearch){
  return axios.get<IResponseModel>(`${ADMIN_DASHBOARD_ENDPOINT}/li-abilities`, {params});
}

export function getTotal(params: IOrderReportSearch) {
  return axios.get<IResponseModel>(`${ADMIN_DASHBOARD_ENDPOINT}/get-total`, {params});
}