import React, {FC} from 'react'
import {useIntl} from "react-intl";
import PaymentVoucher from './AgencyDebt';
import { PageTitle } from '../../../../../_metronic/layout/core';

const AgencyDebtWrapper: FC = () => {

  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Công nợ đại lý</PageTitle>
      <PaymentVoucher />
    </>
  )
}

export default AgencyDebtWrapper
