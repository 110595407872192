/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { IOrderProduct } from '../../models/OrderModel'

type Props = {
    show: boolean
    mode: string
    data?: any
    onClose: () => void
    handleAddTocart: (data: IOrderProduct, qty: number) => void
}

const QuantityModal: React.FC<Props> = ({ show, data, onClose, handleAddTocart }) => {
    const [qty, setQty] = useState(1);
    return (
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header className='d-flex'>
                <Modal.Title>Sản phẩm: {data?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-10">
            <form>
                <div className="col-12">
                    <label>Nhập số lượng</label>
                    <input type='number' value={qty} className='form-control' onChange={(e) => setQty(parseInt(e.target.value))}/>
                </div>
                <div className='d-flex mb-4 justify-content-center pt-8'>
                    <button className="btn btn-bg-primary" type="button" onClick={() => handleAddTocart(data, qty)}>Lưu</button>
                    <button className="btn btn-bg-secondary" type="button" onClick={() => onClose()}>Tắt</button>
                </div>
            </form>
            </Modal.Body>
        </Modal>
    )
}

export default QuantityModal
