import React, {FC} from 'react'
import {useIntl} from "react-intl";
import { PageTitle } from '../../../../_metronic/layout/core';
import SupplierDebt from './SupplierDebt';

const SupplierDebtWrapper: FC = () => {

  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Công nợ nhà cung cấp</PageTitle>
      <SupplierDebt />
    </>
  )
}

export default SupplierDebtWrapper
