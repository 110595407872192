import axios from "axios";
import {IReceipt, ISearchReceipt} from "../../../models/WareHouseModel";
import {PaginateModel} from "../../../models/PaginateModel";
import {ADMIN_RECEIPT_ENDPOINT} from "../../../constants/endpoints";
import { ResponseModel } from "../../../models/ResponseModel";
import { ReceiptDetailModel, ReceiptDetailSearchModel } from "../../../models/ReceiptDetailModel";
export interface OrderListModel {
  paginate_data: PaginateModel<IReceipt>,
  sub_total: number
}

export function getList(params?: ISearchReceipt) {
  return axios.get<OrderListModel>(`${ADMIN_RECEIPT_ENDPOINT}`, {params})
}

export function removeReceipt(ids?: number[]) {
  return axios.delete<ResponseModel>(`${ADMIN_RECEIPT_ENDPOINT}/destroy`, {params: {ids}})
}
export function exportCSV(params?: ISearchReceipt) {
  return axios.get(`${ADMIN_RECEIPT_ENDPOINT}/export`, {params, responseType: 'blob'})
}

export function exportPdf(params: { id: number }) {
  return axios.post(`${ADMIN_RECEIPT_ENDPOINT}/export-pdf`, params)
}


export function searchProduct(params: ReceiptDetailSearchModel) {
  return axios.get<PaginateModel<ReceiptDetailModel>>(`${ADMIN_RECEIPT_ENDPOINT}/find-receipt-product`, {params})
}

export function exportSearchProduct(params: ReceiptDetailSearchModel) {
  return axios.get(`${ADMIN_RECEIPT_ENDPOINT}/export-product-search`, {params, responseType: 'blob'})
}
