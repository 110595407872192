/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
    from: number
    to: number
    total: number
}


const PaginationHeader: React.FC<Props> = ({
    from,to, total
}) => {
  return (
    <p>
        Hiển thị {from || 0} đến {to || 0} trong {total || 0} bản ghi
    </p>
  )
}

export {PaginationHeader}
