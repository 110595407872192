import axios from "axios";
import {ADMIN_AGENT_ENDPOINT, ADMIN_RECEIPT_ENDPOINT, ADMIN_WAREHOUSE_ENDPOINT} from "../../../../constants/endpoints";
import {ResponseModel} from "../../../../models/ResponseModel";
import {
  IProductWareHouse,
  IReceipt,
  ISearchProduct, ISearchSupplier, ISupplier
} from "../../../../models/WareHouseModel";
import {PaginateModel} from "../../../../models/PaginateModel";
import { ISearch } from "../../../../models/AgentProduct";
import { Product4ReceiptModel } from "../../../../models/ReceiptlModel";

export function getDetail(id?: string) {
  return axios.get<ResponseModel>(`${ADMIN_RECEIPT_ENDPOINT}/${id}`)
}

export function getListProducts(params?: ISearchProduct) {
  return axios.get<PaginateModel<IProductWareHouse>>(`${ADMIN_WAREHOUSE_ENDPOINT}/products`, {params})
}

export function getSuppliers(params?: ISearchSupplier) {
  return axios.get<PaginateModel<ISupplier>>(`${ADMIN_WAREHOUSE_ENDPOINT}/suppliers`, {params})
}

export function addReceipt(items: Product4ReceiptModel[]) {
  return axios.post<ResponseModel>(`${ADMIN_RECEIPT_ENDPOINT}/manual-create`, {items: items})
}

export function getProductList(params: ISearch) {
  return axios.get(`${ADMIN_AGENT_ENDPOINT}/products`, {params})
}

export function approveReceipt(id: string) {
  return axios.post<ResponseModel>(`${ADMIN_RECEIPT_ENDPOINT}/approve/${id}`)
}

export function updateReceipt(id: number, receipt: IReceipt) {
  return axios.put<ResponseModel>(`${ADMIN_RECEIPT_ENDPOINT}/${id}`, receipt)
}

export function exportPdf(params: { id: number, receipt_type?: number }) {
  return axios.post(`${ADMIN_RECEIPT_ENDPOINT}/export-pdf`, params)
}