import {IAgentProduct} from "../models/AgentProduct";

const getReceiveDiscount = (item: IAgentProduct, isRound = true) => {
  if (!item) {
    return 0;
  }
  const {market_price: marketPrice, sale_price: price} = item
  if (!marketPrice) {
    return 0;
  }
  const result = (marketPrice - price) / marketPrice * 100;
  return isRound ? Math.round(result) : result;
}
export default getReceiveDiscount;