import {FC} from "react";
import {ITotalReport} from "../../models/DashboardModel";
import {formatPrice} from "../../selectors";

type TProps = {
  total?: ITotalReport
}
const BlockReport: FC<TProps> = ({total}) => {
  return <div className="row">
    <div className="col-12 col-md-6 col-lg-3 mb-2">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Doanh thu ngày</h5>
          <h2 className="text-primary">{formatPrice(total?.revenue_day ?? 0)}</h2>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-3 mb-2">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Doanh thu tháng</h5>
          <h2 className="text-primary">{formatPrice(total?.revenue_month ?? 0)}</h2>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-3 mb-2">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            Đơn hàng ngày
          </h5>
          <h2 className="text-primary">{total?.order_day}</h2>
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 col-lg-3 mb-2">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            Đơn hàng tháng
          </h5>
          <h2 className="text-primary">{total?.order_month}</h2>
        </div>
      </div>
    </div>
  </div>

}
export default BlockReport;