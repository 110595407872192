/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import { useIntl } from 'react-intl';
import {Vietnamese} from "flatpickr/dist/l10n/vn.js"
import { ABILITIES_TYPES, RECIEPT_VOUCHER } from '../../../../constants/common';
import { PaginateModel } from '../../../../models/PaginateModel';
import { AbilityModel, SearchModel } from '../../../../models/AbilityModel';
import { HeaderModel, ModalModel } from '../../../../models/CommonModel';
import * as AbilitiesCRUD from '../../../abilities/AbilitiesCRUD';
import PaymentVoucherTable from '../../../abilities/agency/PaymentVoucherTable';

type Props = {
    show: boolean
    data?: any
    mode: string
    onClose: () => void
}
const AgencyDebtModal: React.FC<Props> = ({ show, data, onClose }) => {
    const intl = useIntl();
    const type: any = ABILITIES_TYPES.find((e) => {
        return e.label == RECIEPT_VOUCHER
    });
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<AbilityModel>>();
    const [hideCols, setHideCols] = useState<string[]>([]);
    const [configModal, setConfigModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const initSearch = {
        type: type?.id,
        per_page: 1000000
    }
    const fp = useRef<any>(null);
    const [search, setSearch] = useState<SearchModel>(initSearch)
    useEffect(() => {
        getData({...search, supplier_id: data?.supplier?.id});
    }, [data]);

    const getData = async (params?: SearchModel) => {
        const { data: resData } = await AbilitiesCRUD.getList({...params, agency_id: data?.agency?.id});
        setDataPaginate(resData?.paginate_data);
        setSearch(params ?? {});
    }

    const handleSearch = (e: any) => {
        e.preventDefault();
        getData({ ...search, page: 1 })
    }

    const handleClearSearch = (e: any) => {
        e.preventDefault();
        setSearch(initSearch);
        if (fp.current?.flatpickr) {
            fp.current.flatpickr.clear();
        }
        getData(initSearch)
    }
    
    const header: HeaderModel[] = [
        {
            label: intl.formatMessage({ id: 'ABILITIES.DATE' }),
            style: { minWidth: "150px" },
            field: 'date',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ABILITIES.PAYER' }),
            style: { minWidth: "150px" },
            field: 'name',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ABILITIES.DESCRIPTION' }),
            style: { minWidth: "250px" },
            field: 'note',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'ABILITIES.RECEIVE_AMOUNT' }),
            style: { minWidth: "250px" },
            field: 'value',
            sortable: true
        }
    ]

    const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
        const currentSearch = { ...search };
        const sortParams: SearchModel = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
        if (direction === 'none') {
            delete sortParams.sort_by;
            delete sortParams.sort_direction;
        }
        getData({ ...sortParams });
    }

    const handleChangeVisCols = (e: any) => {
        let hiddenCols = [...hideCols];
        if (!e.target?.checked) {
            setHideCols([...hiddenCols, e.target.name])
        } else {
            setHideCols(hiddenCols.filter((item) => item !== e.target.name))
        }
    }
    return (
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
            size='xl'
        >
            <Modal.Header className='d-flex'>
                <Modal.Title>{ data?.agency?.name }</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-10">
                <div className="card">
                    <div className="card-header py-4">
                        <div className="col-md-12">
                            <form onSubmit={handleSearch}>
                                <div className="row">
                                    <div className="col-md-2">
                                        <label className="form-label">Từ khóa</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="keyword"
                                            value={search.keyword || ''}
                                            onChange={(e) => setSearch({ ...search, keyword: e.target.value })}
                                            autoComplete='nope'
                                        />
                                    </div>
                                    <div className='col-md-3 mb-2'>
                                        <label className="form-label">Từ ngày</label>
                                        <Flatpickr
                                            options={{
                                                dateFormat: "d-m-Y",
                                                locale: Vietnamese,
                                                shorthandCurrentMonth: true
                                            }}
                                            onChange={(date) => {
                                                let startDate = moment(date[0]).format('YYYY-MM-DD');
                                                let sDate = search.date ?? {startDate: "", endDate: ""};
                                                sDate.startDate  = startDate;
                                                setSearch({ ...search, date: sDate });
                                            }}
                                            className='form-control'
                                            placeholder=''
                                        />
                                    </div>
                                    <div className='col-md-3 mb-2'>
                                        <label className="form-label">Tới ngày</label>
                                        <Flatpickr
                                            options={{
                                                dateFormat: "d-m-Y",
                                                locale: Vietnamese,
                                                shorthandCurrentMonth: true
                                            }}
                                            onChange={(date) => {
                                                let endDate = moment(date[0]).format('YYYY-MM-DD');
                                                let sDate = search.date ?? {startDate: "", endDate: ""};
                                                sDate.endDate  = endDate;
                                                setSearch({ ...search, date: sDate });
                                            }}
                                            className='form-control'
                                            placeholder=''
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <button
                                                className="col-auto btn btn-primary mt-8 me-2"
                                                onClick={handleSearch}
                                            >
                                                <i className="bi bi bi-search fs-4"></i>
                                            </button>
                                            <button
                                                className="col-auto btn btn-danger mt-8 me-2"
                                                type="button"
                                                onClick={handleClearSearch}
                                            >
                                                <i className="bi bi-eraser-fill fs-1"></i>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="card-body">
                        
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AgencyDebtModal
