import { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PageTitle } from '../../../_metronic/layout/core';
import { useIntl } from 'react-intl';
import GrantAccess from './GrantAccess';

const AccountWrapper: FC = () => {
  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: 'MENU.ROLE' }),
      path: '/accounts/roles',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <Switch>
      <Route path='/accounts/roles' exact>
        <PageTitle breadcrumbs={breadcrumbs}>
          {intl.formatMessage({id: 'MENU.ROLE'})}
        </PageTitle>
        <GrantAccess />
      </Route>
      <Redirect from='/accounts/roles' to='/accounts/roles' />
    </Switch>
  );
};

export default AccountWrapper;
