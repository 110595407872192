import React, {FC} from "react";
import {useIntl} from "react-intl";
import {ErrorMessage, Field, useFormikContext} from "formik";
import { IAgent } from "../../../models/AgentModel";

const AgentBasic: FC = () => {
  const intl = useIntl();
  const {values} = useFormikContext<IAgent>();

  return <>
    <div className="card mt-5">
      <div className="card-header py-4 height-unset">
        <h5 className="mb-0">{intl.formatMessage({id: 'AGENT.CONTACT_INFO'})}</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-6 mb-2">
            <div className="row">
              <label htmlFor="label"
                     className="col-md-4 col-form-label">{intl.formatMessage({id: 'AGENT.LABEL'})}</label>
              <div className="col-md-8">
                <Field
                  type="text"
                  className="form-control"
                  value={values.label ?? ""}
                  id="label"
                  name="label"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-2">
            <div className="row">
              <label htmlFor="location"
                     className="col-md-4 col-form-label">{intl.formatMessage({id: 'AGENT.LOCATION'})}</label>
              <div className="col-md-8">
                <Field
                  type="text"
                  className="form-control"
                  value={values.location ?? ""}
                  id="location"
                  name="location"
                />
              </div>
            </div>
          </div>
          <div className="col-12 mb-2">
            <div className="row">
              <label htmlFor="address"
                     className="col-md-2 col-form-label required">{intl.formatMessage({id: 'AGENT.ADDRESS'})}</label>
              <div className="col-md-10">
                <Field
                  type="text"
                  className="form-control"
                  value={values.address ?? ""}
                  id="address"
                  name="address"
                />
                <ErrorMessage name='address' className='text-danger' component='span'/>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </>
}
export default AgentBasic