import React, {FC, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import _, {includes} from "lodash";
import { OrderDetailModel } from "../../../../models/OrderModel";
import { useParams } from "react-router-dom";
import { HeaderModel, ModalModel } from "../../../../models/CommonModel";
import { formatPrice } from "../../../../selectors";
import SortableColumn from "../../../../components/Table/SortableColumn";
import ConfigModal from "../../../../components/Modal/ConfigModal";

type TProps = {
  products?: Array<OrderDetailModel>,
  setProducts: any,
  handleCheckbox?: (id: number) => void,
  handleCheckAll?: (e: any) => void,
  arrDelete?: number[],
  canEdit?: boolean,
  handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void;
  hideCols: string[],
  setHideCols: (cols: string[]) => void
}

const OrderDetailTable: FC<TProps> = ({
                                    products,
                                    setProducts,
                                    handleCheckbox,
                                    handleCheckAll,
                                    arrDelete,
                                    canEdit,
                                    handleSort,
                                    hideCols,
                                    setHideCols
                                  }) => {
  const intl = useIntl();
  const {id} = useParams<{ id?: string }>();

  const [configModal, setConfigModal] = useState<ModalModel>({
    show: false,
    mode: 'add',
  });

  const headers: HeaderModel[] = [
    {
      label: 'Image',
      field: 'image_path',
      sortable: false,
      style: {maxWidth: "120px", minWidth: "120px"}
    },
    {
      label: intl.formatMessage({id: 'PRODUCTS.NAME'}),
      field: 'inventory_name',
      sortable: true,
      style: {maxWidth: "280px", minWidth: "280px"}
    },
    {
      label: intl.formatMessage({id: id ? 'PRODUCTS.ORDER_QUANTITY' : 'PRODUCTS.QUANTITY'}),
      field: 'quantity',
      sortable: false,
      style: {maxWidth: "100px", minWidth: "100px"}
    },
    {
      label: 'Đơn giá',
      field: 'unit_price',
      sortable: true,
      style: {maxWidth: "180px", minWidth: "180px"}
    },
    {
      label: intl.formatMessage({id: 'PRODUCTS.VAT'}),
      field: 'vat',
      sortable: true,
      style: {maxWidth: "120px", minWidth: "120px"}
    }, {
      label: intl.formatMessage({id: 'ORDER.PRICE'}),
      field: 'sub_total',
      sortable: false,
      style: {maxWidth: "220px", minWidth: "220px"}
    }
  ];

  const handleChange = (e: any, item: OrderDetailModel) => {
    if (products && e.target.name) {
      let prods: OrderDetailModel[] = [...products]
      let findIndex = _.findIndex(prods, function (el: OrderDetailModel) { return el.id == item.id});
      if (findIndex >= 0 && e.target.name == 'new_quantity') {
        prods[findIndex]['new_quantity'] = e.target.value;
        setProducts([...prods]);
      }
    }
  }

  const arrCanEdit = canEdit ? products : [];
  const isCheckedAll = useMemo(() => arrDelete && arrDelete?.length > 0 && arrDelete?.length === arrCanEdit?.length, [arrDelete?.length])
  const isChecked = (id: number) => includes(arrDelete, id);

  const handleChangeVisCols = (e: any) => {
    let hiddenCols = [...hideCols];
    if (!e.target?.checked) {
      setHideCols([...hiddenCols, e.target.name])
    } else {
      setHideCols(hiddenCols.filter((item) => item !== e.target.name))
    }
  }

  return <>
    <div className='table-responsive mt-5'>
      <table className={`table table-bordered border gy-7 gs-7`}>
        <thead>
        <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
          <th>
            <i
              className="fa fa-cogs"
              style={{cursor: 'pointer'}}
              onClick={() => setConfigModal({...configModal, show: true})}
            ></i>
          </th>
          {canEdit &&
            <th>
              <input key={`checked_all_${isCheckedAll}`} className="form-check-input" type="checkbox"
                     checked={isCheckedAll}
                     onChange={handleCheckAll}/>
            </th>
          }
          {headers.map((th, index) => {
            if (!hideCols.includes(th.field)) {
              if (th.sortable) {
                return (
                  <SortableColumn
                    key={'th-' + index}
                    sortBy={th?.field}
                    onSortChange={handleSort}
                    style={th?.style ?? {}}
                  >
                    {th.label}
                  </SortableColumn>
                )
              }
              return (
                <th style={th?.style ?? {}}>{th.label}</th>
              )
            }
          })}
        </tr>
        </thead>
        <tbody>
        {products?.map((item: OrderDetailModel, index: number) => {
          const checked = isChecked(item.id);
          return (
            <tr key={`order_product${index}`} className={`cursor-pointer`}>
              <td></td>
              {canEdit &&
                <td>
                    <div className="form-check form-check-custom">
                      <input key={`item_checkbox_${item.id}_${checked}`} className="form-check-input" type="checkbox"
                             value={item.id}
                             defaultChecked={checked}
                             onChange={() => handleCheckbox && handleCheckbox(item.id)}/>
                    </div>
                </td>
              }
              {!hideCols.includes('image_path') && (
								<td>
									{item.inventory_of_sento && item.inventory_of_sento.image_path != '' ? (
										<img src={item.inventory_of_sento.image_path} alt="Preview" style={{ maxWidth: "100%" }} />
									) : (
										'No image'
									)}
								</td>
								)}
              {!hideCols.includes('inventory_name') &&
                <td>
                  {item.inventory_name}
                </td>
              }
              {!hideCols.includes('quantity') &&
                <td>
                  {canEdit ?
                    <>
                      <div className="input-group input-group-sm mb-3">
                        <input className="form-control form-control-sm" key={`quantity_${item.id}`}
                              min={0} defaultValue={item.quantity}
                              name="new_quantity"
                              onChange={(e) => handleChange(e, item)}
                              onBlur={(e) => handleChange(e, item)}/>
                        <span className="input-group-text text-sm-center">{item.unit || ""}</span>
                      </div>
                    </>
                    : `${item.quantity} ${item?.unit || ""}`
                  }
                </td>
              }
              {!hideCols.includes('unit_price') &&
                <td>
                  {formatPrice(item.unit_price)}
                </td>
              }
              {!hideCols.includes('vat') &&
                <td style={{width: '100px'}}>
                    {(item.vat ?? 0) + '%'}
                </td>
              }
              {!hideCols.includes('sub_total') &&
                <td key={`total_price_${item.id}`}><strong>{formatPrice(item.sub_total)}</strong></td>
              }
            </tr>
          )
        })}
        </tbody>
      </table>
    </div>
    <ConfigModal
      {...configModal}
      header={headers}
      hideCols={hideCols}
      handleChangeVisCols={handleChangeVisCols}
      onClose={() => {
        setConfigModal({...configModal, show: false})
      }}
    />
  </>
}
export default OrderDetailTable