import {FC, useEffect, ChangeEvent, useState} from 'react'
import {useIntl} from 'react-intl'
import {PaginateModel} from '../../models/PaginateModel'
import {IStoreModel, TSearch} from '../../models/StoreModel'
import {DATA_PAGINATION_LENGTH} from '../../../_metronic/partials/pagination/PaginationLength'
import {Toast} from '../../modules/common/helper'
import * as StoreCRUD from './StoreCRUD'
import {FilterList} from './components/FilterList'
import StoreTable from './components/StoreTable'
import Pagination from './components/Pagination'
import StoreTableHeader from './components/StoreTableHeader'
import {ModalProvider} from './components/ModalContext'
import { Sento } from '../../hooks/PermissionChecker'
import { usePermissionChecker, UserLoggedIn } from '../../hooks/PermissionChecker'
import { HeaderModel, ModalModel } from '../../models/CommonModel'
import ConfigModal from './ConfigModal'

const Store: FC = () => {
  const intl = useIntl()
  const [dataPaginate, setDataPaginate] = useState<PaginateModel<IStoreModel>>()
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedId, setSelectedId] = useState<number>()
  const [reloadData, setReloadData] = useState(false)
  const { hasPermission } = usePermissionChecker();
  const isSento = Sento();
  const user = UserLoggedIn();
  const intItSearch = {
    page: 1,
    per_page: DATA_PAGINATION_LENGTH[0],
    status: '1'
  }

  const [hideCols, setHideCols] = useState<string[]>([]);
  const [configModal, setConfigModal] = useState<ModalModel>({
      show: false,
      mode: 'add',
  });
  useEffect(() => {
		document.title = 'Sento - Danh sách cửa hàng';
	})

  const [search, setSearch] = useState<TSearch>(intItSearch)
  useEffect(() => {
    getData()
    setReloadData(false)
  }, [reloadData])
  
  const getData = async (params?: TSearch) => {
    try {
      if (!isSento) {
        // @ts-ignore
        const storeId = user?.store ? user?.store?.id : null
        if (storeId) {
          params = { ...params, store_id: storeId }
        } else {
          return null;
        }
      }
      const {data: dataPaginate} = await StoreCRUD.getList(params)
      setDataPaginate(dataPaginate)
      setSearch(params ?? {})
    } finally {
      setReloadData(false)
    }
  }

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    setSearch({...search, [name]: value})
  }

  const handlePageChange = (data: any) => {
    getData({...search, page: data.selected + 1})
  }

  const handlePageLengthChange = (e: any) => {
    getData({...search, page: 1, per_page: +e.target.value})
  }

  const handleSearch = () => {
    getData({...search})
  }

  const handleClearSearch = () => {
    setSearch(intItSearch)
    getData()
  }

  const handleDelete = async (id: number) => {
    try {
      await StoreCRUD.destroy(id)
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage({id: 'COMMON.SUCCESS_NOTY'}),
      })
    } finally {
      getData({...search, page: 1})
    }
  }

  const toggleModal = (id?: number) => {
    setModalVisible(!modalVisible)
    setSelectedId(id)
  }

  const handleReloadData = () => {
    setReloadData(true);
  };

  const header: HeaderModel[] = [
    {
        label: intl.formatMessage({ id: 'STORE.NAME' }),
        style: { minWidth: "150px" },
        field: 'name',
        sortable: true
    },
    {
        label: intl.formatMessage({ id: 'STORE.EMAIL' }),
        style: { minWidth: "150px" },
        field: 'email',
        sortable: true
    },
    {
        label: intl.formatMessage({ id: 'STORE.PHONE' }),
        style: { minWidth: "250px" },
        field: 'phone',
        sortable: true
    },
    {
        label: intl.formatMessage({ id: 'STORE.ADDRESS' }),
        style: { minWidth: "250px" },
        field: 'address',
        sortable: true
    },
    {
        label: intl.formatMessage({ id: 'STORE.STATUS' }),
        style: { minWidth: "250px" },
        field: 'status',
        sortable: true
    }
  ]

  const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
      const currentSearch = { ...search };
      const sortParams: TSearch = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
      if (direction === 'none') {
          delete sortParams.sort_by;
          delete sortParams.sort_direction;
      }
      getData({ ...sortParams });
  }

  const handleChangeVisCols = (e: any) => {
      let hiddenCols = [...hideCols];
      if (!e.target?.checked) {
          setHideCols([...hiddenCols, e.target.name])
      } else {
          setHideCols(hiddenCols.filter((item) => item !== e.target.name))
      }
  }

  return (
    <ModalProvider>
      <div className='card'>
        {isSento && (
          <div className='card-header py-4'>
          <div className='col-md-12'>
            <FilterList
              search={search}
              handleChangeSearch={handleChangeSearch}
              handleSearch={handleSearch}
              handleClearSearch={handleClearSearch}
            />
          </div>
        </div>
        )}
        <div className='card-body'>
          <StoreTableHeader 
            meta={dataPaginate?.meta} 
            toggleModal={toggleModal} 
            hasPermission={hasPermission}
          />
          <StoreTable
            data={dataPaginate?.data ?? []}
            handleDelete={handleDelete}
            modalVisible={modalVisible}
            toggleModal={toggleModal}
            selectedId={selectedId}
            reloadData={reloadData}
            handleReloadData={handleReloadData}
            openColConfig={() => setConfigModal({ ...configModal, show: true })}
            header={header}
            hideCols={hideCols}
            handleSort={handleSort}
          />
        </div>
        <div className='card-footer d-flex justify-content-between'>
          <Pagination
            search={search}
            handlePageLengthChange={handlePageLengthChange}
            handlePageChange={handlePageChange}
            dataPaginate={dataPaginate}
          />
        </div>
        <ConfigModal
            {...configModal}
            header={header}
            hideCols={hideCols}
            handleChangeVisCols={handleChangeVisCols}
            onClose={() => { setConfigModal({ ...configModal, show: false }) }}
        />
      </div>
    </ModalProvider>
  )
}

export default Store
