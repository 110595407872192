import {
  ADMIN_STORES_ENDPOINT
} from "../../../constants/endpoints";
import axios from "axios";
import { PaginateModel } from '../../../models/PaginateModel'
import {IStoreModel, TSearch} from "../../../models/StoreModel";

export function getList(params?: TSearch) {
  return axios.get<PaginateModel<IStoreModel>>(ADMIN_STORES_ENDPOINT, {params});
}



