import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import * as ReceiptCRUD from "./ReceiptCRUD";
import { IReceipt, IReceiptDetail} from "../../../../models/WareHouseModel";
import { Toast } from "../../../../modules/common/helper";
import ReceiptDetailTable from "./ReceiptDetailTable";
import { Form, Formik } from "formik";
import DetailInfo from "./DetailInfo";
import * as Yup from "yup";
import { openNewTabWithHtml } from "../../../../selectors";

const ViewDetail: FC = () => {
  const intl = useIntl()
  const { id } = useParams<{ id?: string }>();
  const [detail, setDetail] = useState<IReceipt>()
  const schema = Yup.object().shape({});

  useEffect(() => {
    document.title = 'Sento - Chi tiết đơn nhập';
  })

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    const { data } = await ReceiptCRUD.getDetail(id);
    setDetail(data.data);
  }

  const approveReceipt = async () => {
    try {
      if (!id) {
        return false;
      }
      const { data } = await ReceiptCRUD.approveReceipt(id)
      Toast.fire({
        icon: 'success',
        title: data?.message || intl.formatMessage({ id: 'COMMON.SUCCESS_NOTY' }),
      })
      getDetail();
    } catch (error: any) {
      Toast.fire({
        icon: 'error',
        title: error?.response?.data?.message,
      })
    }
  }

  const onChangeTable = () => {

  }
  const handleSubmit = async () => {
    try {
      if (detail && detail.id) {
        const { data } = await ReceiptCRUD.updateReceipt(detail.id, detail)
        Toast.fire({
          icon: 'success',
          title: data?.message || intl.formatMessage({ id: 'COMMON.SUCCESS_NOTY' }),
        })
        getDetail();
      }
    } catch (error: any) {
      Toast.fire({
        icon: 'error',
        title: error?.response?.data?.message,
      })
    }
  }
  
  const setReceiptDetails = (receiptDetails: IReceiptDetail[]) => {
    setDetail({...detail,  receipt_detail: receiptDetails})
  }

  const handleChangeDetail = (e: any) => {
    setDetail({...detail,  [e.target.name]: e.target.value})
  }

  const editable = detail?.status !== 'Duyệt';
  const handleCheckAll = (e: any) => {
    let receiptDetails = detail?.receipt_detail ?? [];
    let checkVal = e.target.checked ? 1 : 0;
    receiptDetails = receiptDetails.map(el => ({ ...el, checked: checkVal }));
    setDetail({...detail,  receipt_detail: receiptDetails})
  }

  const handleExportPdf = async () => {
      if (detail?.id) {
        const {data: resData} = await ReceiptCRUD.exportPdf({id: detail.id, receipt_type: 1})
        openNewTabWithHtml(resData);
      }
  }
  return (
    <Formik enableReinitialize validationSchema={schema}
      initialValues={{}} onSubmit={handleSubmit}>
      {({ values, handleChange }) => {
        return (
          <Form>
            <div className="card">
              <DetailInfo
                detail={detail}
                approveReceipt={approveReceipt}
                editable={editable}
                handleChangeDetail={handleChangeDetail}
                handleSubmit={handleSubmit}
                handleExportPdf={handleExportPdf}
              />
              <div className="card-body">
                <ReceiptDetailTable
                  products={detail?.receipt_detail ?? []}
                  editable={editable}
                  setReceiptDetails={setReceiptDetails}
                  handleCheckAll={handleCheckAll}
                />
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
export default ViewDetail