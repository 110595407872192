import {ADMIN_AGENT_ENDPOINT} from '../../constants/endpoints'
import {IAgent, ISearch} from '../../models/AgentModel'
import {PaginateModel} from '../../models/PaginateModel'
import axios from 'axios'
import {ResponseModel} from '../../models/ResponseModel'

export function getAgents(params?: ISearch) {
  return axios.get<PaginateModel<IAgent>>(`${ADMIN_AGENT_ENDPOINT}`, {params})
}

export function getAllAgents(params?: ISearch) {
  return axios.get<PaginateModel<IAgent>>(`${ADMIN_AGENT_ENDPOINT}/get-all`, {params})
}

export function removeAgents(ids?: number[]) {
  return axios.delete<ResponseModel>(`${ADMIN_AGENT_ENDPOINT}/destroy`, {params: {ids}})
}

export function exportAgent(params: ISearch) {
  return axios.get(`${ADMIN_AGENT_ENDPOINT}/export`, {params, responseType: 'blob'})
}

export function importAgent(params: FormData) {
  return axios.post(`${ADMIN_AGENT_ENDPOINT}/import`, params)
}

export function getListProducts(params: ISearch) {
  return axios.get(`${ADMIN_AGENT_ENDPOINT}/products`, {params})
}
