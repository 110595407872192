import React, {FC} from 'react'
import {useIntl} from "react-intl";
import { PageTitle } from '../../../../_metronic/layout/core';
import Agent from './Agent';

const AgentWrapper: FC = () => {

  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({id: 'MENU.AGENT'})}</PageTitle>
      <Agent />
    </>
  )
}

export default AgentWrapper
