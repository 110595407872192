import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {isEmpty} from 'lodash'
import {selectMasterData, setMaster} from '../../setup/redux/master'
import {IMaster} from '../modules/auth/models/MasterModel'
import axios from 'axios'
import {GET_MASTER_DATA} from '../constants/endpoints'

const initMasterData = {
  categories: [],
  units: [],
  quarranty: [],
  brands: [],
  suppliers: [],
  return_status: [],
  warranty_type: [],
  warranty_status: [],
  stores: [],
  group_supplier: []
}

const useMasterData = () => {
  const [masterData, setMasterData] = useState<IMaster>()
  const dispatch = useDispatch()
  const master: IMaster = useSelector(selectMasterData)

  const getMasterData = () => {
    return axios.get(GET_MASTER_DATA).then((res) => {
      return res
    })
  }

  useEffect(() => {
    const isMasterDataEmpty = Object.keys(master).every((key) => isEmpty((master as any)[key]))
    if (!isMasterDataEmpty) {
      setMaster(master ?? initMasterData)
      return
    }
    const fetchMasterData = async () => {
      try {
        const data = await getMasterData()
        setMasterData(data?.data ?? [])
        dispatch(setMaster(data?.data ?? []))
      } catch (error) {
        console.error('Error fetching master data:', error)
      }
    }

    fetchMasterData()
  }, [master])

  return {masterData, master}
}

export default useMasterData
