import {ADMIN_ACCOUNTS_ENDPOINT} from '../../../../constants/endpoints'
import axios from 'axios'
import { PaginateModel } from '../../../../models/PaginateModel'
import { IAccount, TSearch} from '../../../../modules/auth/models/AccountModel'
import { ResponseModel } from "../../../../models/ResponseModel"

export function getList(params?: TSearch) {
  return axios.get<PaginateModel<IAccount>>(ADMIN_ACCOUNTS_ENDPOINT, {params})
}

export function create(params: object) {
  return axios.post<ResponseModel>(ADMIN_ACCOUNTS_ENDPOINT, params)
}

export function getDetail(id?: string) {
  return axios.get<ResponseModel>(`${ADMIN_ACCOUNTS_ENDPOINT}/${id}`)
}
export function update(params: object, id?: string) {
  return axios.put(`${ADMIN_ACCOUNTS_ENDPOINT}/${id}`, params)
}

export function destroy(id: number) {
  return axios.delete<ResponseModel>(`${ADMIN_ACCOUNTS_ENDPOINT}/${id}`)
}
