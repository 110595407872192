import React, {FC, ChangeEvent, useEffect} from 'react'
import {IPermission} from '../../../../modules/auth/models/PermissionsModel'
import {UserPermission, hasPermission} from '../../../../modules/utils/UserPermissions '
import {IScreenModel} from '../../../../models/IScreenModel'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as RoleCRUD from './RoleCRUD'
import {Toast} from '../../../../modules/common/helper'
import clsx from 'clsx'
import * as Yup from 'yup'

interface PermissionComponentProps {
  listScreen: IScreenModel[]
  selectedRole: number | undefined
  selectedRolePermissions: IPermission[] | null
  modalVisible: boolean
  toggleModal: (id?: number) => void
  handleReloadData: () => void
  setSelectedRolePermissions: (permissions: IPermission[]) => void
  role: any
}

const PermissionComponent: FC<PermissionComponentProps> = ({
  listScreen,
  selectedRole,
  selectedRolePermissions,
  modalVisible,
  toggleModal,
  handleReloadData,
  setSelectedRolePermissions,
  role,
}) => {
  const permissionName = (permissionName: string): string => {
    const translations: Record<string, string> = {
      list: 'Xem',
      create: 'Tạo mới',
      update: 'Sửa',
      delete: 'Xóa',
    }
    const parts = permissionName.split('-')
    const lastPart = parts[parts.length - 1]
    return translations[lastPart] || lastPart
  }
  const intl = useIntl()
  const initialValues = {
    name: '',
  }
  const schema = Yup.object().shape({
    name: Yup.string().required('Vui lòng nhập địa chỉ'),
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        if (selectedRole) {
          await RoleCRUD.update(selectedRole, values)
        } else {
          await RoleCRUD.create(values)
        }
        Toast.fire({
          icon: 'success',
          title: intl.formatMessage({id: 'COMMON.SUCCESS_NOTY'}),
        })
        toggleModal()
        handleReloadData()
        setSelectedRolePermissions([])
      } catch (error: any) {
        if (error?.response && error?.response?.status === 422) {
          const validationErrors = error?.response?.data?.errors
          formik.setErrors(validationErrors)
        }
      } finally {
        setSubmitting(false)
      }
    },
  })
  useEffect(() => {
    formik.setFieldValue('name', role?.name || '')
  }, [role])

  useEffect(() => {
    formik.setFieldValue(
      'permissions',
      selectedRolePermissions?.map((p) => p.name) || []
    );
  }, [selectedRolePermissions]);

  const handleCheckedPermission = (e: any, permission: IPermission) => {
    const checked = e.target.checked;

    if (checked) {
      setSelectedRolePermissions([...(selectedRolePermissions || []), permission]);
    } else {
      setSelectedRolePermissions(
        selectedRolePermissions?.filter((p) => p.name !== permission.name) || []
      );
    }
  };
  return (
    <div className={clsx('store-modal', {'open': modalVisible})}>
        <div className='modal-content'>
          <form className='form w-100' onSubmit={formik.handleSubmit}> 
            <div className='row'>
              <div className='col-12 mb-3'>
                <label className='form-label' htmlFor='name'>
                  {intl.formatMessage({id: 'ROLE.NAME'})}
                </label>
                <input
                  {...formik.getFieldProps('name')}
                  id='name'
                  name='name'
                  type='text'
                  className={clsx(
                    'form-control form-control-lg',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  placeholder='Nhập tên vai trò'
                  autoComplete='off'
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                )}
              </div>
              <div className='fw-bold fs-5 border-bottom my-5'>Quyền hạn của vai trò</div>
              <div className='col-md-12'>
                <div className='permissions-container'>
                  {listScreen?.map((item) => (
                    <div key={item.id}>
                      <div className='col-12 mb-3 border-bottom d-flex justify-content-between'>
                        {item.permissions.map((p) => (
                          <div key={p.id} className='form-check my-2'>
                            <label className='form-check-label mx-2 fw-bold fs-4'>
                              {p.label}
                              <input
                                className='cursor-pointer form-check-input mx-2 fs-5'
                                type='checkbox'
                                checked={
                                  selectedRolePermissions
                                    ? hasPermission(selectedRolePermissions, p.name)
                                    : false
                                }
                                onChange={(e) => handleCheckedPermission(e, p)}
                              />
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='col-md-12 text-end'>
              <button type='submit' className='btn btn-primary'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-floppy'
                  viewBox='0 0 16 16'
                >
                  <path d='M11 2H9v3h2z' />
                  <path d='M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z' />
                </svg>{' '}
                Lưu
              </button>
              <button
                type='button'
                className='btn btn-secondary ms-2 '
                onClick={() => {
                  toggleModal()
                  document.querySelector('.store-modal')?.classList.add('modal-hidden')
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-x'
                  viewBox='0 0 16 16'
                >
                  <path
                    fillRule='evenodd'
                    d='M3.646 3.646a.5.5 0 0 1 .708 0L8 
                          7.293l3.646-3.647a.5.5 0 0 1 .708.708L8.707 
                          8l3.647 3.646a.5.5 0 0 1-.708.708L8 8.707l-
                          3.646 3.647a.5.5 0 0 1-.708-.708L7.293 
                          8 3.646 4.354a.5.5 0 0 1 0-.708z'
                  />
                </svg>{' '}
                Đóng
              </button>
            </div>
          </form>
        </div>
    </div>
  )
}

export default PermissionComponent
