import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap-v5';

interface Option {
  optionLabel: string;
  optionValue: string;
}

interface AddableSelectProps {
  options: Option[];
  name: string;
  value?: string | number;
  label?: string;
  id?: string;
  title?: string;
  emptyOption?: string;
  placeholder?: string;
  className?: string;
  handleSubmit?: (value: string) => Promise<void>;
  handleShowModal?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedValue?: string;
}

const SelectOptions: React.FC<{ options: Option[]; selectedValue: string | undefined }> = ({
  options,
  selectedValue,
}) => (
  <>
    <option value="" disabled hidden>
      Chọn một mục
    </option>
    {options.map((option, index) => (
      <option
        key={option.optionValue + index}
        value={option.optionValue}
      >
        {option.optionLabel}
      </option>
    ))}
  </>
);

const AddableSelect: React.FC<AddableSelectProps> = ({
  options,
  name,
  value = '',
  label = '',
  id = '',
  title = 'Tiêu đề',
  emptyOption = 'Chọn một mục',
  placeholder = '',
  className = '',
  handleSubmit,
  handleShowModal,
  onChange,
  selectedValue,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState<string>('');

  useEffect(() => {
    const selectedOption = options.find((option) => option.optionValue === selectedValue);
    setSelectedOptionLabel(selectedOption ? selectedOption.optionLabel : '');
  }, [selectedValue, options]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.(event);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <label className="form-label" htmlFor="name">
            {label}
          </label>
          <select
            name={name}
            value={selectedValue || value}
            className={`form-select mb-3${className ? ' ' + className : ''}`}
            multiple={false}
            onChange={handleOptionChange}
          >
            <SelectOptions options={options} selectedValue={selectedValue} />
          </select>
        </div>
      </div>
    </div>
  );
};

export default AddableSelect;
