import React, {FC, useRef} from "react";
import {useIntl} from "react-intl";
import _ from "lodash";
import { Product4ReceiptModel } from "../../../../models/ReceiptlModel";
import { Select2 } from "../../../../../_metronic/partials/select/Select2";
import { Selectoptions } from "../../../../models/CommonModel";
import { NumericFormat } from "react-number-format";
import { formatPrice, revertPriceNumner } from "../../../../selectors";
import { IReceiptDetail } from "../../../../models/WareHouseModel";

type TProps = {
  products?: IReceiptDetail[],
  editable?: boolean
  setReceiptDetails: (details: IReceiptDetail[]) =>  void
  handleCheckAll: (e: any) => void
}

const ReceiptDetailTable: FC<TProps> = ({
    products,
    editable,
    setReceiptDetails,
    handleCheckAll
  }) => {
  const intl = useIntl();

  const handleChange = (e: any, item: IReceiptDetail, revertNumber = false) => {
    let detailsProds = products ? products : [];
    let index = _.findIndex(products, {'id': item.id});
    let curProd = detailsProds[index];
    let name = e.target.name;
    let value = revertNumber ? revertPriceNumner(e.target.value) : e.target.value;
    if (e.target.type === 'checkbox') {
      value = e.target.checked ?  1 : 0;
    } else {
      curProd = {...curProd, checked: 1}
    }
    if (name === 'new_price') {
      let iOSuppliers:any = item.inventory_of_sento.inventory_of_supplier ?? {};
      let curSup = _.find(iOSuppliers, { supplier_id: item.supplier_id});
      let mkPrice = curSup.price_sell;
      let newDiscount = isNaN(value) ? 100 : calcDiscount(mkPrice, value);
      curProd = {...curProd, discount: newDiscount, price: value};
    }
    if (isNaN(value)) {
      return false;
    }

    curProd = {...curProd, [name]: value}
    detailsProds[index] = curProd
    setReceiptDetails([...detailsProds])
  }

  const calcDiscount = (marketPrice: any, sellPrice: any) => {
		return ((marketPrice - sellPrice) / marketPrice) * 100;
	}
	const calcPrice = (marketPrice: any, discount: any) => {
		return marketPrice - (marketPrice * discount / 100);
	}
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }
  
  const isCheckedAllF = () => {
    return products?.every(el => el.checked == 1);
  }
  const isCheckedAll = isCheckedAllF();
  return <>
    <div className='table-responsive mt-5'>
      <table className={`table table-bordered border gy-7 gs-7 `}>
        <thead>
        <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
            <th>
            <input key={`checked_all_${isCheckedAll}`} className="form-check-input" type="checkbox" role="button"
              value=""
              defaultChecked={isCheckedAll}
              onChange={(e: any) => handleCheckAll(e)} />
            </th>
            <th>{intl.formatMessage({id: 'WAREHOUSE.PRODUCT_NAME'})}</th>
            <th>{intl.formatMessage({id: 'WAREHOUSE.QUANTITY'})}</th>
            <th>{intl.formatMessage({id: 'WAREHOUSE.UNIT'})}</th>
            <th>{intl.formatMessage({id: 'WAREHOUSE.PRICE'})}</th>
            <th>{intl.formatMessage({id: 'WAREHOUSE.DISCOUNT'})}</th>
            <th>{intl.formatMessage({id: 'WAREHOUSE.STATUS'})}</th>
        </tr>
        </thead>
        <tbody>
        {products?.map((item: IReceiptDetail, index: number) => {
          let checked = item?.checked ? true : false;
          if (editable) {
            return (
              <tr key={`order_product${index}`} className={`cursor-pointer`}>
                <td>
                    <div className="form-check form-check-custom">
                      <input key={`item_checkbox_${item.id}_${checked}`} 
                        name="checked"
                        className="form-check-input" type="checkbox"
                          value={item.id}
                          defaultChecked={checked}
                          onChange={(e) => handleChange(e, item)}/>
                    </div>
                </td>
                <td>{item.inventory_name}</td>
                <td>
                  <input 
                    className="form-control form-control-sm" 
                    name="quantity"
                    type="number" key={`quantity_${item.id}`}
                    min={0} defaultValue={item.quantity}
                    onChange={(e) => handleChange(e, item)}
                    onBlur={(e) => handleChange(e, item)}
                  />
                </td>
                <td>{item.unit}</td>
                <td>
                  <NumericFormat
										thousandSeparator=","
										name='new_price'
										className='form-control'
										defaultValue={item.price ?? 0}
										onChange={(e) => {
											handleChange(e, item, true);
										}}
									/>
                </td>
                <td>{item.discount?.toFixed(2)}%</td>
                <td>{item.status}</td>
              </tr>
            )
          } else {
            return (
              <tr key={`order_product${index}`} className={`cursor-pointer`}>
                <td>
                </td>
                <td>{item.inventory_name}</td>
                <td>{item.quantity}</td>
                <td>{item.unit}</td>
                <td>{formatPrice(item.price)}</td>
                <td>{item.discount?.toFixed(2)}%</td>
                <td>{item.status}</td>
              </tr>
            )
          }
          
        })}
        </tbody>
      </table>
    </div>
  </>
}
export default ReceiptDetailTable