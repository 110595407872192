import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IMaster} from '../../../app/modules/auth/models/MasterModel'

interface ICommonState {
  masterData: IMaster
}

const initialState: ICommonState = {
  masterData: {
    categories: [],
    units: [],
    quarranty: [],
    brands: [],
    suppliers: [],
    refund_status: [],
    warranty_type: [],
    warranty_status: [],
    stores: [],
    group_supplier: [],
  },
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setMaster: (state, action: PayloadAction<IMaster>) => {
      state.masterData = action.payload
    },
  },
})

export const {setMaster} = commonSlice.actions

export const selectMasterData = (state: {common: ICommonState}) => state?.common?.masterData ?? []

export default commonSlice.reducer
