import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import {HeaderUserMenu} from '../../../partials'
import {UserModel} from "../../../../app/modules/auth/models/UserModel";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const history = useHistory();
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [hasCart, setHasCart] = useState<boolean>(false);
  useEffect(() => {
    checkHhasCart();
  }, [])
  const checkHhasCart = () => {
    try {
      let cartData = JSON.parse(localStorage.getItem('cart') || '[]');
      setHasCart(cartData && cartData.length > 0)
    } catch (e) {
      return setHasCart(false);
    }
  } 

  const clearCart = () => {
    localStorage.removeItem('cart');
    setHasCart(false);
    history.push("/orders");
  }
  return (
    <div className='d-flex align-items-center flex-shrink-0'>
      {/* begin::User */}
      {hasCart && (
        <div>
          <Link to={'/orders/detail?createFromCart=1'} className='btn btn-primary' title='Tạo đơn'><i className='fa fa-shopping-cart'></i></Link>
          <span onClick={clearCart} className='btn btn-warning ms-2' title='Xóa giỏ hàng'><i className='fa fa-trash'></i></span>
        </div>
      )}
      
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <div className="symbol symbol-50px">
            <div className="symbol-label fs-2 fw-bold text-primary">{user.user_name?.[0] ?? ''}</div>
          </div>
        </div>
        <HeaderUserMenu/>
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  )
}

export {Topbar}
