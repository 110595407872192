/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  selected: number,
  onChange: (e: any) => void
}

const DATA_PAGINATION_LENGTH = [10, 20, 50]

const PaginationLength: React.FC<Props> = ({
  selected,
  onChange
}) => {
  return (
    <div className="d-flex align-items-center">
      <span className="me-2" style={{width: "50px"}}>Hiển thị</span>
      <select style={{width: "65px"}} className="form-select form-select-sm" onChange={(e) => onChange(e)} value={selected || DATA_PAGINATION_LENGTH[0]}>
        {DATA_PAGINATION_LENGTH.map((item: number) => (
          <option key={`pagination-length-${item}`} value={item}>{item}</option>
        ))}
      </select>
      <span className="ms-2">mục</span>
    </div>
  )
}

export {PaginationLength, DATA_PAGINATION_LENGTH}
