/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Modal } from 'react-bootstrap-v5'
import { HeaderModel } from '../../models/CommonModel'
import NormalCustomCheckbox from '../../components/Control/NormalCustomCheckbox'

type Props = {
    show: boolean
    mode: string
    data?: any
    header: HeaderModel[]
    onClose: () => void
    hideCols: string[],
    handleChangeVisCols: (e: any) => void 
}

const ConfigModal: React.FC<Props> = ({ show, mode, data, header, onClose, hideCols, handleChangeVisCols }) => {
    return (
        <Modal
            show={show}
            onHide={onClose}
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header className='d-flex'>
                <Modal.Title>Tùy chọn hiển thị</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-10">
            <form>
                <div className="col-12">
                    {header.map((item, index) => {
                        let checkedStatus = true;
                        if (hideCols.includes(item.field)) {
                            checkedStatus = false
                        }
                        return (
                            <NormalCustomCheckbox 
                                key={'col-'+ item.field} 
                                name={item.field} 
                                label={item.label}
                                checked={checkedStatus}
                                handleChange={handleChangeVisCols}
                            />
                        )
                    })}
                </div>
                <div className='d-flex mb-4 justify-content-center pt-8'>
                    <button className="btn btn-bg-secondary" type="button" onClick={() => onClose()}>Tắt</button>
                </div>
            </form>
            </Modal.Body>
        </Modal>
    )
}

export default ConfigModal
