import { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { IReceipt } from "../../../../models/WareHouseModel";
import { Field } from "formik";


type DetailProps = {
  detail?: IReceipt
  approveReceipt: () => void
  editable?:boolean
  handleChangeDetail: (e: any) => void
  handleSubmit: () => void
  handleExportPdf: () => void
}

const DetailInfo: FC<DetailProps> = ({detail, approveReceipt, editable, handleChangeDetail, handleSubmit, handleExportPdf}) => {
  const intl = useIntl()

  useEffect(() => {
  })

  useEffect(() => {
  
  }, []);
 
  return (
    <div className="card-header py-4">
      <div className="col-md-12">
        <div className="row g-3 align-items-center">
          <div className="col-6 col-lg-2">
            <label htmlFor="inputPassword6"
              className="col-form-label">{intl.formatMessage({ id: 'WAREHOUSE.RECEIPT_CODE' })}</label>
          </div>
          <div className="col-6 col-lg-4">
            <strong>{detail?.receipt_code}</strong>
          </div>
          <div className="col-6 col-lg-2">
            <label htmlFor="inputPassword6"
              className="col-form-label">{intl.formatMessage({ id: 'WAREHOUSE.SUPPLIER' })}</label>
          </div>
          <div className="col-6 col-lg-4">
            <strong>{detail?.supplier?.name || ''}</strong>
          </div>
          <div className="col-12">
            <label htmlFor="inputPassword6"
              className="col-form-label">{intl.formatMessage({ id: 'WAREHOUSE.NOTE' })}</label>
          </div>
          <div className="col-12">
          {editable ? (
            <Field
                as='textarea'
                className="form-control"
                id="note"
                name="note"
                value={detail?.note || ''}
                onChange={(e: any) => handleChangeDetail(e)}
              />
            ) : (
              <span>{ detail?.note || '' }</span>
            )}
          </div>
          {editable ? (
            <>
              <div className="col-12"></div>
              <div className="col-3 col-lg-1">
                <button className="btn btn-primary" type="button" onClick={() => handleSubmit()}>Lưu</button>
              </div>
              <div className="col-3 col-lg-1">
                <button className="btn btn-success" type="button" onClick={() => approveReceipt()}>Duyệt</button>
              </div>
            </>
          ) : (
            <>
              <div className="col-6 col-lg-2">
                <label htmlFor="inputPassword6"
                  className="col-form-label">{intl.formatMessage({ id: 'WAREHOUSE.STATUS' })}</label>
              </div>
              <div className="col-6 col-lg-4">
                <strong>{detail?.status || ''}</strong>
              </div>
              
            </>
          )}
            
            <div className="col-3 col-lg-1">
            <button className="btn btn-primary me-3"
              type="button"
              onClick={() => handleExportPdf()}>{intl.formatMessage({id: 'ORDER.PRINT'})}
            </button>
            </div>
            <div className="col-12">
              <Link to='/warehouse/receipt/detail' className="btn btn-primary">Quay lại màn hình nhập</Link>
            </div>
        </div>
      </div>
    </div>
  )
}
export default DetailInfo