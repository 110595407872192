import React, {FC, useRef} from "react";
import {useIntl} from "react-intl";
import _ from "lodash";
import { Product4ReceiptModel } from "../../../../models/ReceiptlModel";
import { Select2 } from "../../../../../_metronic/partials/select/Select2";
import { Selectoptions } from "../../../../models/CommonModel";
import { NumericFormat } from "react-number-format";
import { revertPriceNumner } from "../../../../selectors";

type TProps = {
  products?: Product4ReceiptModel[],
  placedProducts: any,
  handleCheckbox?: (id: number) => void,
  handleCheckAll?: (e: any) => void,
  onChangeTable: (item: Product4ReceiptModel, index?: number) => void
}

const ProductTable: FC<TProps> = ({
    products,
    handleCheckbox,
    handleCheckAll,
    placedProducts,
    onChangeTable
  }) => {
  const intl = useIntl();
  const discountRef = useRef<HTMLInputElement[]>([]);
	const priceRef = useRef<HTMLInputElement[]>([]);

  const handleChange = (e: any, item: Product4ReceiptModel, revertNumber = false) => {
    let sItem = _.find(placedProducts, (e) => e.id == item.id);

		if (!sItem) {
			sItem = item
		}
		let val = e.target.value;
    if (revertNumber) {
      val = revertPriceNumner(val);
    }
		if (e.target.type == 'checkbox') {
			val = e.target.checked ? 1 : 0;
		}

    if (e.target.name == 'best_supplier.new_price') {
			let bestSupplier = item?.best_supplier
			let itemId = bestSupplier?.id ?? 1;
			let newDiscount = calcDiscount(bestSupplier?.price_sell, val).toFixed(2);
			let dcName = 'best_supplier.new_discount'
			sItem = { ...sItem, [dcName]: newDiscount }
			let dcRef = discountRef.current[parseInt(itemId.toString())];
			if (dcRef) {
				dcRef.value = newDiscount.toString();
			}
		}

		if (e.target.name == 'best_supplier.new_discount') {
			let bestSupplier = item?.best_supplier;
			let itemId = bestSupplier?.id ?? 1;
			let newPrice = calcPrice(bestSupplier?.price_sell, val);
			let stPriceName = 'best_supplier.new_price'
			sItem = { ...sItem, [stPriceName]: newPrice }
			let dcRef = priceRef.current[parseInt(itemId.toString())];
			if (dcRef) {
				dcRef.value = newPrice.toString();
			}
		}

		sItem = { ...sItem, [e.target.name]: val }
		onChangeTable(sItem);
  }

  const handleChangeSupplier = (iSupplier: any, item: Product4ReceiptModel) => {
    let sItem = _.find(placedProducts, (e) => e.id == item.id); 
    if (!sItem) {
			sItem = item
		}

    sItem = { ...sItem, new_supplier: iSupplier }

    onChangeTable(sItem);
  }
  const calcDiscount = (marketPrice: any, sellPrice: any) => {
		return ((marketPrice - sellPrice) / marketPrice) * 100;
	}
	const calcPrice = (marketPrice: any, discount: any) => {
		return marketPrice - (marketPrice * discount / 100);
	}
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }
  const isCheckedAllF = () => {
    return products?.every(itemB => placedProducts.some((itemA: Product4ReceiptModel) => itemA.id === itemB.id));
  }
  const classTable = Object.keys(placedProducts).length ? '' : '  '
  const isCheckedAll = isCheckedAllF();
  return <>
    <div className='table-responsive mt-5'>
      <table className={`table table-bordered border gy-7 gs-7 ${classTable}`}>
        <thead>
        <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
            <th>
            <input key={`checked_all_${isCheckedAll}`} className="form-check-input" type="checkbox" role="button"
              value=""
              checked={isCheckedAll}
              onChange={handleCheckAll} />
            </th>
          <th style={{maxWidth: "120px", minWidth: "120px"}}
              className="text-wrap">{intl.formatMessage({id: 'PRODUCTS.NAME'})}</th>
          <th style={{maxWidth: "180px", minWidth: "180px"}}
              className="text-wrap">Nhà cung cấp</th>
          <th style={{maxWidth: "100px", minWidth: "100px"}}
                     className="text-wrap">Giá NCC</th>
          <th style={{maxWidth: "120px", minWidth: "120px"}}
              className="text-wrap bg-primary">Số lượng</th>
          <th style={{maxWidth: "80px", minWidth: "80px"}}
              className="text-wrap">Tồn kho</th>
          <th style={{maxWidth: "80px", minWidth: "80px"}}
              className="text-wrap">Min</th>
              <th style={{maxWidth: "100px", minWidth: "100px"}}
              className="text-wrap">Max</th>
          <th style={{maxWidth: "100px", minWidth: "100px"}}
              className="text-wrap">Chiết khấu NCC</th>
          <th style={{maxWidth: "120px", minWidth: "120px"}}
              className="text-wrap">Giá bán sento</th>
        </tr>
        </thead>
        <tbody>
        {products?.map((item: Product4ReceiptModel, index: number) => {
          let checked = _.find(placedProducts, {id: item.id});
          let iSupplier = item.best_supplier ?? {};
          let supplier = iSupplier?.supplier ?? {};
          var itemId = iSupplier?.id ?? 1;
          let qty = 0;
          let supplierPrice = iSupplier?.price ?? item.price;
          let supplierDiscount = iSupplier.discount ?? 0;
          let price = item.price ?? 0;
          var suppliersOptions:Selectoptions[]  = [];
          let warehouse:any = {};
          if (item.inventory_of_supplier && item.inventory_of_supplier.length > 0) {
            item.inventory_of_supplier.map((element) => {
              let supplierName = element?.supplier?.name ?? 'Sento';
              let supplierId = element?.supplier?.id ?? 1;
              suppliersOptions = [...suppliersOptions, {label: supplierName, value: supplierId}];
              if (itemId == element.id) {
                warehouse = element.warehouse;
              }
            })
          }
          
          var curSupplier = {label: supplier?.name ?? 'Sento', value:supplier?.id ?? 1};
          if (checked) {
            qty = checked.receipt_quantity ?? qty;
            supplierPrice = checked['best_supplier.new_price'] ?? supplierPrice;
            supplierDiscount = checked['best_supplier.new_discount'] ?? supplierDiscount;
            price = checked['new_price'] ?? price;
            if (checked?.new_supplier) {
              supplierPrice = checked?.new_supplier?.price ?? 0;
              supplierDiscount = checked?.new_supplier?.discount ?? 0;
            }
            if (checked.new_supplier) {
              warehouse = checked.new_supplier.warehouse;
            }
          }
          if (typeof supplierDiscount === 'string') {
            supplierDiscount = parseFloat(supplierDiscount)
          }
          return (
            <tr key={`order_product_${index}_${item.id}`} className={`cursor-pointer`}>
              <td>
                  <div className="form-check form-check-custom">
                    <input key={`item_checkbox_${item.id}_${checked}`} className="form-check-input" type="checkbox"
                        value={item.id}
                        defaultChecked={checked}
                        onChange={(e) => handleChange(e, item)}/>
                  </div>
              </td>
              <td>
                {item.label}
              </td>
              <td>
                <Select2
                  name="new_supplier"
                  placeholder=""
                  options={suppliersOptions}
                  defaultValue={curSupplier}
                  onChange={(selectedOption: any) => {
                    var iSupplier = _.find(item.inventory_of_supplier, {supplier_id: selectedOption.value})
                    if (iSupplier) {
                      handleChangeSupplier(iSupplier, item)
                    }
                  }}
                />
              </td>
              <td >
                  <NumericFormat
                    getInputRef={(el:HTMLInputElement | null) => priceRef.current[parseInt(itemId.toString())] = el!}
                    thousandSeparator=","
                    name='best_supplier.new_price'
                    className='form-control form-control-sm'
                    value={supplierPrice}
                    onBlur={(e) => {
                      handleChange(e, item, true);
                    }}
                    onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                  />
              </td>
              <td className="bg-primary">
                <div className="input-group input-group-sm mb-3 ">
                  <input className="form-control form-control-sm" type="number" key={`receipt_quantity_${item.id}`}
                        min={0} 
                        defaultValue={qty}
                        name="receipt_quantity"
                        onChange={(e) => handleChange(e, item)}
                        onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}

                  />
                  <span className="input-group-text text-sm-center">{item?.units?.name || ""}</span>
                </div>
              </td>
              <td>
                {warehouse?.quantity ?? 0}
              </td>
              <td>
                {warehouse?.max ?? 0}
              </td>
              <td>
                {warehouse?.min ?? 0}
              </td>
              <td>
                <div className="input-group input-group-sm mb-3">
                  <input 
                    ref={(el) => discountRef.current[parseInt(itemId.toString())] = el!}
                    className="form-control form-control-sm" 
                    key={supplierDiscount}
                    min={0} defaultValue={supplierDiscount?.toFixed(2)}
                    onBlur={(e) => handleChange(e, item)}
                    name="best_supplier.new_discount"
                    onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                  />
                  <span className="input-group-text text-sm-center">%</span>
                </div>
              </td>
              <td>
                <NumericFormat
                  thousandSeparator=","
                  name='new_price'
                  className='form-control form-control-sm'
                  value={price}
                  onBlur={(e) => {
                    handleChange(e, item, true);
                  }}
                  onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                />
                {/* <input className="form-control form-control-sm" 
                  defaultValue={price}
                  type="number" min={0}
                  name="new_price"
                  key={price}
                  onBlur={(e) => handleChange(e, item)}
                 /> */}
              </td>
            </tr>
          )
        })}
        </tbody>
      </table>
    </div>
  </>
}
export default ProductTable