import React, {FC} from "react";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {useLocation, useParams} from "react-router-dom";
import ReceiptProducts from "./ReceiptProducts";

interface ILocation {
  receipt_code?: string;
}

const InventoryDeliveryProductsWrapper: FC = () => {
  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Đơn xuất hàng',
      path: '/warehouse/inventory-delivery/list',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const {id} = useParams<{ id?: string }>();
  const location = useLocation<ILocation>();

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}>Tìm kiếm sản phẩm</PageTitle>
      <ReceiptProducts/>
    </>
  )
}
export default InventoryDeliveryProductsWrapper;