import { FC, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { PaginateModel } from "../../models/PaginateModel";
import { SalesProductModel, SearchModel } from "../../models/SalesProductModel";
import { Sento, usePermissionChecker } from "../../hooks/PermissionChecker";
import { HeaderModel, ModalModel } from "../../models/CommonModel";
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../_metronic/partials/pagination/PaginationLength";
import queryString from 'query-string'
import _ from "lodash";
import * as SalesProductCRUD from './SalesProductCRUD';
import { Toast, download, getDiscount } from "../../modules/common/helper";
import { PaginationHeader } from "../../../_metronic/partials/pagination/PaginationHeader";
import ConfigModal from "../../components/Modal/ConfigModal";
import { Paginate } from "../../../_metronic/partials/pagination/Paginate";
import SalesProductsTable from "./SalesProductsTable";
import { AsyncSelect2 } from "../../../_metronic/partials/select/AsyncSelect2";
import { ErrorMessage } from "formik";
import { GET_SALES_OPTIONS } from "../../constants/endpoints";
import { revertPriceNumner } from "../../selectors";
import axios, { AxiosError } from "axios";
import { IAxiosError } from "../../models/AxiosModel";
import { HTTP_UNPROCESSABLE_ENTITY } from "../../constants/status";
import SelectStaffDuplicateModal from "./SelectStaffDuplicateModal";

const SalesProducts: FC = () => {
    const intl = useIntl();
    const history = useHistory();
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<SalesProductModel>>();
    const [hideCols, setHideCols] = useState<string[]>([]);
    const [configModal, setConfigModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const [staffModal, setStaffModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const initSearch = {
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0],
    }
    const [search, setSearch] = useState<SearchModel>(initSearch)
    const [selectedProds, setSelectedProds] = useState<SalesProductModel[]>([]);

    useEffect(() => {
        document.title = 'Sento - Đại lý';
    })

    useEffect(() => {
       
    }, []);

    const getListProducts = async (params: SearchModel) => {
        const {data: resData} = await SalesProductCRUD.getAssigned(params);
        const objQuery = {...params}
        if (objQuery.page === 1) {
          delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
          delete objQuery.per_page
        }
        history.push({search: `?${queryString.stringify(objQuery)}`})
        setDataPaginate(resData);
    }

    const handlePageChange = (data: any) => {
        getListProducts({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getListProducts({ ...search, page: 1, per_page: +e.target.value })
    }

    const handleSearch = (e: any) => {
        e.preventDefault();
        getListProducts({ ...search, page: 1 })
    }

    const handleCheckbox = (item: SalesProductModel, checked = true) => {
        let selected = [...selectedProds];
        if (checked) {
            selected = [...selected, item];
        } else {
            selected = selected.filter((e) => {
                return e.id != item.id
            });
        }
        setSelectedProds(selected);
    }

    const handleCheckAll = () => {
        
    }


    const handleClearSearch = (e: any) => {
        e.preventDefault();
        setSearch({ ...initSearch, sales_id: search.sales_id });
        getListProducts({ ...initSearch, sales_id: search.sales_id })
        setSelectedProds([]);
    }

    const handleExport = async () => {
        const { data } = await SalesProductCRUD.exportCSV({ ...search });
        download(data, 'agency');
    }


    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleSearch(e);
        }
    }

    const header: HeaderModel[] = [
        {
            label: intl.formatMessage({ id: 'AGENT.ID' }),
            style: {},
            field: 'id',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'AGENT.NAME' }),
            style: { minWidth: "150px" },
            field: 'name',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'COMMON.PHONE' }),
            style: {},
            field: 'phone',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'COMMON.EMAIL' }),
            style: { minWidth: "150px" },
            field: 'email',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'COMMON.ADDRESS' }),
            style: { minWidth: "250px" },
            field: 'address',
            sortable: true
        }
    ]

    const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
        const currentSearch = { ...search };
        const sortParams: SearchModel = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
        if (direction === 'none') {
            delete sortParams.sort_by;
            delete sortParams.sort_direction;
        }
        getListProducts({ ...sortParams });
    }

    const handleChangeVisCols = (e: any) => {
        let hiddenCols = [...hideCols];
        if (!e.target?.checked) {
            setHideCols([...hiddenCols, e.target.name])
        } else {
            setHideCols(hiddenCols.filter((item) => item !== e.target.name))
        }
    }

    const handleChange = (e: any) => {
        let newSearch = {...search, [e.target.name]: e.target.value};
        setSearch(newSearch);
        getListProducts(newSearch)
    }

    const handleChangeProdVal = (e: any, item: SalesProductModel) => {
        let val = revertPriceNumner(e.target.value);
        var curSelected = [...selectedProds];
        let sIndex = _.findIndex(curSelected, {id: item.id});
        let salesProdData = curSelected[sIndex].sales_product_by_sale;
        salesProdData = {...salesProdData, [e.target.name]: val};
        if (e.target.name === 'price') {
            salesProdData = {...salesProdData, discount: calcDiscount(item.price, val)};
        } else  if (e.target.name === 'discount') {
            salesProdData = {...salesProdData, price: calcPrice(item.price, val)};
        }
        let sItem = {...curSelected[sIndex], 
            sales_product_by_sale: salesProdData 
        };
        curSelected[sIndex] = sItem;
        setSelectedProds(curSelected);
    }

    const calcDiscount = (marketPrice: any, sellPrice: any) => {
		return ((marketPrice - sellPrice) / marketPrice) * 100;
	}
	const calcPrice = (marketPrice: any, discount: any) => {
		return marketPrice - (marketPrice * discount / 100);
	}

    const handleDeleteSelected = async () => {
        try {
            if (search.sales_id) {
                const { data } = await SalesProductCRUD.deleteSelected(selectedProds, search.sales_id);
                Toast.fire({
                    icon: 'success',
                    title: data?.message || intl.formatMessage({ id: 'COMMON.SUCCESS_NOTY' }),
                });
                setSelectedProds([])
                getListProducts({ ...search, page: 1 });
            }
        } catch (error: any) {
            Toast.fire({
                icon: 'error',
                title: error?.response?.data?.message,
            })
        }
    }

    const handleUpdateProd = async () => {
        try {
            let params: any = {
                sales_id: search.sales_id,
                selected_prods: selectedProds
            }
            const {data} = await SalesProductCRUD.updaeAssignedProducs({...params});
            Toast.fire({
                icon: 'success',
                title: data?.message || intl.formatMessage({id: 'COMMON.SUCCESS_NOTY'}),
            })
            setSelectedProds([]);
            getListProducts({ ...search, page: 1 });
        } catch (error: any) {
            Toast.fire({
                icon: 'error',
                title: error?.response?.data?.message,
            })
        }
    }

    const handleCloneStaffProducts = async (saleId?: number) => {
        try {
            let params: any = {
                sales_id: saleId,
                clone_id: search.sales_id
            }
            const {data} = await SalesProductCRUD.cloneSalesProds({...params});
            setStaffModal({...staffModal, show: false})
            Toast.fire({
                icon: 'success',
                title: data?.message || intl.formatMessage({id: 'COMMON.SUCCESS_NOTY'}),
            })
        } catch (error: any) {
            Toast.fire({
                icon: 'error',
                title: error?.response?.data?.message,
            })
        }
    }

    return <>
        <div className="card">
            <div className="card-header py-4">
                <div className="col-md-12">
                    <form onSubmit={handleSearch}>
                        <div className="row">
                            <div className="col-md-3 ">
                                <AsyncSelect2
                                    isGray
                                    sourceUrl={`${GET_SALES_OPTIONS}`}
                                    defaultOptions
                                    default
                                    className="mt-8"
                                    onChange={(selectedOption: any) => {
                                        handleChange({target: {
                                            name: 'sales_id', 
                                            value: selectedOption.value,
                                            label: selectedOption.label
                                        }});
                                    }}
                                />
                            </div>
                            <div className="col-md-3 ">
                                <label className='form-label'>{intl.formatMessage({ id: 'PRODUCTS.NAME' })}</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    placeholder=''
                                    name='product_name'
                                    value={search?.product_name ?? ''}
                                    onChange={(e:any) => handleChange(e)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <button
                                        className="col-auto btn btn-primary mt-8 me-2"
                                        onClick={handleSearch}
                                    >
                                        <i className="bi bi bi-search fs-4"></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
                                    </button>
                                    <button
                                        className="col-auto btn btn-danger mt-8 me-2"
                                        type="button"
                                        onClick={handleClearSearch}
                                    >
                                        <i className="bi bi-eraser-fill fs-1"></i> Re-search
                                    </button>
                                </div>

                            </div>
                            <div className="col-md-2 text-end">
                                <button className="btn btn-icon mt-8 me-2 btn-light-info me-5 text-hover-white" type="button"
                                    onClick={handleExport}>
                                    <i className="bi bi bi-cloud-arrow-down-fill fs-1 text-info"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card-body">
                <div className="row justify-content-between align-items-center mb-3">
                    <div className='col-7 col-md-9'>
                        <PaginationHeader from={dataPaginate?.meta?.from || 0} to={dataPaginate?.meta?.to || 0}
                            total={dataPaginate?.meta?.total || 0} />
                    </div>
                    <div className='col-5 col-md-3 text-end'>
                        {selectedProds?.length > 0 && (
                            <>
                                <button type="button" className="btn btn-success btn-sm mx-1" onClick={() => handleUpdateProd()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy" viewBox="0 0 16 16">
                                        <path d="M11 2H9v3h2z" />
                                        <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
                                    </svg> {intl.formatMessage({ id: 'COMMON.SAVE' }, { name: 'selected' })} 
                                </button>
                                <button type="button" className="btn btn-danger btn-sm mx-1" onClick={() => handleDeleteSelected()}>
                                    <i className="bi bi bi-trash"></i> {intl.formatMessage({ id: 'COMMON.DELETE' }, { name: 'selected' })} 
                                </button>
                            </>
                        )}
                        {search.sales_id && (
                            <button type="button" className="btn btn-success btn-sm mx-1" onClick={() => setStaffModal({ ...staffModal, show: true })}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                </svg> Sao chép
                            </button>
                        )}
                        <Link to="/sales-products/add" className="btn btn-primary btn-sm"><i
                            className="bi bi bi-plus fs-4"></i> {intl.formatMessage({ id: 'COMMON.CREATE' })}</Link>
                        
                    </div>

                </div>
                <SalesProductsTable
                    data={dataPaginate?.data || []}
                    toggleSelected={handleCheckbox}
                    handleCheckAll={handleCheckAll}
                    handleSort={handleSort}
                    hideCols={hideCols}
                    selected={selectedProds}
                    setHideCols={setHideCols}
                    handleChangeProdVal={handleChangeProdVal}
                />
            </div>
            <div className="card-footer d-flex justify-content-between">
                <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
                <Paginate
                    pageCount={dataPaginate?.meta?.last_page || 0}
                    onPageChange={handlePageChange}
                    forcePage={(search.page || 1) - 1}
                />
            </div>
        </div>
        <ConfigModal
            {...configModal}
            header={header}
            hideCols={hideCols}
            handleChangeVisCols={handleChangeVisCols}
            onClose={() => { setConfigModal({ ...configModal, show: false }) }}
        />
        {search.sales_id && (
            <SelectStaffDuplicateModal
                {...staffModal}
                curSaleId={search.sales_id}
                onHide={() => {setStaffModal({...staffModal, show:false})}}
                onSubmit={handleCloneStaffProducts}
            />
        )}
    </>
}
export default SalesProducts;