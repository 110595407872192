import {IOrderProduct} from "../models/OrderModel";

// Item is inventory of sento
const getSalePrice = (item: IOrderProduct) => {
  
  const priceOfAgency = item.price_of_agency ?? {};
  let salePrice = priceOfAgency?.new_sale_price ?? priceOfAgency?.sale_price ?? 0;
  
  if (!!salePrice) {
    return isNaN(salePrice) ? 0 : Math.round(salePrice)
  }
  return item.price;
}
export default getSalePrice;