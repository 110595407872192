import {IOrderProduct} from "../models/OrderModel";

// Item is inventory of sento
const getCostPriceSento = (item: IOrderProduct) => {
  if (!item) {
    return 0;
  }
  const priceOfAgency = item.price_of_agency ?? {};
  let costPrice = priceOfAgency?.cost_price_sento ?? 0;
  return isNaN(costPrice) ? 0 : costPrice;
}
export default getCostPriceSento;