import Swal from 'sweetalert2'
import {IPermissionsModel, IPermission} from '../../modules/auth/models/PermissionsModel'
import {UserPermission, hasPermission} from '../../modules/utils/UserPermissions '
import {IInventoryOfSupplier} from '../auth/models/InventoryOfSupplierModel'
export const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast: any) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export const Confirm = Swal.mixin({
  position: 'center',
  showConfirmButton: true,
  showCancelButton: true,
})

export const ToastConfirm = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: true,
})

export const download = (data: Blob, name: string, format: string = 'xlsx') => {
  let url = ''
  if (data instanceof Blob) {
    url = URL.createObjectURL(data)
  } else {
    url = URL.createObjectURL(new Blob([data]))
  }
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${name}.${format}`)
  document.body.appendChild(link)
  link.click()

  setTimeout(() => {
    link.removeAttribute('href')
    link.removeAttribute('download')
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }, 100)
}
export const checkPermissions = (userPermissions: IPermission[]): IPermissionsModel => {
  return {
    canView: hasPermission(userPermissions, 'view'),
    canCreate: hasPermission(userPermissions, 'create'),
    canUpdate: hasPermission(userPermissions, 'update'),
    canDelete: hasPermission(userPermissions, 'delete'),
  }
}

export const roundNumber = (number: number, decimals: number = 5) => {
  // if (isNaN(number)) {
  //   return 0
  // }
  const factor = Math.pow(10, decimals)
  return Math.round(number * factor) / factor
}

export const getBestSupplier = (inventoryOfSupplier: Array<IInventoryOfSupplier>) => {
  return inventoryOfSupplier.reduce((result: any, item: IInventoryOfSupplier) => {
    let minPrice = result.length ? result[0].price_sell : item.price_sell
    let priceSell = item?.price_sell ?? 0
    if (priceSell < minPrice) {
      minPrice = item.price_sell
      result.length = 0
    }

    if (item.price_sell === minPrice) {
      result = {...item}
    }

    return result
  }, {})
}

export const convertDotNotationToObject = (dotNotationObj: {[key: string]: any}) => {
  const resultObj = {}

  Object.keys(dotNotationObj).forEach((key) => {
    const value = dotNotationObj[key]
    const keyParts = key.split('.')
    let currentPart: {[key: string]: any} = resultObj

    for (let i = 0; i < keyParts.length - 1; i++) {
      const part = keyParts[i]
      if (!currentPart[part]) currentPart[part] = {}
      currentPart = currentPart[part]
    }

    currentPart[keyParts[keyParts.length - 1]] = value
  })

  return resultObj
}

export const getDiscount = (price: number, supplierPrice: number) => {
  let discount = 0
  if (price !== 0 || supplierPrice !== 0) {
    discount = price - supplierPrice
  }
  return roundNumber((discount / price) * 100, 6) >= 0
    ? roundNumber((discount / price) * 100, 6)
    : 0
}
