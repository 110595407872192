import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from "react-intl";
import { useParams } from 'react-router-dom';
import AddToCart from './AddToCart';

const AddCartWrapper: FC = () => {

  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]
  const {id} = useParams<{ id?: string }>();
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{intl.formatMessage({id: 'MENU.ORDER'})}</PageTitle>
      <AddToCart id={id} />
    </>
  )
}

export default AddCartWrapper
