import React, { FC, useMemo, useState } from "react";
import formatPrice from "../../../selectors/formatPrice";
import { useIntl } from "react-intl";
import _ from "lodash";
import { PaginateModel } from "../../../models/PaginateModel";
import { useParams } from "react-router-dom";
import SortableColumn from "../../../components/Table/SortableColumn";
import { HeaderModel } from "../../abilities/Abilities";
import ConfigModal from "../../abilities/ConfigModal";
import { ModalModel } from "../../../models/CommonModel";
import { IInventoryOfSento} from "../../../models/AgentProduct";
import { ReceiptDetailModel } from "../../../models/ReceiptDetailModel";
import { NumericFormat } from "react-number-format";
import { selectedProdModel } from "./AddProducts";

type TProps = {
    receiptDetails?: Array<ReceiptDetailModel>
    products?: PaginateModel<IInventoryOfSento>,
    flagPlacedProd?: number,
    selectedProds: selectedProdModel[],
    handleCheckbox: (id: number, price: number, checked: boolean) => void,
    handleCheckAll: (checked: boolean) => void,
    arrDelete?: number[],
    handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void;
    hideCols: string[],
    setHideCols: (cols: string[]) => void,
    handleChangeProdVal: (e: any, item: IInventoryOfSento) => void
}

const ProductTable: FC<TProps> = ({
    products,
    handleCheckbox,
    handleCheckAll,
    arrDelete,
    handleSort,
    hideCols,
    setHideCols,
    selectedProds,
    handleChangeProdVal
}) => {
    const intl = useIntl();
    const { id } = useParams<{ id?: string }>();

    const [configModal, setConfigModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });

    const headers: HeaderModel[] = [
        {
            label: intl.formatMessage({ id: 'PRODUCTS.NAME' }),
            field: 'name',
            sortable: true,
            style: { maxWidth: "150px", minWidth: "150px" }
        }
    ]
    headers.push({
        label: intl.formatMessage({ id: 'PRODUCTS.SENTO_CAPITAL_PRICE' }),
        field: 'cost_price_sento',
        sortable: true,
        style: { maxWidth: "120px", minWidth: "120px" }
    }, {
        label: intl.formatMessage({ id: 'PRODUCTS.PRICE_SELL' }),
        field: 'sale_price',
        sortable: true,
        style: { maxWidth: "120px", minWidth: "120px" }
    }, {
        label: intl.formatMessage({ id: 'PRODUCTS.MARKET_PRICE' }),
        field: 'market_price',
        sortable: true,
        style: { maxWidth: "120px", minWidth: "120px" }
    }, {
        label: 'Chiết khấu NCC',
        field: 'discount',
        sortable: true,
        style: { maxWidth: "120px", minWidth: "120px" }
    }, {
        label: intl.formatMessage({ id: 'PRODUCTS.VAT' }),
        field: 'vat',
        sortable: true,
        style: { maxWidth: "120px", minWidth: "120px" }
    }
    )

    const handleChangeVisCols = (e: any) => {
        let hiddenCols = [...hideCols];
        if (!e.target?.checked) {
            setHideCols([...hiddenCols, e.target.name])
        } else {
            setHideCols(hiddenCols.filter((item) => item !== e.target.name))
        }
    }
    const isCheckedAll = () => {
        let prods = products?.data ?? [];
        let ids = prods?.map((e) => {
            return {
                id: e.id ?? 0,
                price: e.price ?? 0
            }
        });
       
        let unChecked = ids.filter((e) => {
            return !_.some(selectedProds, {id: e.id});
        });
        return unChecked.length == 0 && selectedProds.length > 0;
    }
    return <>
        <div className='table-responsive mt-5' key={products?.meta?.current_page}>
            <table className={`table table-bordered border gy-7 gs-7`}>
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th>
                            <i
                                className="fa fa-cogs"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setConfigModal({ ...configModal, show: true })}
                            ></i>
                        </th>
                        <th>
                            <input className="form-check-input"
                                type="checkbox"
                                onChange={(e: any) => handleCheckAll(e.target.checked)}
                                checked={isCheckedAll()}
                            />
                        </th>
                        {headers.map((th, index) => {
                            if (!hideCols.includes(th.field)) {
                                if (th.sortable) {
                                    return (
                                        <SortableColumn
                                            key={'th-' + index}
                                            sortBy={th?.field}
                                            onSortChange={handleSort}
                                            style={th?.style ?? {}}
                                        >
                                            {th.label}
                                        </SortableColumn>
                                    )
                                }
                                return (
                                    <th style={th?.style ?? {}}>{th.label}</th>
                                )
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {products?.data?.map((item: IInventoryOfSento, index: number) => {
                        if (item?.id) {
                            let isChecked = _.some(selectedProds, {id: item.id});
                            return (
                                <tr key={`proucts_${item.id}`} className={`cursor-pointer`}>
                                    <td></td>
                                    <td>
                                        <div className="form-check form-check-custom">
                                            <input className="form-check-input" type="checkbox"
                                                value={item.id}
                                                defaultChecked={isChecked}
                                                checked={isChecked}
                                                onChange={(e: any) => handleCheckbox && handleCheckbox(item.id ?? 0, item.price ?? 0, e.target.checked)} />
                                        </div>
                                    </td>
                                    {!hideCols.includes('name') &&
                                        <td>
                                            {item.name}
                                        </td>
                                    }
                                    {!hideCols.includes('cost_price_sento') &&
                                        <td>
                                            {formatPrice(item?.best_supplier?.price ?? item?.price)}
                                        </td>
                                    }
                                    {!hideCols.includes('sale_price') &&
                                        <td>
                                            <NumericFormat
                                                thousandSeparator=","
                                                name='inventory_of_supplier.price'
                                                className='form-control'
                                                defaultValue={item?.price ?? 0}
                                                onChange={(e) => {
                                                    handleChangeProdVal(e, item);
                                                }}
                                            />
                                        </td>
                                    }
                                    {!hideCols.includes('market_price') &&
                                        <td>
                                            {formatPrice(item?.best_supplier?.price_sell ?? item?.price)}
                                        </td>
                                    }
                                    {!hideCols.includes('discount') &&
                                        <td style={{ minWidth: '150px' }}>{`${formatPrice(item.best_supplier?.discount ?? item.discount)}%`}</td>
                                    }
                                    {!hideCols.includes('vat') &&
                                        <td style={{ width: '100px' }}>
                                            <div className="input-group input-group-sm mb-3">{item.vat + '%'}</div>
                                        </td>
                                    }
                                </tr>
                            )
                        }
                    })}
                </tbody>
            </table>
        </div>
        <ConfigModal
            {...configModal}
            header={headers}
            hideCols={hideCols}
            handleChangeVisCols={handleChangeVisCols}
            onClose={() => {
                setConfigModal({ ...configModal, show: false })
            }}
        />
    </>
}
export default ProductTable