import React, {FC} from "react";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../../_metronic/layout/core";
import Receipt from "./Receipt";

const InventoryDeliveryWrapper: FC = () => {
  const intl = useIntl();
  const breadcrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({id: 'MENU.WAREHOUSE'}),
      path: '/warehouse/list',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    }
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Đơn hàng xuất kho</PageTitle>
      <Receipt/>
    </>
  )
}
export default InventoryDeliveryWrapper;