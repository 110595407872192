import React, { FC, useEffect, useRef, useState } from "react";
import { PaginationHeader } from "../../../../_metronic/partials/pagination/PaginationHeader";
import ReceiptTable from "./ReceiptTable";
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../../_metronic/partials/pagination/PaginationLength";
import { Paginate } from "../../../../_metronic/partials/pagination/Paginate";
import { PaginateModel } from "../../../models/PaginateModel";
import { IReceipt, ISearch, ISearchReceipt } from "../../../models/WareHouseModel";
import { useIntl } from "react-intl";
import * as ReceiptCRUD from "./ReceiptCRUD";
import queryString from "query-string";
import { Link, useHistory } from "react-router-dom";
import { Sento, usePermissionChecker, UserLoggedIn } from "../../../hooks/PermissionChecker";
import { LIST_PERMISSIONS } from "../../../constants/common";
import { HeaderModel, ModalModel } from "../../../models/CommonModel";
import ConfigModal from "./ConfigModal";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { Toast, download } from "../../../modules/common/helper";
import { formatPrice, openNewTabWithHtml } from "../../../selectors";
import {Vietnamese} from "flatpickr/dist/l10n/vn.js"
import useMasterData from "../../../hooks/useMaster";

const Receipt: FC = () => {

    const initRange:any = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    const initSearch = {
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0],
        status: '',
        receipt_type: 1,
        date:{startDate: initRange[0], endDate: initRange[1]},
        store_id: UserLoggedIn().store_id
    }
    const intl = useIntl();
    const history = useHistory();
    const searchHistory = history.location.search;
    const fp = useRef<any>(null);
    const [search, setSearch] = useState<ISearchReceipt>(initSearch)
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<IReceipt>>();
    const { hasPermission } = usePermissionChecker();
    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const [hideCols, setHideCols] = useState<string[]>([]);
    const [subTotal, setSubTotal] = useState<number>(0)
    const [configModal, setConfigModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const sento = Sento();
    const { master } = useMasterData();
    const parseParams = (params: ISearchReceipt) => {
        if (params.page) {
            params.page = +params.page
        }
        if (params.per_page) {
            params.per_page = +params.per_page
        }
        return params
    }


    const initParams: ISearchReceipt = parseParams(queryString.parse(searchHistory))

    useEffect(() => {
        document.title = 'Sento - Nhập hàng';
    })

    useEffect(() => {
        const params = { ...search, ...initParams }
        getList(params);
        setSearch(params)
    }, [])

    const handlePageLengthChange = (e: any) => {
        getList({ ...search, page: 1, per_page: +e.target.value })
    }

    const handlePageChange = (data: any) => {
        getList({ ...search, page: data.selected + 1 })
    }

    const handleSearch = (e: any) => {
        e.preventDefault();
        getList({ ...search, page: 1 })

    }

    const handleClearSearch = (e: any) => {
        e.preventDefault();
        if (fp.current?.flatpickr) {
            fp.current.flatpickr.clear();
        }
        setSearch({
            ...initSearch,
        });
        getList({ ...initSearch })
    }

    const getList = async (params: ISearchReceipt) => {
        const {data: resData} = await ReceiptCRUD.getList(params);
        const objQuery = { ...params }
        if (objQuery.page === 1) {
            delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
            delete objQuery.per_page
        }
        history.push({ search: `?${queryString.stringify(objQuery)}` })
        setDataPaginate(resData?.paginate_data);
        setSubTotal(resData?.sub_total);
        setSearch(params ?? {});
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleSearch(e);
        }
    }

    const header: HeaderModel[] = [
        {
            label: 'Mã đơn',
            style: { minWidth: "150px" },
            field: 'receipt_code',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'WAREHOUSE.SUPPLIER' }),
            style: { minWidth: "150px" },
            field: 'supplier_name',
            sortable: true
        },
        {
            label: 'Tổng giá trị',
            style: { minWidth: "150px" },
            field: 'sub_total',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'WAREHOUSE.STATUS' }),
            style: { minWidth: "150px" },
            field: 'status',
            sortable: true
        },
        {
            label: 'Ngày duyệt',
            style: { minWidth: "150px" },
            field: 'approved_at',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'WAREHOUSE.NOTE' }),
            style: { minWidth: "150px" },
            field: 'note',
            sortable: true
        }
    ]

    const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
        const currentSearch = { ...search };
        const sortParams: ISearch = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
        if (direction === 'none') {
            delete sortParams.sort_by;
            delete sortParams.sort_direction;
        }
        getList({ ...sortParams });
    }

    const handleChangeVisCols = (e: any) => {
        let hiddenCols = [...hideCols];
        if (!e.target?.checked) {
            setHideCols([...hiddenCols, e.target.name])
        } else {
            setHideCols(hiddenCols.filter((item) => item !== e.target.name))
        }
    }

    const handleExport = async () => {
        const {data} = await ReceiptCRUD.exportCSV({...search});
        download(data, 'receipts');
    }
    const handleExportPdf = async (id: number) => {
        const {data: resData} = await ReceiptCRUD.exportPdf({id})
        openNewTabWithHtml(resData);
    }
    const handleDelete = async () => {
        try {
            const res = await ReceiptCRUD.removeReceipt(selectedIds);
            Toast.fire({
              icon: 'success',
              title: res?.data?.message || intl.formatMessage({id: 'COMMON.SUCCESS_NOTY'}),
            })
          } finally {
            getList({...search, page: 1})
          }
    }

    return <div className="card">
        <div className="card-header py-4">
            <div className="col-md-12">
                <form onSubmit={handleSearch}>

                    <div className="row">
                        <div className='col-md-3 mb-2'>
                            <label className="form-label" htmlFor="name">{intl.formatMessage({ id: 'WAREHOUSE.INFO' })}</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="name"
                                value={search.name || ''}
                                onChange={(e) => setSearch({ ...search, name: e.target.value })}
                                autoComplete='nope'
                                onKeyDown={(e) => handleKeyDown(e)}
                            />
                        </div>
                        <div className="col-md-3 mb-2">
                            <label className="form-label">{intl.formatMessage({id: 'ORDER.STATUS'})}</label>
                            <select
                                className="form-select"
                                value={search.status}
                                onChange={(e) => setSearch({...search, status: e.target.value})}
                                name="status"
                            >
                                <option value="">Tất cả</option>
                                <option value="Chưa Duyệt">Chưa duyệt</option>
                                <option value="Duyệt">Duyệt</option>
                            </select>
                            </div>
                            <div className='col-md-3 mb-2'>
                                <label className="form-label">{intl.formatMessage({ id: 'COMMON.TIME' })}</label>
                                <Flatpickr
                                    ref={fp}
                                    onChange={(date) => {
                                        let startDate = moment(date[0]).format('YYYY-MM-DD');
                                        let endDate = moment(date[1]).format('YYYY-MM-DD');
                                        setSearch({ ...search, date: { startDate, endDate } });
                                    }}
                                    options={{
                                        mode: "range",
                                        dateFormat: 'd-m-Y',
                                        enableTime: false,
                                        locale: Vietnamese,
                                        defaultDate: [moment().format('DD-MM-YYYY'), moment().format('DD-MM-YYYY')],
                                    }}
                                    className='form-control'
                                    placeholder=''
                                />
                            </div>
                            {sento &&
                                <div className="col-md-3 mb-2">
                                    <label className="form-label">Store</label>
                                    <select
                                    className="form-select"
                                    value={search.store_id}
                                    onChange={(e) => setSearch({ ...search, store_id: e.target.value })}
                                    name="store_id"
                                    >
                                    <option value=''>Tất cả</option>
                                    {master?.stores?.map((store, index) => {
                                        return (
                                        <option value={store.id}>{store.name}</option>
                                        )
                                    })}
                                    </select>
                                </div>
                            }
                        <div className='col-md-3 mb-2 d-flex'>
                            <div className="row">
                                <button
                                    className="col-auto btn btn-primary mt-8 me-2"
                                    onClick={handleSearch}
                                >
                                    <i className="bi bi bi-search fs-4"></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
                                </button>
                                <button
                                    className="col-auto btn btn-danger mt-8 me-2"
                                    type="button"
                                    onClick={handleClearSearch}
                                >
                                    <i className="bi bi-eraser-fill fs-1"></i> Re-search
                                </button>
                                <button className="btn btn-icon mt-8 me-2 btn-light-info me-5 text-hover-white" type="button"
                                onClick={handleExport}>
                                <i className="bi bi bi-cloud-arrow-down-fill fs-1 text-info"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div className="card-body">
            <div className="justify-content-between align-items-center my-3 row">
                <div className='col-7 col-md-9'>
                    <PaginationHeader from={dataPaginate?.meta?.from || 0} to={dataPaginate?.meta?.to || 0}
                        total={dataPaginate?.meta?.total || 0} />
                    <h3>Tổng cộng: <span className="text-bold">{formatPrice(subTotal)} VNĐ</span></h3>
                </div>
                <div className='col-5 col-md-3 text-end'>
                    {selectedIds.length > 0 && (
                        <button
                            className="btn btn-sm btn-danger me-2"
                            type="button"
                            onClick={handleDelete}
                            >
                            <i className="bi bi bi-trash fs-4"></i> {intl.formatMessage({id: 'COMMON.DELETE'})}
                        </button>
                    )}
                    
                </div>
            </div>
            <ReceiptTable
                selectedIds={selectedIds}
                data={dataPaginate?.data || []}
                openColConfig={() => setConfigModal({ ...configModal, show: true })}
                header={header}
                hideCols={hideCols}
                handleExportPdf={handleExportPdf}
                handleSort={handleSort}
                setSelectedIds={(ids) => setSelectedIds(ids)}
            />
        </div>
        <div className="card-footer d-flex justify-content-between">
            <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
            <Paginate
                pageCount={dataPaginate?.meta?.last_page || 0}
                onPageChange={handlePageChange}
                forcePage={(search.page || 1) - 1}
            />
        </div>
        <ConfigModal
            {...configModal}
            header={header}
            hideCols={hideCols}
            handleChangeVisCols={handleChangeVisCols}
            onClose={() => { setConfigModal({ ...configModal, show: false }) }}
        />
    </div>
}
export default Receipt;