import axios from 'axios'
import { GET_MY_ACCOUNT_INFO, UPDATE_MY_ACCOUNT_INFO } from '../../constants/endpoints'
import { ResponseModel } from '../../models/ResponseModel'

export function getDetail() {
  return axios.get<ResponseModel>(`${GET_MY_ACCOUNT_INFO}`)
}
export function update(params: object) {
  return axios.put(`${UPDATE_MY_ACCOUNT_INFO}`, params)
}

