import React, { FC, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useHistory } from 'react-router-dom';

const QrScannerButton: FC = () => {
  
    const [isScanning, setIsScanning] = useState(false);
    const history = useHistory();
    const handleScan = (result: string) => {
        if (result) {
            setIsScanning(false);
            window.location.href=result
        }
    };
    
    const handleError = (err: Error) => {
        console.error("QR Scan Error:", err);
    };

    return (
      <div>
          <button id="enableScanBtn" onClick={() => setIsScanning(!isScanning)}>
            <i className='fa fa-qrcode'></i>
          </button>
          {isScanning && (
              <div id="qrScannerArea" style={{ marginTop: '10px' }}>
                  <QrReader
                      onResult={(result, error) => {
                          if (!!result) {
                              handleScan(result.getText());
                          }

                          if (!!error) {
                              handleError(error);
                          }
                      }}
                      constraints={{ facingMode: 'environment' }}
                  />
              </div>
          )}
      </div>
  );
}
export default QrScannerButton;