/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from "../../../_metronic/assets/ts/_utils";
import * as DashboardCURD from "./DashboardCRUD";
import {ILiAbilities} from "../../models/DashboardModel";
import {map} from "lodash";
import {formatPrice} from "../../selectors";
import {Sento} from "../../hooks/PermissionChecker";

type Props = {
  className: string,
  colorBase: string,
  store?: string
}

const ChartAbilities: React.FC<Props> = ({className, store}) => {

  const isSento = Sento();
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [time, setTime] = useState<string>('month')
  const [liAbilities, setLiAbilities] = useState<ILiAbilities[]>([]);

  useEffect(() => {
    const getOrderReport = async () => {
      const {data} = await DashboardCURD.getReportAbilities({time, store});
      setLiAbilities(data.data)
    }
    getOrderReport();
  }, [time, store]);


  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, liAbilities))
    if (chart && liAbilities.length) {
      chart.render()
    }

    return () => {
      if (chart && liAbilities.length) {
        chart.destroy()
      }
    }
  }, [chartRef, liAbilities])

  const times: any = {
    'week': 'Tuần',
    'month': 'Tháng',
    'year': 'Năm'
  }
  const emptyData = liAbilities.length === 0;

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div className="card-title align-items-center d-flex justify-content-center">
          <h3>
            <span className='card-label fw-bolder fs-3 mb-1'>Doanh thu</span>
          </h3>
        </div>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          {Object.keys(times).map(function (key) {
            return <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${key == time ? 'active' : ''}`}
              id='kt_charts_widget_2_year_btn' key={key}
              onClick={() => setTime(key)}
            >
              {times[key]}
            </a>
          })}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        {emptyData ?
          <div key={`item_key_${emptyData}`}
               className="d-flex justify-content-center align-items-center text-muted fst-italic"
               style={{height: '350px'}}>
            Không tìm thấy dữ liệu
          </div>
          :
          <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
        }
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export default ChartAbilities;

function getChartOptions(height: number, liAbilities: ILiAbilities[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-light-info')

  return {
    series: [
      {
        name: 'Doanh thu',
        data: map(liAbilities, 'value'),
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: map(liAbilities, 'label'),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return formatPrice(val) + ''
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
