import axios from 'axios';
import _ from 'lodash';
import React from 'react'
import {StylesConfig} from 'react-select'
import AsyncSelect from 'react-select/async';

const selectStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    minHeight: '42px',
    border: '1px solid #e4e6ef',
    outline: 0,
    borderRadius: '0.475rem',
    boxShadow: 'none',
    '&:focus': {
      borderColor: '#b5b5c3',
      outline: 0,
      boxShadow: 'none'
    },
    '&:hover': {
      borderColor: '#b5b5c3',
      outline: 0,
      boxShadow: 'none'
    },
  }),
}

const selectGrayStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    minHeight: '42px',
    border: 'none',
    outline: 0,
    borderRadius: '0.475rem',
    boxShadow: 'none',
    backgroundColor: '#f5f8fa',
    borderColor: '#f5f8fa',
    color: '#5e6278',
    transition: 'color 0.2s ease, background-color 0.2s ease',
    '&:focus': {
      backgroundColor: '#eef3f7',
      borderColor: '#eef3f7 !important',
      outline: 0,
      boxShadow: 'none'
    },
    '&:hover': {
      backgroundColor: '#eef3f7',
      borderColor: '#eef3f7 !important',
      outline: 0,
      boxShadow: 'none'
    },
  }),
}

const AsyncSelect2Supplier: React.FC<any> = ({
  isGray,
  sourceUrl,
  vendorId,
  storeId,
  ...otherProps
}) => {
  let timer: any = null;
  const loadOptions = (inputValue: string, callback: any) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      let uri = `${sourceUrl}?search=${inputValue}`;
      if (storeId) {
        uri = `${sourceUrl}?search=${inputValue}&store_id=${storeId}`;
      }
      const data: any = await axios.get(uri);
      let options = [{value: '', label: 'Tất cả'}];
      if (data.data?.data) {
        let newOptions = data.data.data.map((item: any) => ({
          label: item.name,
          value: item.id,
          address: item.address,
          dept: item.total_price - item.total_received
        }));
        options = _.unionBy(options, newOptions, 'value')
      }
      callback(options);
    }, 1000);
  };
  return (
    <AsyncSelect
      key={sourceUrl}
      {...otherProps}
      loadOptions={loadOptions}
      styles={isGray ? selectGrayStyles : selectStyles}
    />
  )
}

export {AsyncSelect2Supplier}
