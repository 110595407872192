import { ChangeEvent, FC, useMemo } from 'react';
import { useIntl } from "react-intl";
import { includes } from 'lodash'
import { SupplierModel } from '../../models/SupplierModel';
import { HeaderModel } from '../../models/CommonModel';
import SortableColumn from '../../components/Table/SortableColumn';

type Props = {
    data: Array<SupplierModel>,
    arrDelete: number[],
    onEdit: (supplier: SupplierModel) => void
    handleCheckbox: (id: number) => void,
    handleCheckAll: (e: ChangeEvent<HTMLInputElement>) => void,
    header: HeaderModel[]
    hideCols: string[],
    openColConfig: () => void,
    handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void
};

const SupplierTable: FC<Props> = ({ data, arrDelete, handleCheckbox, handleCheckAll, onEdit, header, hideCols, openColConfig, handleSort }) => {
    const intl = useIntl();

    const isCheckedAll = useMemo(() => arrDelete.length > 0 && arrDelete.length === data?.length, [arrDelete.length, data.length])

    const isChecked = (id: number) => includes(arrDelete, id);

    return (
        <div className='table-responsive'>
            <table className="table table-hover table-bordered table-striped border gy-7 gs-7">
                <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                        <th>
                            <i 
                                className="fa fa-cogs"
                                style={{cursor: 'pointer'}}
                                onClick={openColConfig}
                            ></i>
                        </th>
                        <th style={{ minWidth: "50px" }}>
                            <input key={`checked_all_${isCheckedAll}`} className="form-check-input" type="checkbox"
                                value=""
                                defaultChecked={isCheckedAll}
                                onChange={handleCheckAll} />
                        </th>
                        {header.map((th, index) => {
                            if (!hideCols.includes(th.field)) {
                                if (th.sortable) {
                                    return (
                                        <SortableColumn
                                            key={'th-' + index}
                                            sortBy={th?.field}
                                            onSortChange={handleSort}
                                            style={th?.style ?? {}}
                                        >
                                            {th.label}
                                        </SortableColumn>
                                    )
                                }
                                return (
                                    <th style={th?.style ?? {}}>{th.label}</th>
                                )
                            }
                        })}
                        <th style={{ minWidth: "150px" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item: SupplierModel, index: number) => {
                        const checked = isChecked(item.id);
                        return (
                            <tr key={`agent_${index}`} className="cursor-pointer">
                                <td></td>
                                <td>
                                    {item.id != 1 && (
                                        <div className="form-check form-check-custom">
                                            <input key={`item_checkbox_${item.id}_${checked}`} className="form-check-input" type="checkbox"
                                                value={item.id}
                                                defaultChecked={checked}
                                                onChange={() => handleCheckbox(item.id)} />
                                        </div>
                                    )}
                                </td>
                                {!hideCols.includes('name') && (
                                <td>{item.name}</td>
                                )}
                                {!hideCols.includes('phone') && (
                                <td>{item.phone}</td>
                                )}
                                {!hideCols.includes('email') && (
                                <td>{item.email}</td>
                                )}
                                {!hideCols.includes('address') && (
                                <td>{item.address}</td>
                                )}
                                {!hideCols.includes('desc') && (
                                <td>{item.desc}</td>
                                )}
                                {!hideCols.includes('status') && (
                                <td>{item.status}</td>
                                )}
                                <td className='text-center'>
                                    <button onClick={() => onEdit(item)} className="btn btn-icon btn-light-warning w-30px h-30px ps-2 me-2">
                                        <i className="bi bi-pencil-square fs-4 me-2"></i>
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div >
    );
}

export default SupplierTable;