import { FC, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import * as AgentCRUD from './AgentCRUD';
import { Link, useHistory } from "react-router-dom";
import { includes, without, map } from 'lodash';
import queryString from 'query-string'
import { PaginateModel } from "../../../models/PaginateModel";
import { IAgent, ISearch } from "../../../models/AgentModel";
import { HeaderModel, ModalModel } from "../../../models/CommonModel";
import { DATA_PAGINATION_LENGTH, PaginationLength } from "../../../../_metronic/partials/pagination/PaginationLength";
import { Toast } from "../../../modules/common/helper";
import { PaginationHeader } from "../../../../_metronic/partials/pagination/PaginationHeader";
import AgentTable from "./AgentTable";
import ConfigModal from "../../../components/Modal/ConfigModal";
import { Paginate } from "../../../../_metronic/partials/pagination/Paginate";


const Agent: FC = () => {
    const initResImport = { message: '', errors: [] };
    const intl = useIntl();
    const history = useHistory();
    const [dataPaginate, setDataPaginate] = useState<PaginateModel<IAgent>>();
    const [resImport, setResImport] = useState(initResImport);
    const [hideCols, setHideCols] = useState<string[]>([]);
    const [configModal, setConfigModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const initSearch = {
        page: 1,
        per_page: DATA_PAGINATION_LENGTH[0],
    }
    const [search, setSearch] = useState<ISearch>(initSearch)
    const [paramsExport, setParamsExport] = useState<ISearch>({})

    const [arrDelete, setArrDelete] = useState<number[]>([])

    const parseParams = (params: ISearch) => {
        if (params.page) {
            params.page = +params.page
        }
        if (params.per_page) {
            params.per_page = +params.per_page
        }
        return params
    }

    useEffect(() => {
        document.title = 'Sento - Đại lý';
    })

    useEffect(() => {
        const searchHistory = history.location.search;
        const initParams: ISearch = parseParams(queryString.parse(searchHistory))
        getData({ ...search, ...initParams });
    }, []);

    const getData = async (params?: ISearch, rmResImport = true) => {
        setArrDelete([]);
        const { data: dataPaginate } = await AgentCRUD.getAgents(params);
        const objQuery = { ...params }
        if (objQuery.page === 1) {
            delete objQuery.page
        }
        if (objQuery.per_page === DATA_PAGINATION_LENGTH[0]) {
            delete objQuery.per_page
        }
        history.push({ search: `?${queryString.stringify(objQuery)}` })
        setDataPaginate(dataPaginate);
        setSearch(params ?? {});
        setParamsExport({ ...params })
        if (rmResImport) {
            setResImport({ ...initResImport })
        }
    }

    const handlePageChange = (data: any) => {
        getData({ ...search, page: data.selected + 1 })
    }

    const handlePageLengthChange = (e: any) => {
        getData({ ...search, page: 1, per_page: +e.target.value })
    }

    const handleSearch = (e: any) => {
        e.preventDefault();
        getData({ ...search, page: 1 })
    }

    const handleCheckbox = (id: number) => {
        const ids = includes(arrDelete, id) ? without(arrDelete, id) : [...arrDelete, id];
        setArrDelete([...ids]);
    }

    const handleCheckAll = (e: any) => {
        const checked = e.target.checked;
        let ids: number[] = [];
        if (checked) {
            ids = map(dataPaginate?.data, 'id');
        }
        setArrDelete([...ids]);
    }

    const showDelete = useMemo(() => !!arrDelete.length , [arrDelete])

    const handleClearSearch = (e: any) => {
        e.preventDefault();
        setSearch({ ...initSearch });
        getData({ ...initSearch })
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleSearch(e);
        }
    }

    const header: HeaderModel[] = [
        {
            label: intl.formatMessage({ id: 'AGENT.ID' }),
            style: {},
            field: 'id',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'AGENT.NAME' }),
            style: { minWidth: "150px" },
            field: 'name',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'COMMON.PHONE' }),
            style: {},
            field: 'phone',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'COMMON.EMAIL' }),
            style: { minWidth: "150px" },
            field: 'email',
            sortable: true
        },
        {
            label: intl.formatMessage({ id: 'COMMON.ADDRESS' }),
            style: { minWidth: "250px" },
            field: 'address',
            sortable: true
        }
    ]

    const handleSort = (sortBy: string, direction: 'asc' | 'desc' | 'none') => {
        const currentSearch = { ...search };
        const sortParams: ISearch = { ...currentSearch, sort_by: sortBy, sort_direction: direction };
        if (direction === 'none') {
            delete sortParams.sort_by;
            delete sortParams.sort_direction;
        }
        getData({ ...sortParams });
    }

    const handleChangeVisCols = (e: any) => {
        let hiddenCols = [...hideCols];
        if (!e.target?.checked) {
            setHideCols([...hiddenCols, e.target.name])
        } else {
            setHideCols(hiddenCols.filter((item) => item !== e.target.name))
        }
    }

    return <>
        <div className="card">
            <div className="card-header py-4">
                <div className="col-md-12">
                    <form onSubmit={handleSearch}>
                        <div className="row">
                            <div className="col-md-2">
                                <label className="form-label">{intl.formatMessage({ id: 'AGENT.NAME' })}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="name"
                                    value={search.name || ''}
                                    onChange={(e) => setSearch({ ...search, name: e.target.value })}
                                    autoComplete='nope'
                                    onKeyDown={(e) => handleKeyDown(e)}
                                />
                            </div>
                            {/* {sento &&
                <div className="col-md-2">
                  <label className="form-label">{intl.formatMessage({id: 'STORE.NAME'})}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="store"
                    autoComplete='nope'
                    value={search.store || ''}
                    onChange={(e) => setSearch({...search, store: e.target.value})}
                  />
                </div>
              } */}
                            <div className={`col-md-4`}>
                                <label className="form-label">{intl.formatMessage({ id: 'COMMON.OTHER_INFORMATION' })}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="other"
                                    autoComplete='nope'
                                    value={search.other || ''}
                                    onChange={(e) => setSearch({ ...search, other: e.target.value })}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <button
                                        className="col-auto btn btn-primary mt-8 me-2"
                                        onClick={handleSearch}
                                    >
                                        <i className="bi bi bi-search fs-4"></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
                                    </button>
                                    <button
                                        className="col-auto btn btn-danger mt-8 me-2"
                                        type="button"
                                        onClick={handleClearSearch}
                                    >
                                        <i className="bi bi-eraser-fill fs-1"></i> Re-search
                                    </button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card-body">
                {resImport?.message &&
                    <div
                        className="alert alert-dismissible bg-light-success border border-success d-flex flex-column flex-sm-row">
                        <div className="d-flex flex-column pe-0 justify-content-center">
                            <h5 className="mb-1 text-success">{resImport?.message}</h5>
                        </div>
                        <button type="button"
                            className="position-absolute position-sm-relative m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                            data-bs-dismiss="alert" style={{ width: "unset", height: "unset" }}>
                            <span className="svg-icon svg-icon-1 svg-icon-primary"><i className="bi bi-x-lg text-success"></i></span>
                        </button>
                    </div>
                }
                {resImport?.errors?.length > 0 &&
                    <div
                        className="alert alert-dismissible bg-light-danger border border-danger d-flex flex-column flex-sm-row p-5">
                        <div className="d-flex flex-column text-danger pe-0 pe-sm-10">
                            <h5 className="mb-1">{intl.formatMessage({ id: 'COMMON.LIST_ERROR' })}</h5>
                            {resImport?.errors.map((item: any) => {
                                return <span key={`error_${item.row}`}><i className="bi bi-dot text-danger"></i>
                                    {intl.formatMessage({ id: 'AGENT.ERROR_IMPORT' }, { row: item.row, message: item.message })} </span>
                            })}
                        </div>
                        <button type="button"
                            className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                            data-bs-dismiss="alert">
                            <span className="svg-icon svg-icon-1 svg-icon-primary"><i className="bi bi-x-lg text-danger"></i></span>
                        </button>
                    </div>
                }
                <div className="row justify-content-between align-items-center mb-3">
                    <div className='col-7 col-md-9'>
                        <PaginationHeader from={dataPaginate?.meta?.from || 0} to={dataPaginate?.meta?.to || 0}
                            total={dataPaginate?.meta?.total || 0} />
                    </div>
                    <div className='col-5 col-md-3 text-end'>
                        <Link to="/agents/detail" className="btn btn-primary btn-sm"><i
                            className="bi bi bi-plus fs-4"></i> {intl.formatMessage({ id: 'COMMON.CREATE' })}</Link>
                    </div>

                </div>
                <AgentTable
                    data={dataPaginate?.data || []}
                    handleCheckbox={handleCheckbox}
                    arrDelete={arrDelete}
                    handleCheckAll={handleCheckAll}
                    openColConfig={() => setConfigModal({ ...configModal, show: true })}
                    header={header}
                    hideCols={hideCols}
                    handleSort={handleSort}
                />
            </div>
            <div className="card-footer d-flex justify-content-between">
                <PaginationLength selected={search.per_page || DATA_PAGINATION_LENGTH[0]} onChange={handlePageLengthChange} />
                <Paginate
                    pageCount={dataPaginate?.meta?.last_page || 0}
                    onPageChange={handlePageChange}
                    forcePage={(search.page || 1) - 1}
                />
            </div>
        </div>
        <ConfigModal
            {...configModal}
            header={header}
            hideCols={hideCols}
            handleChangeVisCols={handleChangeVisCols}
            onClose={() => { setConfigModal({ ...configModal, show: false }) }}
        />
    </>
}
export default Agent;