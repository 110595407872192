import { ChangeEvent, FC } from 'react'
import { TSearch, AccountSelectModel } from '../../../../modules/auth/models/AccountModel'
import { useIntl } from 'react-intl'

type TProps = {
  handleChangeSearch: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void,
  handleSearch: () => void,
  handleClearSearch: () => void
  search: TSearch
}

const FilterList: FC<TProps> = ({ handleChangeSearch, handleSearch, handleClearSearch, search }) => {
  const intl = useIntl()
  const accountStatus: Array<AccountSelectModel> = [
    {
      value: '1',
      label: intl.formatMessage({ id: 'ACCOUNT.STATUS.ACTIVE' }),
    },
    {
      value: '0',
      label: intl.formatMessage({ id: 'ACCOUNT.STATUS.INACTIVE' }),
    },
  ]

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  return (
    <div className='card'>
      <div className='card-body pb-0 px-0'>
        <div className='row'>
          <div className='col-md-2'>
            <label className='form-label'>{intl.formatMessage({ id: 'ACCOUNT.NAME' })}</label>
            <input 
              type='text' 
              className='form-control' 
              placeholder='' 
              name='name' 
              onChange={handleChangeSearch} 
              onKeyDown={(e) => handleKeyDown(e)}
              value={search.name || ''}
            />
          </div>
          <div className='col-md-2'>
            <label className='form-label'>{intl.formatMessage({ id: 'ACCOUNT.EMAIL' })}</label>
            <input type='text' value={search.email || ''} className='form-control' placeholder='' name='email' onChange={handleChangeSearch} onKeyDown={(e) => handleKeyDown(e)} />
          </div>
          <div className='col-md-2'>
            <label className='form-label'>{intl.formatMessage({ id: 'COMMON.OTHER_INFORMATION' })}</label>
            <input type='text' value={search.other || ''} className='form-control' placeholder='' name='other' onChange={handleChangeSearch} onKeyDown={(e) => handleKeyDown(e)} />
          </div>
          <div className='col-md-2'>
            <label className='form-label'>{intl.formatMessage({ id: 'ACCOUNT.STATUS' })}</label>
            <select
              className='form-select'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              value={search.status}
              name="status"
              onChange={handleChangeSearch}
            >
              {accountStatus.map((option, index) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                )
              })}
            </select>
          </div>
          <div className='col-md-4 text-end'>
            <button type='button' className='btn btn-primary mt-8 me-2' onClick={handleSearch}>
              <i className='bi bi bi-search fs-4'></i> {intl.formatMessage({ id: 'COMMON.SEARCH' })}
            </button>
            <button type='button' className='btn btn-danger mt-8' onClick={handleClearSearch}>
              <i className='fa fa-times'></i> {intl.formatMessage({ id: 'COMMON.DELETE_SEARCH' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export  { FilterList }