
import axios from "axios";
import { ResponseModel } from "../../../models/ResponseModel";
import { ADMIN_SALES_ENDPOINT } from "../../../constants/endpoints";

export function getAgentDetail(id?: string) {
  return axios.get<ResponseModel>(`${ADMIN_SALES_ENDPOINT}/agency/${id}`)
}

export function upsertAgent(params: any) {
  return axios.post<ResponseModel>(`${ADMIN_SALES_ENDPOINT}/agency/upsert`, params)
}

export function uniqueAgtCode(params: any) {
  return axios.get<ResponseModel>(`${ADMIN_SALES_ENDPOINT}/agency/unique-code`, {params})
}

