/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { usePermissionChecker } from '../../../../app/hooks/PermissionChecker'
import { LIST_PERMISSIONS } from '../../../../app/constants/common'
export function AsideMenuMainSales() {
    const intl = useIntl()
    const { hasPermission } = usePermissionChecker();
    return (
        <>
            <AsideMenuItem
                to='/my-products'
                icon='/media/icons/duotune/abstract/abs027.svg'
                title={intl.formatMessage({ id: 'MENU.PRODUCTS' })}
                fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
                to='/my-orders'
                icon='/media/icons/duotune/ecommerce/ecm006.svg'
                title={intl.formatMessage({ id: 'MENU.ORDER' })}
                fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
                to='/agents'
                icon='/media/icons/duotune/communication/com005.svg'
                title={intl.formatMessage({ id: 'MENU.AGENT' })}
                fontIcon='bi-app-indicator'
            >

                {/*<AsideMenuItem to='/customer/add' title={intl.formatMessage({id: 'MENU.LIST'})} hasBullet={true}/>*/}
                {/*<AsideMenuItem to='/customer/group' title={intl.formatMessage({id: 'MENU.CUSTOMER_GROUP'})} hasBullet={true}/>*/}
                {/*<AsideMenuItem to='/customer/c' title={intl.formatMessage({id: 'MENU.PRICE_LIST'})} hasBullet={true}/>*/}
            </AsideMenuItem>
            <AsideMenuItemWithSub
                to='/debt'
                icon='\media\icons\duotune\finance\fin010.svg'
                title={intl.formatMessage({ id: 'MENU.DEBT' })}
                fontIcon='bi-app-indicator'
            >
                <AsideMenuItem to='/debt/agency' title={intl.formatMessage({ id: 'MENU.DEBT.AGENCY' })} icon='\media\icons\duotune\general\gen001.svg' />
            </AsideMenuItemWithSub>
        </>
    )
}
