import {Dictionary} from "@reduxjs/toolkit";
import {IOrderProduct} from "../models/OrderModel";
import {formatPrice, getQuantity} from "./index";
import getReceiveDiscount from "./getReceiveDiscount";

const getPriceDetail: any = (products: Dictionary<IOrderProduct>) => {
  const arrKey = Object.keys(products)
  let totalTax = 0;
  let totalPrice = 0;
  let totalDiscount = 0;
  if (arrKey.length) {
    arrKey.forEach((key: string) => {

      const item = products?.[key];
      const quantity = getQuantity(item);
      const salePrice = item?.price_of_agency?.new_sale_price ?? item?.price_of_agency?.sale_price ?? 0;
      const marketPrice = item?.price_of_agency?.new_market_price ?? item?.price_of_agency?.market_price ?? 0;
      // @ts-ignore
      const perDiscount = getReceiveDiscount({
        market_price: marketPrice,
        sale_price: salePrice
      })

      const perTax = item?.new_vat ?? item?.vat ?? 0;
      const discount = (marketPrice / 100) * perDiscount * quantity;
      const vat = (salePrice / 100) * perTax * quantity;

      totalPrice += salePrice * quantity;
      totalTax += vat;
      totalDiscount += discount;
    })
  }
  return {
    price: formatPrice(totalPrice),
    tax: formatPrice(totalTax),
    discount: formatPrice(totalDiscount),
    taxInc: formatPrice(totalPrice + totalTax)
  };
}
export default getPriceDetail;