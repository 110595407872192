import React, { FC, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap-v5";
import _ from "lodash";
import { SalesProductModel } from "../../models/SalesProductModel";
import { formatPrice } from "../../selectors";
import { HeaderModel, ModalModel } from "../../models/CommonModel";
import ConfigModal from "../warehouse/ConfigModal";
import SortableColumn from "../../components/Table/SortableColumn";
import { NumericFormat } from "react-number-format";


type Props = {
    data: Array<SalesProductModel>,
    toggleSelected: (item: SalesProductModel, checked: boolean) => void,
    selected?: SalesProductModel[],
    handleCheckAll: () => void,
    handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void,
    hideCols: string[],
    setHideCols: (cols: string[]) => void,
    handleChangeProdVal: (e: any, item: SalesProductModel) => void
};

const SalesProductsTable: FC<Props> = ({ data, toggleSelected, selected, handleSort, hideCols, setHideCols, handleChangeProdVal }) => {
    const intl = useIntl();
    const [configModal, setConfigModal] = useState<ModalModel>({
        show: false,
        mode: 'add',
    });
    const headers: HeaderModel[] = [
        {
            label: intl.formatMessage({ id: 'PRODUCTS.NAME' }),
            field: 'name',
            sortable: true,
            style: { maxWidth: "150px", minWidth: "150px" }
        },
        {
            label: intl.formatMessage({ id: 'PRODUCTS.SENTO_CAPITAL_PRICE' }),
            field: 'cost_price_sento',
            sortable: true,
            style: { maxWidth: "120px", minWidth: "120px" }
        },
        {
            label: intl.formatMessage({ id: 'PRODUCTS.PRICE_SELL' }),
            field: 'sale_price',
            sortable: true,
            style: { maxWidth: "120px", minWidth: "120px" }
        },
        {
            label: intl.formatMessage({ id: 'PRODUCTS.MARKET_PRICE' }),
            field: 'market_price',
            sortable: true,
            style: { maxWidth: "120px", minWidth: "120px" }
        },
        {
            label: 'CK - NCC',
            field: 'sup_discount',
            sortable: true,
            style: { maxWidth: "120px", minWidth: "120px" }
        },
        {
            label: 'CK - ĐL',
            field: 'discount',
            sortable: true,
            style: { maxWidth: "120px", minWidth: "120px" }
        },
        {
            label: intl.formatMessage({ id: 'PRODUCTS.VAT' }),
            field: 'vat',
            sortable: true,
            style: { maxWidth: "120px", minWidth: "120px" }
        }
    ];
    const handleChangeVisCols = (e: any) => {
        let hiddenCols = [...hideCols];
        if (!e.target?.checked) {
            setHideCols([...hiddenCols, e.target.name])
        } else {
            setHideCols(hiddenCols.filter((item) => item !== e.target.name))
        }
    }
    return <>
        <div className='table-responsive'>
            <table className="table table-hover table-bordered table-striped border gy-7 gs-7">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th> <i
                                className="fa fa-cogs"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setConfigModal({ ...configModal, show: true })}
                            ></i>
                        </th>
                        {headers.map((th, index) => {
                            if (!hideCols.includes(th.field)) {
                                if (th.sortable) {
                                    return (
                                        <SortableColumn
                                            key={'th-' + index}
                                            sortBy={th?.field}
                                            onSortChange={handleSort}
                                            style={th?.style ?? {}}
                                        >
                                            {th.label}
                                        </SortableColumn>
                                    )
                                }
                                return (
                                    <th style={th?.style ?? {}}>{th.label}</th>
                                )
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item: SalesProductModel, index: number) => {
                        let checked = _.find(selected, { 'id': item.id });
                        let capPrice = item?.best_supplier?.price ?? item?.price; //Giá vốn
                        let priceSell = item?.best_supplier?.price_sell ?? item?.price; //Gí thị trường
                        let sentoPrice = item?.sales_product_by_sale?.price ?? item?.price; //Giá bán của sento
                        let discount = item?.sales_product_by_sale?.discount ?? item.discount ;
                        if (checked) {
                            sentoPrice = checked.sales_product_by_sale.price;
                            discount = checked.sales_product_by_sale.discount;
                        }
                        // if (item.is_company) {
                        //     discount = 0;
                        // }
                        return (
                            <tr key={`agent_product_${item.id}`} className="cursor-pointer">
                                <td>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={!!checked}
                                        onChange={(e) => toggleSelected(item, e.target.checked)}
                                    />
                                </td>
                                {!hideCols.includes('name') && (
                                    <td>{item.name || ''}</td>
                                )}
                                {!hideCols.includes('cost_price_sento') && (
                                    <td>{formatPrice(capPrice)}</td>
                                )}
                                {!hideCols.includes('sale_price') && (
                                    <td>
                                        {(checked) ? (
                                            <NumericFormat
                                                thousandSeparator=","
                                                name='price'
                                                className='form-control'
                                                defaultValue={sentoPrice}
                                                onChange={(e) => {
                                                    handleChangeProdVal(e, item);
                                                }}
                                            />
                                        ) : (
                                            formatPrice(sentoPrice)
                                        )}
                                        
                                    </td>
                                )}
                                {!hideCols.includes('market_price') && (
                                    <td>{formatPrice(priceSell)}</td>
                                )}
                                {!hideCols.includes('sup_discount') && (
                                    <td style={{ minWidth: '150px' }}>{`${item?.best_supplier?.discount?.toFixed(1)}%`}</td>
                                )}
                                {!hideCols.includes('discount') && (
                                    <td style={{ minWidth: '150px' }}>{`${discount.toFixed(1)}%`}</td>
                                )}
                                {!hideCols.includes('vat') && (
                                    <td style={{ width: '100px' }}>
                                        <div className="input-group input-group-sm mb-3">{item.vat + '%'}</div>
                                    </td>
                                )}
                            </tr>
                        )

                    })}
                </tbody>
            </table>
        </div>
        <ConfigModal
            {...configModal}
            header={headers}
            hideCols={hideCols}
            handleChangeVisCols={handleChangeVisCols}
            onClose={() => {
                setConfigModal({ ...configModal, show: false })
            }}
        />
    </>
}
export default SalesProductsTable